import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import {
  FaUserAlt,
  FaUserCircle,
  FaUser,
  FaPowerOff,
  FaShoppingBag,
  FaCog,
  FaPlus,
  FaSearch,
  FaTrash,
  FaUsers,
  FaBox,
  FaShoppingCart,
  FaHome,
  FaPallet,
  FaTshirt,
  FaBoxes,
  FaLink,
  FaReceipt,
  FaChartLine,
} from "react-icons/fa";

import { Link } from "react-router-dom";
import Api from "../../services/Api";

function SideNav(props) {
  const [user, setUser] = useState({});

  const [pendingOrder, setPendingOrder] = useState(0);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("auth_user"));
    setUser(user);

    // get pending order
    const token = localStorage.getItem("token");
    Api.get(
      "/items/Orders?filter[status][_eq]=pending&aggregate[count]=id",
      token
    )
      .then((response) => {
        console.log(response);
        if (response) {
          setPendingOrder(response.data[0].count.id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div class="min-h-screen fixed bg-gray-100">
        <div class="sidebar min-h-screen w-[3.35rem] overflow-hidden border-r lg:w-56 bg-white lg:hover:shadow-lg">
          <div class="flex h-screen flex-col justify-between pt-2 pb-6">
            <div>
              <div class="w-max p-2.5 flex flex-row items-center gap-4">
                <div>
                  <img
                    src={logo}
                    alt="logo"
                    class="w-8 h-8 rounded-full object-cover"
                  />
                </div>
                <div>Kataloqu Admin</div>
              </div>
              <ul class="mt-4 space-y-2 tracking-wide">
                {/* <li class="min-w-max">
                  <Link
                    to="/adminqu"
                    aria-label="dashboard"
                    className={
                      props.page == "dashboard"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaHome />
                    <span class="-mr-1 hidden lg:block font-medium">
                      Dashboard
                    </span>
                  </Link>
                </li> */}
                {/* <li class="min-w-max">
                  <a
                    href="#"
                    class="group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600">
                    <FaShoppingCart />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Order
                    </span>
                  </a>
                </li> */}
                <li className="border-t border-gray-200"></li>
                <li class="min-w-max">
                  <Link
                    to="/adminqu/catalog"
                    className={
                      props.page == "catalog"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaTshirt />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Katalog
                    </span>
                  </Link>
                </li>

                <li class="min-w-max">
                  <Link
                    to="/adminqu/category"
                    className={
                      props.page == "category"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaBoxes />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Kategori
                    </span>
                  </Link>
                </li>
                <li class="min-w-max">
                  <Link
                    to="/adminqu/brand"
                    className={
                      props.page == "brand"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaBox />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Brand
                    </span>
                  </Link>
                </li>

                <li className="border-t border-gray-200"></li>

                <li class="min-w-max">
                  <Link
                    to="/adminqu/orders"
                    aria-label="orders"
                    className={
                      props.page == "orders"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaShoppingCart />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Pesanan
                      <span class="ml-2 text-xs bg-red-500 text-white px-1 rounded-full">
                        {pendingOrder}
                      </span>
                    </span>
                  </Link>
                  <Link
                    to="/adminqu/report"
                    aria-label="dashboard"
                    className={
                      props.page == "report"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaChartLine />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Laporan
                    </span>
                  </Link>
                </li>
                <li className="border-t border-gray-200"></li>

                <li class="min-w-max">
                  <Link
                    to="/adminqu/customer"
                    aria-label="dashboard"
                    className={
                      props.page == "customer"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaUser />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Pelanggan
                    </span>
                  </Link>
                  <Link
                    to="/adminqu/customer-group"
                    aria-label="dashboard"
                    className={
                      props.page == "customer-group"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaUsers />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Grup Pelanggan
                    </span>
                  </Link>
                </li>
                {/* <li className="border-t border-gray-200"></li> */}

                <li class="min-w-max">
                  <Link
                    to="/adminqu/link-reseller"
                    aria-label="dashboard"
                    className={
                      props.page == "reseller"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaLink />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Link Reseller
                    </span>
                  </Link>
                </li>
                <li className="border-t border-gray-200"></li>
                <li class="min-w-max">
                  <Link
                    to="/adminqu/setting"
                    aria-label="dashboard"
                    className={
                      props.page == "setting"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaCog />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Pengaturan
                    </span>
                  </Link>
                </li>
                <li class="min-w-max">
                  <Link
                    to="/adminqu/account"
                    aria-label="account"
                    className={
                      props.page == "account"
                        ? "relative flex items-center space-x-6 bg-gradient-to-r from-pink-600 to-pink-400 px-4 py-3 lg:py-2 text-white"
                        : "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaUserCircle />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Akun
                    </span>
                  </Link>
                </li>
                <li class="min-w-max">
                  <button
                    onClick={() => {
                      localStorage.removeItem("auth_user");
                      localStorage.removeItem("token");
                      window.location.href = "/adminqu";
                    }}
                    className={
                      "group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600"
                    }>
                    <FaPowerOff />
                    <span class="group-hover:text-gray-700 hidden lg:block">
                      Logout
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            {/* <div class="w-max -mb-3">
              <a
                href="#"
                class="group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 group-hover:fill-cyan-600"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="group-hover:text-gray-700">Settings</span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SideNav;
