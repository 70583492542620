import React, { useState, useEffect } from "react";

import Api from "../../../services/Api";

import { Link } from "react-router-dom";
import Config from "../../../config/Config";

import { FaSearch, FaWhatsapp } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import Topmenu from "../../../components/adminqu/Topmenu";
import SideNav from "../../../components/adminqu/Sidenav";

function AdminquAccount() {
  const [authUser, setAuthUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    store_id: {
      name: "",
      subdomain: "",
      package: "",
    },
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletedLink, setDeletedLink] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isPasswordOpen, setPasswordOpen] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    const getUser = async (token) => {
      setIsLoading(true);
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);

          setFirstName(response.data.first_name);
          setLastName(response.data.last_name);
          setEmail(response.data.email);

          setIsLoading(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    getUser(token);
  }, []);

  const updateProfile = async () => {
    setIsLoading(true);

    const getUser = async (token) => {
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);

          setFirstName(response.data.first_name);
          setLastName(response.data.last_name);
          setEmail(response.data.email);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    Api.patch(
      "/users/me",
      {
        first_name: firstName,
        last_name: lastName,
        email: email,
      },
      token
    ).then((response) => {
      if (response.errors) {
        toast.error(
          "Gagal menyimpan profil  (" + response.errors[0].message + " )"
        );
        setIsLoading(false);
        return;
      }

      if (response) {
        toast.success("Profil berhasil diperbarui");
        setProfileOpen(false);
        getUser(token);
        setIsLoading(false);
      }
    });
  };

  const updatePassword = async () => {
    if (password !== passwordConfirmation) {
      toast.error("Password dan konfirmasi password tidak sama");
      return;
    }

    setIsLoading(true);

    Api.patch(
      "/users/me",
      {
        password: password,
      },
      token
    ).then((response) => {
      if (response.errors) {
        toast.error(
          "Gagal menyimpan password  (" + response.errors[0].message + " )"
        );
        setIsLoading(false);
        return;
      }

      if (response) {
        toast.success("Password berhasil diperbarui");
        setPasswordOpen(false);
        setIsLoading(false);
      }
    });
  };

  const dateFormat = (date = null) => {
    if (date != null && date != undefined) {
      const parts = date.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        return `${day}/${month}/${year}`;
      }
      return date;
    } else {
      return "-";
    }
  };

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <SideNav page="account" />
        </div>
        <div className="flex-1">
          <div>
            <div className="pt-4 lg:pt-8">
              <h3 className="text-center my-6 text-lg font-semibold">Akun</h3>
              <div className="flex-1 px-4 lg:px-12 flex flex-row justify-center">
                <div className="flex flex-col gap-2 w-full lg:w-3/4 ">
                  <div className="flex flex-col md:flex-row gap-2">
                    <div className="flex flex-col  p-8 rounded gap-4 h-60 bg-pink-100 w-full md:w-1/2">
                      <h3 className="text-pink-600 font-semibold">
                        Profil Anda
                      </h3>
                      <div className="flex flex-row gap-4 items-center">
                        <img
                          src={
                            "https://ui-avatars.com/api/?name=" +
                            authUser.first_name +
                            "+" +
                            authUser.last_name
                          }
                          className="rounded-full w-16 h-16"
                        />
                        <div className="">
                          <h3 className="font-semibold">
                            {authUser.first_name} {authUser.last_name}
                          </h3>
                          <p className="text-sm">{authUser.email}</p>
                        </div>
                      </div>
                      <div className="flex flex-row gap-4">
                        <button
                          className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded-lg"
                          onClick={() => {
                            setProfileOpen(true);
                          }}>
                          Ubah Profil
                        </button>
                        <button
                          className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded-lg"
                          onClick={() => {
                            setPasswordOpen(true);
                          }}>
                          Ubah Password
                        </button>
                      </div>
                    </div>

                    <div className="flex flex-col  p-8 gap-4 rounded h-60 bg-gray-50 w-full md:w-1/2">
                      <h3 className="text-gray-800 font-semibold">
                        Paket berlangganan Anda
                      </h3>
                      <div className="flex flex-row gap-4 items-center">
                        <img
                          src={
                            "https://ui-avatars.com/api/?name=" +
                            authUser.store_id.name
                          }
                          className="rounded-full w-16 h-16"
                        />
                        <div className="">
                          <h3 className="font-semibold">
                            {authUser.store_id.name}
                          </h3>
                          <p className="text-sm">
                            {authUser.store_id.subdomain}.kataloqu.com
                          </p>
                          <p className="text-sm">
                            Paket{" "}
                            <span className="font-semibold text-pink-600">
                              {authUser.store_id.package.toUpperCase(0)}
                            </span>
                          </p>
                          <p className="text-sm">
                            Kuota katalog{" "}
                            <span className="font-semibold text-pink-600">
                              {authUser.store_id.catalog_quotas == -1
                                ? "Unlimited"
                                : authUser.store_id.catalog_quotas}
                            </span>
                          </p>
                          <p className="text-sm">
                            Kuota Reseller{" "}
                            <span className="font-semibold text-pink-600">
                              {authUser.store_id.link_quotas == -1
                                ? "Unlimited"
                                : authUser.store_id.link_quotas}
                            </span>
                          </p>
                          <p className="text-sm">
                            Masa berakhir{" "}
                            <span className="font-semibold text-pink-600">
                              {dateFormat(authUser.store_id.expired_date)}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row gap-4">
                        <a
                          href="https://wa.me/6285659658433?text=Halo%20Admin%20Kataloqu%2C%20saya%20ingin%20mengubah%20atau%20memperpanjang%20paket%20saya"
                          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg flex flex-row gap-2">
                          <FaWhatsapp className="text-xl" />
                          <span className="">Ubah atau perpanjang paket</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isProfileOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white rounded-lg w-96 p-8 z-50 mx-4">
                  <div className="my-1 flex flex-col gap-2">
                    <h3 className="font-semibold text-pink-600">Ubah profil</h3>

                    <div className="flex flex-row gap-2">
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">Nama depan</span>
                        <input
                          type="text"
                          className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">Nama belakang</span>
                        <input
                          type="text"
                          className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="my-1 w-full font-semibold">
                      <span className="text-sm">Email</span>
                      <input
                        type="text"
                        className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex gap-2 flex-row justify-between">
                    <button
                      onClick={() => {
                        setProfileOpen(false);
                      }}
                      className="mt-4 bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded">
                      Batal
                    </button>
                    {isLoading ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        onClick={() => {
                          updateProfile();
                        }}
                        className="mt-4 bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">
                        Simpan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {isPasswordOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white rounded-lg w-96 p-8 z-50 mx-4">
                  <div className="my-1 flex flex-col gap-2">
                    <h3 className="font-semibold text-pink-600">
                      Ubah password
                    </h3>

                    <div className="my-1 w-full font-semibold">
                      <span className="text-sm">Password baru</span>
                      <input
                        type="password"
                        className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="my-1 w-full font-semibold">
                      <span className="text-sm">Konfirmasi password</span>
                      <input
                        type="password"
                        className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                        value={passwordConfirmation}
                        onChange={(e) =>
                          setPasswordConfirmation(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="flex gap-2 flex-row justify-between">
                    <button
                      onClick={() => {
                        setPasswordOpen(false);
                      }}
                      className="mt-4 bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded">
                      Batal
                    </button>
                    {isLoading ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        onClick={() => {
                          updatePassword();
                        }}
                        className="mt-4 bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">
                        Simpan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminquAccount;
