import React, { useState, useEffect } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { Link } from "react-router-dom";

import Api from "../services/Api";

var httpHost = window.location.hostname;
var domain = "https://kataloqu.com";

if (httpHost == "localhost" || httpHost == "192.168.1.7") {
  httpHost = "mycollection.kataloqu.com";
}

function CartFloat(props) {
  const [cart, setCart] = useState([]);

  const [totalQty, setTotalQty] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("customer_token");
    const getCart = async () => {
      Api.get("/items/Cart", token)
        .then((response) => {
          console.log(response);
          if (response) {
            setCart(response.data);
            let total = 0;
            response.data.map((item) => {
              total += item.qty;
            });
            setTotalQty(total);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    if (token != null) getCart();
  }, [props?.refresh]);

  return (
    totalQty > 0 && (
      <Link
        to="/cart"
        className={
          "bg-" +
          props.store.color +
          "-500 shadow rounded-full py-4 min-h-12 fixed bottom-10 right-10 px-6 flex-row flex justify-center items-center cursor-pointer"
        }>
        <FaShoppingCart className="text-white" />
        <span className="text-white ml-2">({totalQty})</span>
      </Link>
    )
  );
}

export default CartFloat;
