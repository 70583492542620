import React, { useState, useEffect } from "react";

import Api from "../../../services/Api";

import { FaSearch, FaTrash, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Config from "../../../config/Config";

import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import Topmenu from "../../../components/adminqu/Topmenu";
import InfiniteScroll from "react-infinite-scroll-component";
import SideNav from "../../../components/adminqu/Sidenav";
import formatCurrency from "../../../helpers/formatNumber";

function AdminquCatalog() {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeletes, setIsOpenDeletes] = useState(false);
  const [authUser, setAuthUser] = useState({
    store_id: {
      name: "",
    },
  });

  const [catalogOriginal, setCatalogOriginal] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [catalogDeleted, setCatalogDeleted] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  const [hasMore, setHasMore] = useState(true);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");

  const [checkedItems, setCheckedItems] = useState([]);

  const [page, setPage] = useState(1);

  const [countAll, setCountAll] = useState(0);

  useEffect(() => {
    const getCatalogCount = async (token, storeId) => {
      const queryStringStatus =
        status == "all" ? "" : "&filter[status][_eq]=" + status;
      const queryStringSearch =
        search == "" ? "" : "&filter[name][_like]=" + search;

      Api.get(
        "/items/Catalog?aggregate[count]=*&filter[store_id][_eq]=" +
          storeId +
          queryStringStatus +
          queryStringSearch,
        token
      )
        .then((response) => {
          console.log(response);
          setCountAll(response.data[0].count);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getInitialCatalog = async (token, storeId) => {
      const queryStringStatus =
        status == "all" ? "" : "&filter[status][_eq]=" + status;
      const queryStringSearch =
        search == "" ? "" : "&filter[name][_contains]=" + search;

      Api.get(
        "/items/Catalog?fields=*,images.image&filter[store_id][_eq]=" +
          storeId +
          queryStringStatus +
          queryStringSearch +
          "&sort=-date_updated&limit=100&page=1",
        token
      )
        .then((response) => {
          setCatalogOriginal(response.data);
          setCatalog(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          //console.log(error);
          setIsLoading(false);
        });
    };

    const getUser = async (token) => {
      setIsLoading(true);
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);
          getCatalogCount(token, response.data.store_id.id);
          getInitialCatalog(token, response.data.store_id.id);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const token = localStorage.getItem("token");
    getUser(token);
  }, [search, status]);

  useEffect(() => {
    const queryStringStatus =
      status == "all" ? "" : "&filter[status][_eq]=" + status;
    const queryStringSearch =
      search == "" ? "" : "&filter[name][_contains]=" + search;

    const getNextCatalog = async (token, storeId) => {
      Api.get(
        "/items/Catalog?fields=*,images.image&filter[store_id][_eq]=" +
          storeId +
          queryStringStatus +
          queryStringSearch +
          "&sort=-date_updated&limit=100&page=" +
          page,
        token
      )
        .then((response) => {
          if (response.data.length == 0) {
            setHasMore(false);
          } else {
            const newCatalog = [...catalog, ...response.data];
            setCatalogOriginal(newCatalog);
            setCatalog(newCatalog);
          }

          setIsLoading(false);
        })
        .catch((error) => {
          //console.log(error);
          setIsLoading(false);
        });
    };

    const token = localStorage.getItem("token");
    const storeId = authUser.store_id.id;

    if (page > 1) {
      getNextCatalog(token, storeId);
    }
  }, [page]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const deleteCatalog = async (id) => {
    setIsDeleting(true);
    const token = localStorage.getItem("token");
    Api.delete("/items/Catalog/" + id, null, token)
      .then((response) => {
        //console.log(response);

        const newCatalog = catalog.filter((item) => item.id != id);
        setCatalog(newCatalog);
        toast.success("Katalog berhasil dihapus");
        closeModal();
        setIsDeleting(false);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const dateFormat = (date = null) => {
    if (date != null && date != undefined) {
      const parts = date.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        return `${day}/${month}/${year}`;
      }
      return date;
    } else {
      return "-";
    }
  };

  const deleteSelected = async () => {
    setIsDeleting(true);
    const token = localStorage.getItem("token");

    const data = {
      keys: checkedItems,
    };

    Api.delete("/items/Catalog", data, token).then((response) => {
      const newCatalog = catalog.filter(
        (item) => !checkedItems.includes(item.id)
      );
      setCatalog(newCatalog);
      toast.success("Katalog dipilih berhasil dihapus");
      setIsOpenDeletes(false);
      setIsDeleting(false);
    });
  };

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <SideNav page="catalog" />
        </div>
        <div className="flex-1">
          <div className="flex flex-col w-full p-4 py-5">
            <div className="fixed top-14 justify-center items-center md:right-0 px-8 md:w-96 w-80 sm:top-5">
              <div className="px-2 py-2 shadow bg-white rounded-lg">
                <div className="flex flex-row gap-4 items-center">
                  <div className="flex w-full flex-row">
                    <input
                      type="text"
                      placeholder={"cari katalog dari " + countAll + " data"}
                      className="border-2 w-full border-gray-100 bg-gray-100 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:bg-white"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <button className="right-6 top-5 text-pink-500 -ml-8">
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 font-bold">Katalog</div>

            <div className="px-4 md:pt-16 pt-24">
              <div className="container mx-auto ">
                <div className="flex flex-col sm:flex-row gap-4 justify-center w-full">
                  <div className="rounded w-full">
                    <div className="flex flex-row justify-between items-center gap-1 mb-4">
                      <div className="w-1/4 text-xs md:text-lg">
                        {authUser.store_id.name}
                      </div>
                      <div className="w-3/4 flex flex-row gap-0.5 justify-end">
                        <button
                          className={
                            status == "all"
                              ? "bg-pink-600  flex flex-row items-center hover:bg-pink-700 hover:text-white text-white py-1 px-2 rounded text-xs md:text-sm"
                              : "bg-gray-200  flex flex-row items-center hover:bg-gray-700 hover:text-white text-gray-800 py-1 px-2 rounded text-xs md:text-sm"
                          }
                          onClick={() => {
                            setStatus("all");
                          }}>
                          Semua
                        </button>
                        <button
                          className={
                            status == "published"
                              ? "bg-pink-600  flex flex-row items-center hover:bg-pink-700 hover:text-white text-white py-1 px-2 rounded text-xs md:text-sm"
                              : "bg-gray-200  flex flex-row items-center hover:bg-gray-700 hover:text-white text-gray-800 py-1 px-2 rounded text-xs md:text-sm"
                          }
                          onClick={() => {
                            setStatus("published");
                          }}>
                          Dipublikasikan
                        </button>
                        <button
                          className={
                            status == "archived"
                              ? "bg-pink-600  flex flex-row items-center hover:bg-pink-700 hover:text-white text-white py-1 px-2 rounded text-xs md:text-sm"
                              : "bg-gray-200  flex flex-row items-center hover:bg-gray-700 hover:text-white text-gray-800 py-1 px-2 rounded text-xs md:text-sm"
                          }
                          onClick={() => {
                            setStatus("archived");
                          }}>
                          Diarsipkan
                        </button>
                      </div>
                    </div>

                    {isLoading ? (
                      <div className="flex h-64 justify-center items-center">
                        <ReactLoading
                          type={"balls"}
                          color={"gray"}
                          height={10}
                          width={48}
                        />
                      </div>
                    ) : (
                      <InfiniteScroll
                        dataLength={catalog.length}
                        next={() => {
                          setPage(page + 1);
                        }}
                        hasMore={hasMore}
                        loader={
                          <div className="flex h-64 justify-center items-center">
                            <ReactLoading
                              type={"balls"}
                              color={"gray"}
                              height={10}
                              width={48}
                            />
                          </div>
                        }
                        endMessage={
                          <p className="text-center text-gray-500 my-4 text-sm">
                            <b>Yay! Anda telah melihat semua katalog</b>
                          </p>
                        }>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2">
                          {catalog.map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.status == "archived"
                                  ? "bg-gray-100 rounded overflow-hidden flex flex-row text-gray-500"
                                  : "bg-pink-50 rounded overflow-hidden flex flex-row"
                              }>
                              <div className="w-8">
                                <input
                                  type="checkbox"
                                  className="m-2"
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    if (checked) {
                                      setCheckedItems([
                                        ...checkedItems,
                                        item.id,
                                      ]);
                                    } else {
                                      const newCheckedItems =
                                        checkedItems.filter(
                                          (checkedItem) =>
                                            checkedItem != item.id
                                        );
                                      setCheckedItems(newCheckedItems);
                                    }
                                  }}
                                />
                              </div>
                              <div className="w-1/4 xl:w-1/4 lg:w-1/4 sm:w-1/4">
                                <Link to={`/adminqu/catalog/${item.id}`}>
                                  <img
                                    src={
                                      Config.api_url +
                                      "/assets/" +
                                      item.image +
                                      "?fit=cover&width=200&height=200"
                                    }
                                    alt={item.name}
                                    className="w-full h-auto overflow-hidden"
                                  />
                                </Link>
                              </div>

                              <div className="w-3/4 xl:w-2/3 lg:w-2/3 sm:w-2/3">
                                <span
                                  className="text-red-500 text-md float-right cursor-pointer p-2 py-3"
                                  onClick={() => {
                                    setCatalogDeleted(item);
                                    openModal();
                                  }}>
                                  <FaTrash className="text-red-500 float-right" />
                                </span>

                                <Link to={`/adminqu/catalog/${item.id}`}>
                                  <div className="pl-2 pr-8 py-2 ">
                                    <h2 className="text-xs sm:text-md font-semibold">
                                      {item.name}
                                    </h2>
                                    <p className="text-pink-700 font-semibold text-xs">
                                      {item.is_discount == 1 ? (
                                        <>
                                          {item.is_range_price ? (
                                            <p
                                              className={
                                                "text-red-500 text-sm font-normal"
                                              }>
                                              <span className=" line-through pr-1">
                                                {formatCurrency(
                                                  item.price_from
                                                )}{" "}
                                                -{" "}
                                                {formatCurrency(item.price_to)}
                                              </span>
                                              <br />
                                              <span
                                                className={
                                                  "text-sm font-semibold"
                                                }>
                                                {formatCurrency(
                                                  item.discount_from
                                                )}{" "}
                                                -{" "}
                                                {formatCurrency(
                                                  item.discount_to
                                                )}
                                              </span>
                                            </p>
                                          ) : (
                                            <p
                                              className={
                                                "text-red-500 text-sm font-normal"
                                              }>
                                              <span className=" line-through pr-1">
                                                {formatCurrency(item.price)}
                                              </span>
                                              <span
                                                className={
                                                  "text-sm font-semibold"
                                                }>
                                                {formatCurrency(item.discount)}
                                              </span>
                                            </p>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.is_range_price ? (
                                            <p
                                              className={
                                                "text-sm font-semibold"
                                              }>
                                              {formatCurrency(item.price_from)}{" "}
                                              - {formatCurrency(item.price_to)}
                                            </p>
                                          ) : (
                                            <p
                                              className={
                                                "text-sm font-semibold"
                                              }>
                                              {formatCurrency(item.price)}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </p>
                                    {item.is_preorder == 1 && (
                                      <>
                                        <p className="text-gray-500 text-xs mt-1 ">
                                          {item.preorder_additional_text}
                                        </p>
                                        <p className="text-pink-500 text-xs mt-0.5">
                                          <span className="bg-pink-500 text-white px-2 py-0.5 rounded ">
                                            {dateFormat(
                                              item.preorder_closed_date
                                            )}
                                          </span>
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </InfiniteScroll>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {isOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white rounded-lg p-8 z-50 mx-4">
                  <p>Apakah anda yakin ingin menghapus katalog ini?</p>

                  <div className="flex gap-2 flex-row justify-between">
                    <button
                      onClick={closeModal}
                      className="mt-4 bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded">
                      Batal
                    </button>
                    {isDeleting ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        onClick={() => {
                          deleteCatalog(catalogDeleted.id);
                        }}
                        className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Ya, hapus
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {isOpenDeletes && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white rounded-lg p-8 z-50 mx-4">
                  <p>Apakah anda yakin ingin menghapus katalog yang dipilih?</p>

                  <div className="flex gap-2 flex-row justify-between">
                    <button
                      onClick={() => {
                        setIsOpenDeletes(false);
                      }}
                      className="mt-4 bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded">
                      Batal
                    </button>
                    {isDeleting ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        onClick={() => {
                          deleteSelected();
                        }}
                        className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Yakin hapus {catalogDeleted.name} ? Ya, hapus
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {checkedItems.length > 0 && (
              <>
                <button
                  onClick={() => {
                    setIsOpenDeletes(true);
                  }}
                  className="invisible lg:visible fixed bottom-4 right-56 bg-red-500 hover:bg-red-700 text-white font-bold py-5 px-5 rounded-full text-sm  h-12 items-center shadow flex gap-2 flex-row"
                  to="/adminqu/catalog/add">
                  <FaTrash /> <span>Hapus katalog</span>
                </button>
                <button
                  onClick={() => {
                    setIsOpenDeletes(true);
                  }}
                  className="lg:invisible visible fixed bottom-4 right-20 bg-red-500 hover:bg-red-700 text-white font-bold py-5 px-5 rounded-full w-14 h-14 items-center shadow"
                  to="/adminqu/catalog/add">
                  <FaTrash />
                </button>
              </>
            )}
            <Link
              className="invisible lg:visible fixed bottom-4 right-10 bg-teal-500 hover:bg-teal-700 text-white font-bold py-5 px-5 rounded-full text-sm  h-12 items-center shadow flex gap-2 flex-row"
              to="/adminqu/catalog/add">
              <FaPlus /> <span>Tambah katalog</span>
            </Link>
            <Link
              className="lg:invisible visible fixed bottom-4 right-4 bg-teal-500 hover:bg-teal-700 text-white font-bold py-5 px-5 rounded-full w-14 h-14 items-center shadow"
              to="/adminqu/catalog/add">
              <FaPlus />
            </Link>

            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminquCatalog;
