import React, { useState, useEffect } from "react";

import Api from "../../../services/Api";

import Topmenu from "../../../components/adminqu/Topmenu";
import { ToastContainer, toast } from "react-toastify";
import Config from "../../../config/Config";

import ReactLoading from "react-loading";

import { Link } from "react-router-dom";

import SideNav from "../../../components/adminqu/Sidenav";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AdminquSetting() {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [authUser, setAuthUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    store_id: {
      name: "",
      subdomain: "",
      package: "",
    },
  });

  const [store, setStore] = useState({
    order_note: "",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    const getUser = async (token) => {
      setIsLoading(true);
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);
          setStore(response.data.store_id);

          // if (response.data.store_id.color == "") {
          //   setStore((store) => ({ ...store, color: "pink" }));
          // }

          setIsLoading(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    getUser(token);
  }, []);

  const uploadLogo = (event) => {
    const image = event.target.files[0];

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var formdata = new FormData();
    formdata.append("file", image, image.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(Config.api_url + "/files", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        //console.log(result);
        const data = JSON.parse(result).data;

        setStore((store) => ({ ...store, logo: data.id }));
      })
      .catch((error) => {
        //console.log("error", error)
      });
  };

  const submitSave = async () => {
    setIsLoading(true);

    Api.patch(
      "/items/Store/" + store.id,
      {
        order_note: store.order_note,
      },
      token
    ).then((response) => {
      if (response.errors) {
        toast.error(
          "Gagal menyimpan pengaturan  (" + response.errors[0].message + " )"
        );
        setIsLoading(false);
        return;
      }

      if (response) {
        toast.success("Pengaturan berhasil disimpan");
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <SideNav page="setting" />
        </div>
        <div className="flex-1">
          <div className="flex-1 w-full  p-4 py-5">
            <div className="px-4 pl-16 sm:pl-4 font-bold">Pengaturan</div>
            <div className="pt-4 lg:pt-4">
              <div className="my-8 mb-20 flex flex-row justify-center gap-2">
                <Link
                  to={"/adminqu/setting"}
                  className="bg-gray-200 p-2 px-4 rounded ">
                  <span className="text-gray-900 text-sm">Informasi</span>
                </Link>
                <Link
                  to="/adminqu/setting/order"
                  className="bg-pink-500 p-2 px-4 rounded">
                  <span className="text-white  text-sm">Order</span>
                </Link>
                <Link
                  to="/adminqu/setting/shipping"
                  className="bg-gray-200 p-2 px-4 rounded">
                  <span className="text-gray-900 text-sm">Ekspedisi</span>
                </Link>
              </div>
              <div className="w-full flex flex-row justify-center px-4">
                <div className="w-full lg:w-1/2 bg-gray-50 p-4 rounded mt-8 md:mt-0">
                  <div className="my-1 flex flex-col md:flex-row gap-2">
                    <div className="my-1 w-full">
                      <span className="text-sm">Catatan order</span>
                      <div className="w-full flex-1 bg-white">
                        <ReactQuill
                          theme="snow"
                          value={store?.order_note}
                          placeholder="informasi transfer rekening, pembayaran ke ..."
                          onChange={(e) => {
                            setStore((store) => ({
                              ...store,
                              order_note: e,
                            }));
                          }}
                          className="h-48 mb-4 pb-10 bg-white"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-right">
                    {isLoading ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        className="mt-4 w-full md:w-auto bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded-lg"
                        onClick={() => {
                          submitSave();
                        }}>
                        Simpan pengaturan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminquSetting;
