import React from "react";

function Page404() {
  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen bg-gray-100">
      <h1 className="text-4xl font-bold">404</h1>
      <h2 className="text-2xl ">Page Not Found</h2>
    </div>
  );
}

export default Page404;
