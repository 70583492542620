import React, { useState, useEffect } from "react";

import Api from "../../services/Api";
import Config from "../../config/Config";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";

import ReactLoading from "react-loading";

var httpHost = window.location.hostname;
var domain = "https://kataloqu.com";

if (httpHost == "localhost" || httpHost == "192.168.1.7") {
  httpHost = "mycollection.kataloqu.com";
}

function Register() {
  const [store, setStore] = useState({
    name: "",
    color: "",
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirm] = useState("");

  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const subdomain = httpHost.split(".")[0];

    const getStore = async () => {
      Api.get(
        "/items/Store?filter[subdomain][_eq]=" +
          subdomain +
          "&filter[is_activated][_eq]=1&fields=*"
      )
        .then((response) => {
          //console.log(response);
          if (response.data.length == 0) {
            // 404 page
            window.location.href = "/expired";
          }

          if (response) {
            setStore(response.data[0]);
            document.title = response.data[0].name + " - by Kataloqu";
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    getStore();
  }, []);

  const submitRegister = (e) => {
    e.preventDefault();
    // validate all fields
    if (name == "") {
      toast.error("Nama harus diisi");
      return false;
    }

    if (email == "") {
      toast.error("Email harus diisi");
      return false;
    }

    if (phone == "") {
      toast.error("Telepon harus diisi");
      return false;
    }

    if (password == "") {
      toast.error("Password harus diisi");
      return false;
    }

    if (confirmPassword == "") {
      toast.error("Konfirmasi password harus diisi");
      return false;
    }

    if (password != confirmPassword) {
      toast.error("Password dan konfirmasi password harus sama");
      return false;
    }

    // submit to api
    const data = {
      name: name,
      email: email,
      phone: phone,
      password: password,
      password_confirmation: confirmPassword,
      store_id: store.id,
      store_customer_group_id: null,
    };

    setLoading(true);

    Api.post("/flows/trigger/4dabbf0f-8ce9-4eef-934d-77b1eeaf2185", data)
      .then((response) => {
        console.log(response);
        if (response.status == "success") {
          setRegisterSuccess(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Pendaftaran gagal, silahkan coba lagi");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col justify-center items-center py-10 px-4">
      <ToastContainer />
      <div>
        <img
          src={Config.api_url + "/assets/" + store.logo}
          className="w-20 mx-auto mb-8"
        />
      </div>
      {!registerSuccess && (
        <>
          <div className="w-80 p-4 bg-gray-100 min-h-48 rounded mb-8">
            <form className="flex flex-col gap-4">
              <h1>Daftar Pelanggan</h1>
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2"
                placeholder="Nama"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="phone"
                className="border border-gray-300 rounded-lg p-2"
                placeholder="Telepon"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />

              <input
                type="password"
                className="border border-gray-300 rounded-lg p-2"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="password"
                className="border border-gray-300 rounded-lg p-2"
                placeholder="Konfirmasi Password"
                value={confirmPassword}
                onChange={(e) => setConfirm(e.target.value)}
              />
              <button
                onClick={(e) => submitRegister(e)}
                disabled={loading}
                type="submit"
                className={
                  "bg-" +
                  store.color +
                  "-500 hover:bg-" +
                  store.color +
                  "-700 text-white font-bold py-2 px-4 rounded-lg flex flex-row justify-center items-center gap-2"
                }>
                {loading && (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={20}
                    width={20}
                  />
                )}
                Daftar Akun
              </button>
            </form>
          </div>
          <div>Sudah punya akun? </div>
          <div className="mt-2">
            <Link
              to={"/login"}
              className={
                "bg-gray-500 hover:bg-" +
                store.color +
                "-700 text-white font-bold py-2 px-4 rounded-lg"
              }>
              Login
            </Link>
          </div>
        </>
      )}

      {registerSuccess && (
        <>
          <div className="w-80 p-8 bg-gray-100 min-h-48 pb-6 rounded mb-8">
            <FaCheck className="text-6xl text-green-500 mx-auto" />
            <h2 className="mt-6 text-center">
              Daftar akun berhasil, <br />
              silahkan Login!
            </h2>
            <div className="mt-4 text-center pb-6">
              <Link
                to={"/login"}
                className={
                  "bg-" +
                  store.color +
                  "-500 hover:bg-" +
                  store.color +
                  "-700 text-white font-bold py-2 px-4 rounded-lg"
                }>
                Login sekarang
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Register;
