import React, { useState, useEffect } from "react";

import Api from "../../../services/Api";

import { Link } from "react-router-dom";
import Config from "../../../config/Config";

import { FaSearch, FaPlus } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import Topmenu from "../../../components/adminqu/Topmenu";
import SideNav from "../../../components/adminqu/Sidenav";

function AdminquCustomer() {
  const [authUser, setAuthUser] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletedCustomer, setDeletedCustomer] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const [customerOriginal, setCustomerOriginal] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [status, setStatus] = useState("active");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const getInitialCustomers = async (token, store_id) => {
    Api.get(
      "/users?filter[store_id][_eq]=" +
        store_id +
        "&filter[role][_eq]=41102248-b193-4b2a-ab2e-9182766b3113&fields=*&limit=-1",
      token
    )
      .then((response) => {
        //console.log(response);
        if (response) {
          setCustomerOriginal(response.data);
          setCustomers(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    const getUser = async (token) => {
      setIsLoading(true);
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);
          getInitialCustomers(token, response.data.store_id.id);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const token = localStorage.getItem("token");
    getUser(token);
  }, [status]);

  useEffect(() => {
    const results = customerOriginal.filter((customer) =>
      customer.first_name?.toLowerCase().includes(search.toLowerCase())
    );
    setCustomers(results);
  }, [search]);

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    setIsDeleting(true);
    Api.patch(
      "/users/" + deletedCustomer.id,
      {
        store_id: null,
      },
      token
    )
      .then((response) => {
        //console.log(response);

        // if (response.errors) {
        //   toast.error("Pelanggan gagal dihapus");
        //   setIsDeleting(false);
        //   return;
        // }

        toast.success("Pelanggan berhasil dihapus");
        setIsDeleting(false);
        setCustomers(
          customers.filter((customer) => customer.id != deletedCustomer.id)
        );
        setCustomerOriginal(
          customerOriginal.filter(
            (customer) => customer.id != deletedCustomer.id
          )
        );
        closeModal();
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Grup gagal dihapus");
        setIsDeleting(false);
      });
  };

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <SideNav page="customer" />
        </div>
        <div className="flex-1 justify-center items-center p-4 py-5">
          <div className="w-full">
            <div className="fixed top-14 justify-center items-center md:right-0 px-8 md:w-96 w-80 sm:top-5">
              <div className="px-2 py-2 shadow bg-white rounded-lg">
                <div className="flex flex-row gap-4 items-center">
                  <div className="flex w-full flex-row">
                    <input
                      type="text"
                      placeholder={
                        "Cari grup pelanggan dari " + customers.length + " data"
                      }
                      className="border-2 w-full border-gray-100 bg-gray-100 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:bg-white"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <button className="right-6 top-5 text-pink-500 -ml-8">
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 font-bold"> Pelanggan</div>

            <div className="pt-24 md:pt-20 lg:pt-16">
              {isLoading ? (
                <div className="flex justify-center h-32 items-center">
                  <ReactLoading
                    type={"balls"}
                    color={"gray"}
                    height={48}
                    width={48}
                  />
                </div>
              ) : (
                <div className="container mx-auto">
                  <div className="flex-1 px-4 lg:px-12 flex flex-row  justify-center">
                    <div className="flex flex-col gap-2 w-full lg:w-3/4 ">
                      {customers.map((item, index) => (
                        <div className="flex flex-row lg:flex-row text-sm bg-gray-50 gap-1 p-2">
                          <div className="flex-1 font-semibold">
                            {item.first_name}
                          </div>
                          <div className="flex-1">{item.email_account}</div>

                          <div className="lg:w-1/4 flex flex-row gap-2 justify-end">
                            <Link
                              to={"/adminqu/customer/" + item.id}
                              className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-1 px-2 lg:py-2 lg:px-3 text-xs lg:text-xs rounded">
                              Edit
                            </Link>
                            <button
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 lg:py-2 lg:px-3 text-xs lg:text-xs rounded"
                              onClick={() => {
                                setDeletedCustomer(item);
                                openModal();
                              }}>
                              Hapus
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white rounded-lg p-8 z-50 mx-4">
                  <p>Apakah anda yakin ingin menghapus pelanggan ini?</p>
                  <p className="font-semibold">{deletedCustomer.name}</p>

                  <div className="flex gap-2 flex-row justify-between">
                    <button
                      onClick={closeModal}
                      className="mt-4 bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded">
                      Batal
                    </button>
                    {isDeleting ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        onClick={() => {
                          handleDelete();
                        }}
                        className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Ya, hapus
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            <Link
              className="invisible lg:visible fixed bottom-4 right-10 bg-teal-500 hover:bg-teal-700 text-white font-bold py-5 px-5 rounded-full text-sm  h-12 items-center shadow flex gap-2 flex-row"
              to="/adminqu/customer/add">
              <FaPlus /> <span>Tambah pelanggan</span>
            </Link>
            <Link
              className="lg:invisible visible fixed bottom-4 right-4 bg-teal-500 hover:bg-teal-700 text-white font-bold py-5 px-5 rounded-full w-14 h-14 items-center shadow"
              to="/adminqu/customer/add">
              <FaPlus />
            </Link>

            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminquCustomer;
