import React, { useState, useEffect } from "react";

import Api from "../../services/Api";
import Config from "../../config/Config";
import { Link } from "react-router-dom";

import ReactLoading from "react-loading";

import { ToastContainer, toast } from "react-toastify";
import { FaHome } from "react-icons/fa";

var httpHost = window.location.hostname;
var domain = "https://kataloqu.com";

if (httpHost == "localhost" || httpHost == "192.168.1.7") {
  httpHost = "mycollection.kataloqu.com";
}

function Login() {
  const [store, setStore] = useState({
    name: "",
    color: "",
  });

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const subdomain = httpHost.split(".")[0];

    const getStore = async () => {
      Api.get(
        "/items/Store?filter[subdomain][_eq]=" +
          subdomain +
          "&filter[is_activated][_eq]=1&fields=*"
      )
        .then((response) => {
          //console.log(response);
          if (response.data.length == 0) {
            // 404 page
            window.location.href = "/expired";
          }

          if (response) {
            setStore(response.data[0]);
            document.title = response.data[0].name + " - by Kataloqu";
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    getStore();
  }, []);

  const submitLogin = (e) => {
    e.preventDefault();

    setLoading(true);
    const data = {
      email: email,
      store_id: store.id,
    };

    //secret-cp.kataloqu.com/flows/trigger/0e932eca-3120-4939-8bcb-4b4a59778537

    Api.post("/flows/trigger/0e932eca-3120-4939-8bcb-4b4a59778537", data)
      .then((response) => {
        //console.log(response);
        if (response.status == "success") {
          Api.post("/auth/login", {
            email: response.email,
            password: password,
          }).then((response2) => {
            console.log(response2);

            if (response2?.errors?.length > 0) {
              toast.error("Gagal login (" + response2.errors[0].message + " )");

              setLoading(false);
              return false;
            }

            localStorage.setItem("customer_token", response2.data.access_token);
            localStorage.setItem(
              "customer_refresh_token",
              response2.data.refresh_token
            );

            window.location.href = "/";

            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col justify-center items-center py-10 px-4">
      <div>
        <img
          src={Config.api_url + "/assets/" + store.logo}
          className="w-20 mx-auto mb-8"
        />
      </div>
      <div className="w-80 p-4 bg-gray-100 min-h-48 rounded mb-8">
        <form className="flex flex-col gap-4">
          <h1>Login Pelanggan</h1>
          <input
            type="text"
            className="border border-gray-300 rounded-lg p-2"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            className="border border-gray-300 rounded-lg p-2"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="submit"
            className={
              "bg-" +
              store.color +
              "-500 hover:bg-" +
              store.color +
              "-700 text-white font-bold py-2 px-4 rounded-lg  flex flex-row justify-center items-center gap-2"
            }
            onClick={(e) => submitLogin(e)}>
            {loading && (
              <ReactLoading type="spin" color="#fff" height={20} width={20} />
            )}
            Login
          </button>
        </form>
      </div>
      <div>Belum punya akun? </div>
      <div className="mt-2">
        <Link
          to={"/register"}
          className={
            "bg-gray-500 hover:bg-" +
            store.color +
            "-700 text-white font-bold py-2 px-4 rounded-lg"
          }>
          Daftar
        </Link>
      </div>

      <div className="mt-8">
        <Link
          to={"/"}
          className={
            "bg-" +
            store.color +
            "-500 hover:bg-" +
            store.color +
            "-700 text-white font-bold py-2 px-4 rounded-lg flex gap-2 justify-center items-center"
          }>
          <FaHome className="inline-block" />
          Kembali ke home
        </Link>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Login;
