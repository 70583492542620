// modify currency format

const formatCurrency = (number) => {
  const currency_symbol = localStorage.getItem("store_currency_symbol") || "Rp"; // Default to empty if not set
  const thousand_separator =
    localStorage.getItem("store_thousand_separator") || ".";

  // Define options for number formatting
  const options = {
    minimumFractionDigits: 0, // Show two decimal places
    maximumFractionDigits: 2,
  };

  // Format number to a string with the specified thousand separator
  let formattedNumber = new Intl.NumberFormat("en-US", options).format(number);

  // Replace default comma separator with specified thousand separator
  if (thousand_separator === ".") {
    formattedNumber = formattedNumber.replace(/,/g, ".");
  } else if (thousand_separator === ",") {
    formattedNumber = formattedNumber.replace(/\./g, ",");
  }

  // Return formatted string with currency symbol
  return `${currency_symbol}${formattedNumber}`;
};

export default formatCurrency;
