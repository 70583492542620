import React, { useState, useEffect } from "react";
import DropdownButton from "./Dropdown";
import DropdownCatalog from "./DropdownCatalog";
import DropdownCategory from "./DropdownCategory";
import DropdownBrand from "./DropdownBrand";

import { Link } from "react-router-dom";
import Api from "../services/Api";
import Config from "../config/Config";

import { FaUserAlt } from "react-icons/fa";

var httpHost = window.location.hostname;
var domain = "https://kataloqu.com";

if (httpHost == "localhost" || httpHost == "192.168.1.7") {
  httpHost = "mycollection.kataloqu.com";
}

function Header(props) {
  const [token, setToken] = useState(null);
  console.log(token);

  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [store, setStore] = useState({
    name: "",
    color: "",
  });

  const [search, setSearch] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const tokentemp = localStorage.getItem("customer_token");
    if (tokentemp != null) {
      setToken(tokentemp);
    }

    const subdomain = httpHost.split(".")[0];
    const getStore = async () => {
      Api.get(
        "/items/Store?filter[subdomain][_eq]=" +
          subdomain +
          "&filter[is_activated][_eq]=1&fields=*"
      )
        .then((response) => {
          //console.log(response);
          if (response.data.length == 0) {
            // 404 page
            window.location.href = "/expired";
          }

          if (response) {
            setStore(response.data[0]);

            localStorage.setItem(
              "store_currency_symbol",
              response.data[0].currency_symbol
            );
            localStorage.setItem(
              "store_thousand_separator",
              response.data[0].thousand_separator
            );

            document.title = response.data[0].name + " - by Kataloqu";
            getBrands(response.data[0].id);
            getCategory(response.data[0].id);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getBrands = async (store_id) => {
      const response = await Api.get(
        "/items/Brands?filter[store_id][_eq]=" + store_id + "&sort=name"
      );
      setBrands(response.data);
    };

    const getCategory = async (store_id) => {
      const response = await Api.get(
        "/items/Categories?filter[store_id][_eq]=" + store_id + "&sort=name"
      );
      setCategory(response.data);
    };

    getStore();
  }, []);

  return (
    <div className="fixed w-full ml-0 mt-0 px-4 py-2 shadow bg-white z-10">
      <div className="flex flex-col w-full sm:flex-row gap-4 items-center hidden md:inline-flex">
        <div className="flex-initial w-full sm:w-1/4 text-lg font-bold text-center justify-center flex items-center gap-2">
          <Link
            to="/"
            className="flex flex-row gap-2 items-center justify-center">
            <span className="rounded-full overflow-hidden">
              {store.logo != null && (
                <img
                  src={Config.api_url + "/assets/" + store.logo + ""}
                  width={60}
                  height={60}
                />
              )}
            </span>
            <span>{store.name}</span>
          </Link>
        </div>

        <div className="w-full flex flex-row sm:w-1/3 ml-auto gap-1">
          <div className="">
            <DropdownCatalog />
          </div>
          <div className="">
            <DropdownCategory name="Kategori" category={category} />
          </div>
          <div className="">
            <DropdownBrand name="Brand" brands={brands} />
          </div>
        </div>
        <div className="w-full sm:w-1/6 ml-auto flex flex-row gap-2">
          <input
            type="text"
            placeholder="Cari katalog"
            className="border-2 w-full border-gray-100 bg-gray-100 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:bg-white"
            onChange={(e) => {
              setSearch(e.target.value);
              props.onSearch(e.target.value);
              console.log(e.target.value);
            }}
          />
        </div>
        <div className="flex-initial w-full sm:w-2/6 text-center flex flex-row justify-center gap-2">
          {token != null ? (
            <Link
              to="/account"
              className={
                "bg-gray-500 hover:bg-" +
                store.color +
                "-700 text-white text-sm font-bold py-2 px-4 rounded-lg flex flex-row gap-1 items-center"
              }>
              <FaUserAlt size={12} />
              Akun
            </Link>
          ) : (
            <Link
              to="/login"
              className={
                "bg-gray-500 hover:bg-" +
                store.color +
                "-700 text-white text-sm font-bold py-2 px-4 rounded-lg"
              }>
              Login
            </Link>
          )}
          <button
            className={
              "bg-" +
              store.color +
              "-500 hover:bg-" +
              store.color +
              "-700 text-white text-sm font-bold py-2 px-4 rounded-lg"
            }
            onClick={props.onOpenModal}>
            Buat link reseller
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-2 sm:hidden">
        <div className="flex flex-row justify-between">
          <Link
            to="/"
            className="w-1/2 text-lg font-bold flex flex-row items-center gap-2">
            <span className="rounded-full overflow-hidden w-1/3">
              {store.logo != null && (
                <img src={Config.api_url + "/assets/" + store.logo + ""} />
              )}
            </span>
            <div className="w-2/3 text-xs">{store.name}</div>
          </Link>
          <div className="py-4 flex flex-row gap-2 justify-end items-center">
            {token != null ? (
              <Link
                to="/account"
                className={
                  "bg-gray-500 hover:bg-" +
                  store.color +
                  "-700 text-white text-xs font-bold py-2.5 px-4 rounded-lg flex flex-row gap-1 items-center"
                }>
                <FaUserAlt size={12} />
              </Link>
            ) : (
              <Link
                to="/login"
                className={
                  "bg-gray-500 hover:bg-" +
                  store.color +
                  "-700 text-white text-xs font-bold py-2 px-4 rounded-lg"
                }>
                Login
              </Link>
            )}

            <button
              className={
                "bg-" +
                store.color +
                "-500 text-xs hover:bg-" +
                store.color +
                "-700 text-white font-bold py-2 px-4 rounded-lg"
              }
              onClick={props.onOpenModal}>
              Buat link reseller
            </button>
          </div>
        </div>
        <div className="flex flex-row justify-center gap-1">
          <div className="">
            <DropdownCatalog />
          </div>
          <div className="">
            <DropdownCategory name="Kategori" category={category} />
          </div>
          <div className="">
            <DropdownBrand name="Brand" brands={brands} />
          </div>
        </div>
        <div className="w-full ml-auto">
          <input
            type="text"
            placeholder="Search"
            className="border-2 w-full border-gray-100 bg-gray-100 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:bg-white"
            onChange={(e) => {
              setSearch(e.target.value);
              props.onSearch(e.target.value);
              console.log(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
