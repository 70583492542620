import React, { useState, useEffect } from "react";

import Api from "../../../services/Api";

import { FaSearch, FaTrash, FaPlus } from "react-icons/fa";
import { useParams, Link } from "react-router-dom";
import Config from "../../../config/Config";

import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import Topmenu from "../../../components/adminqu/Topmenu";
import InfiniteScroll from "react-infinite-scroll-component";
import SideNav from "../../../components/adminqu/Sidenav";
import { FaChevronLeft } from "react-icons/fa";

function AdminquBrandEdit() {
  const params = useParams();

  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");

  const [authUser, setAuthUser] = useState({
    store_id: {
      name: "",
    },
  });

  useEffect(() => {
    const getUser = async (token) => {
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getDetailBrand = async (token) => {
      Api.get("/items/Brands/" + params.id, token)
        .then((response) => {
          //console.log(response);
          setName(response.data.name);
          setIsLoading(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const token = localStorage.getItem("token");
    setIsLoading(true);
    getUser(token);
    getDetailBrand(token);
  }, []);

  const submitEdit = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    Api.patch(
      "/items/Brands/" + params.id,
      {
        name: name,
      },
      token
    )
      .then((response) => {
        //console.log(response);

        if (response.errors) {
          toast.error(
            "Gagal menyimpan brand  (" + response.errors[0].message + " )"
          );
          setIsLoading(false);
          return;
        }

        if (response) {
          toast.success("Berhasil menyimpan brand");

          setIsLoading(false);
        }
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Gagal menyimpan brand");

        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="fixed w-full ml-0 mt-0 px-4 py-3 shadow bg-pink-500 text-white z-50">
        <div className="flex flex-row justify-between items-center">
          <div className="w-1/5">
            <Link to="/adminqu/brand">
              <FaChevronLeft className="text-white" />
            </Link>
          </div>
          <div className="flex-1">
            <div className="text-sm md:text-lg font-bold text-center">
              Edit brand{" "}
            </div>
          </div>
          <div className="w-1/5 text-right"></div>
        </div>
      </div>

      <div className="flex flex-col  pt-16 md:pt-20 px-4 bg-gray-50 min-h-screen">
        <div className="flex flex-row justify-center">
          <div className="w-full md:w-1/3">
            <div className="my-1 flex flex-col  gap-2">
              <div className="my-1 w-full md:w-full font-semibold">
                <span className="text-sm">Nama brand</span>
                <input
                  type="text"
                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="w-full flex justify-end">
                <div className="my-4 w-full md:w-1/3 font-semibold">
                  <div className="h-24 flex flex-row justify-center">
                    {isLoading ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        type="submit"
                        className="w-full bg-teal-500 text-white py-2 px-4 h-10 rounded-md hover:bg-teal-600 focus:outline-none focus:bg-blue-600"
                        onClick={submitEdit}>
                        Simpan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default AdminquBrandEdit;
