import React from "react";

function PageExpired() {
  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen bg-gray-100 p-4 text-center">
      <h1 className="text-2xl font-bold">Halaman toko tidak aktif</h1>
      <h2 className="text-xl ">
        Silahkan hubungi admin Kataloqu untuk informasi lebih lanjut
      </h2>
    </div>
  );
}

export default PageExpired;
