import React from "react";
import logo from "../../assets/images/logo.png";
import {
  FaUserAlt,
  FaPowerOff,
  FaShoppingBag,
  FaCog,
  FaPlus,
  FaSearch,
  FaTrash,
  FaUsers,
  FaBox,
  FaShoppingCart,
  FaHome,
  FaPallet,
  FaTshirt,
  FaBoxes,
} from "react-icons/fa";

import { Link } from "react-router-dom";

function AdminBlank() {
  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <div class="min-h-screen bg-gray-100">
            <div class="sidebar min-h-screen w-[3.35rem] overflow-hidden border-r lg:w-56 bg-white lg:hover:shadow-lg">
              <div class="flex h-screen flex-col justify-between pt-2 pb-6">
                <div>
                  <div class="w-max p-2.5 flex flex-row items-center gap-4">
                    <div>
                      <img
                        src={logo}
                        alt="logo"
                        class="w-8 h-8 rounded-full object-cover"
                      />
                    </div>
                    <div>Kataloqu Admin</div>
                  </div>
                  <ul class="mt-4 space-y-2 tracking-wide">
                    <li class="min-w-max">
                      <Link
                        to="/adminqu"
                        aria-label="dashboard"
                        class="relative flex
                        items-center space-x-6 bg-gradient-to-r from-pink-600
                        to-pink-400 px-4 py-3 lg:py-2 text-white">
                        <FaHome />
                        <span class="-mr-1 hidden lg:block font-medium">
                          Dashboard
                        </span>
                      </Link>
                    </li>
                    <li class="min-w-max">
                      <a
                        href="#"
                        class="group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600">
                        <FaShoppingCart />
                        <span class="group-hover:text-gray-700 hidden lg:block">
                          Order
                        </span>
                      </a>
                    </li>
                    <li className="border-t border-gray-200"></li>
                    <li class="min-w-max">
                      <a
                        href="#"
                        class="group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600">
                        <FaTshirt />
                        <span class="group-hover:text-gray-700 hidden lg:block">
                          Katalog
                        </span>
                      </a>
                    </li>

                    <li class="min-w-max">
                      <a
                        href="#"
                        class="bg group flex items-center space-x-6 rounded-full px-4 py-3 lg:py-2 text-gray-600">
                        <FaBoxes />
                        <span class="group-hover:text-gray-700 hidden lg:block">
                          Kategori
                        </span>
                      </a>
                    </li>
                    <li class="min-w-max">
                      <a
                        href="#"
                        class="bg group flex items-center space-x-6 rounded-full px-4 py-3 lg:py-2 text-gray-600">
                        <FaBox />
                        <span class="group-hover:text-gray-700 hidden lg:block">
                          Brand
                        </span>
                      </a>
                    </li>

                    <li className="border-t border-gray-200"></li>

                    <li class="min-w-max">
                      <a
                        href="#"
                        class="group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600">
                        <FaUsers />
                        <span class="group-hover:text-gray-700 hidden lg:block">
                          Reseller
                        </span>
                      </a>
                    </li>
                    <li className="border-t border-gray-200"></li>
                    <li class="min-w-max">
                      <a
                        href="#"
                        class="group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600">
                        <FaCog />
                        <span class="group-hover:text-gray-700 hidden lg:block">
                          Pengaturan
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="w-max -mb-3">
                  <a
                    href="#"
                    class="group flex items-center space-x-6 rounded-md px-4 py-3 lg:py-2 text-gray-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 group-hover:fill-cyan-600"
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="group-hover:text-gray-700">Settings</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-col w-full p-4 py-5 ">
            Lorem ipsum dolor sit amet co nsectetur adipisicing elit. Quisquam
            uhsuh uhsuhs uhsuhs
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminBlank;
