import React from "react";
import { FaShoppingBag, FaWhatsapp } from "react-icons/fa";

// import src/assets/images/mockup-desktop.png;

import mockupDesktop from "../../assets/images/mockup-desktop.png";
import mockupMobile from "../../assets/images/mockup-mobile.png";
import mycollectionLogo from "../../assets/images/mycollection-logo.png";
import azaliaLogo from "../../assets/images/azalia-logo.jpeg";
import aurynLogo from "../../assets/images/auryn-logo.jpeg";
import qnetLogo from "../../assets/images/qnet-logo.jpeg";
import nairaLogo from "../../assets/images/naira-logo.jpeg";
import logo from "../../assets/images/logo.png";

const currentUrl = window.location.href;
const segment2 = currentUrl.split("/")[3];

function Landing() {
  return (
    <div>
      <div className="flex flex-col px-2 lg:px-32 bg-pink-700 text-white pb-24">
        <div className="pt-8">
          <div className="flex flex-row gap-2 justify-center items-center mt-12">
            <img src={logo} className="w-16" />
            <div className="text-3xl font-bold mt-4">Kataloqu</div>
          </div>
        </div>
        <div>
          <div>
            <h1 className="text-3xl text-center mt-12">
              Mau punya website katalog online gak perlu ribet? <br />
              Pakai <span className="font-bold">Kataloqu</span> aja Yuk!
            </h1>
            <div className="flex flex-row justify-center mt-12">
              <div className="w-full lg:w-1/2 bg-white rounded-lg shadow-lg p-2 mt-8 h-64 lg:h-80 -ml-48 lg:-ml-0 py-0 overflow-hidden">
                <img src={mockupDesktop} className="w-full " />
              </div>
              <div className="w-1/2 lg:w-1/4 bg-white rounded-lg shadow-2xl p-4 -ml-6 lg:-ml-4 h-88 lg:h-96 -mt-1 overflow-hidden">
                <img src={mockupMobile} className="w-full " />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-center mt-12">
        <div className="flex flex-row justify-center mt-12">
          <h2 className="text-2xl font-bold text-center text-pink-500">
            Fitur
          </h2>
        </div>

        <div className="flex flex-col lg:flex-row px-2 lg:px-32 pb-16 lg:pb-0">
          <div className="w-full lg:w-1/3 bg-white p-8 py-2 text-center mt-8 lg:h-80">
            <h3 className="text-gray-800 text-xl font-semibold">
              Katalog Online mudah dan cepat, untuk bisnis Anda
            </h3>
            <p className="text-gray-600 mt-4">
              Buat katalog online tanpa batas, tanpa perlu bayar hosting, tanpa
              perlu beli domain, tanpa perlu beli template, tanpa perlu beli
              plugin, tanpa perlu beli apapun lagi. Cukup katalog online dari
              Kataloqu.com
            </p>
          </div>
          <div className="w-full lg:w-1/3 bg-white p-8 py-2 text-center mt-8 lg:h-80">
            <h3 className="text-gray-800 text-xl font-semibold">
              Buat link situs whitelabel untuk para reseller Anda
            </h3>
            <p className="text-gray-600 mt-4">
              Bisnis online Anda memiliki banyak reseller? Buatkan link situs
              katalog untuk reseller Anda untuk memudahkan mereka berjualan dan
              mempromosikan produk-produk Anda lebih profesional dan lebih mudah
            </p>
          </div>
          <div className="w-full lg:w-1/3 bg-white p-8 py-2 text-center mt-8 lg:h-80">
            <h3 className="text-gray-800 text-xl font-semibold">
              Arsipkan otomatis katalog preorder Anda
            </h3>
            <p className="text-gray-600 mt-4">
              Jualan barang preorder? Kataloqu.com akan mengarsipkan katalog
              Anda secara otomatis ketika tanggal preorder sudah lewat. Tidak
              perlu repot-repot menghapus katalog preorder Anda satu per satu
              ketika tanggal preorder sudah lewat
            </p>
          </div>
        </div>
      </div>

      <div className="justify-center pb-12 ">
        <div className="flex flex-row justify-center mb-12 ">
          <h2 className="text-2xl font-bold text-center text-pink-500">
            Harga berlangganan
          </h2>
        </div>

        <div className="flex flex-row justify-center lg:px-32">
          <div className="flex flex-col lg:flex-row px-2 lg:px-32">
            <div className="w-full rounded-xl bg-gray-50 px-10 py-8 mt-8 h-88">
              <h3 className="text-gray-800 text-xl font-semibold text-center">
                Mikroseller
              </h3>
              <p className="text-gray-600 mt-8">Max 100 Katalog </p>
              <p className="text-gray-600 mt-1">Max 20 Link situs reseller</p>
              <p className="text-gray-600 mt-1">Max 20 Pesanan / bulan</p>{" "}
              <p className="text-red-300 mt-1 line-through	">
                Arsipkan barang PO kadaluarsa otomatis
              </p>
              <p className="text-gray-600 mt-8  font-semibold">
                Rp48.000 / bulan
              </p>
              <p className="text-gray-600 my-2 text-sm font-semibold">atau</p>
              <p className="text-gray-600  text-lg font-semibold">
                Rp528.000 / tahun
              </p>
              <p className="text-gray-600 text-sm text-pink-600 font-semibold">
                hemat 1 bulan
              </p>
            </div>
            <div className="w-full rounded-xl shadow-xl bg-pink-50  px-10 py-12 -mb-8 -mt-2  ">
              <h3 className="text-pink-500 text-2xl font-semibold text-center">
                Maxiseller
              </h3>
              <p className="text-center mt-2">
                <span className="p-1.5 bg-amber-500 rounded-full px-4 text-sm text-white">
                  Rekomendasi
                </span>
              </p>
              <p className="text-gray-600 mt-8">Max 500 Katalog </p>
              <p className="text-gray-600 mt-1">Max 100 Link situs reseller</p>
              <p className="text-gray-600 mt-1">Max 100 Pesanan / bulan</p>
              <p className="text-gray-600 font-semibold mt-1">
                Arsipkan barang PO kadaluarsa otomatis
              </p>
              <p className="text-gray-600 mt-8 text-lg font-semibold">
                Rp58.000 / bulan
              </p>
              <p className="text-gray-600 my-2 text-sm font-semibold">atau</p>
              <p className="text-gray-600  text-lg font-semibold">
                Rp638.000 / tahun
              </p>
              <p className="text-gray-600 text-sm text-pink-600 font-semibold">
                hemat 1 bulan
              </p>
            </div>
            <div className="w-full rounded-xl bg-pink-700 text-white p-10 py-8  mt-8 h-88">
              <h3 className=" text-xl font-semibold text-center">Bigseller</h3>

              <p className=" mt-8">
                <span className="font-semibold">Unlimited</span> Katalog{" "}
              </p>
              <p className=" mt-1">
                <span className="font-semibold">Unlimited </span>link situs
                reseller
              </p>
              <p className="text-gray-600 mt-1">Unlimited Pesanan / bulan</p>

              <p className=" mt-1 font-semibold">
                Arsipkan barang PO kadaluarsa otomatis
              </p>
              <p className=" mt-8 text-lg font-semibold">Rp88.000 / bulan</p>
              <p className=" my-2 text-sm font-semibold">atau</p>
              <p className=" text-lg font-semibold">Rp968.000 / tahun</p>
              <p className=" text-sm text-yellow-400 font-semibold">
                hemat 1 bulan
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center px-2  lg:px-32 mt-8">
          <div className="w-full lg:w-1/3 text-center px-10 py-8 mt-8">
            <a
              href="https://api.whatsapp.com/send?phone=6285659658433&text=Halo%20Kataloqu.com%20saya%20mau%20langganan"
              target="_blank"
              className="bg-teal-500 text-xl text-white px-6 py-4 rounded-full hover:bg-teal-600 focus:outline-none focus:bg-blue-600">
              <FaWhatsapp className="inline-block mr-2" />
              Order Sekarang
            </a>
          </div>
        </div>
      </div>

      <div className="justify-center pb-12 mt-4 h-48 ">
        <div className="text-center text-lg mb-12 ">
          <p className="mb-4">Masih ragu dengan Kataloqu.com?</p>
          <h2 className="text-2xl font-bold text-center text-pink-500">
            Mereka seller yang sudah menggunakan Kataloqu.com
          </h2>
        </div>

        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center px-2 lg:px-32 mb-12">
          <div className="lg:w-40 lg:h-40 w-60 h-60  rounded-full shadow overflow-hidden">
            <img src={mycollectionLogo} className="w-full" />
          </div>
          <div className="lg:w-40 lg:h-40 w-60 h-60  rounded-full shadow overflow-hidden">
            <img src={azaliaLogo} className="w-full" />
          </div>
          <div className="lg:w-40 lg:h-40 w-60 h-60  rounded-full shadow overflow-hidden">
            <img src={aurynLogo} className="w-full" />
          </div>
          <div className="lg:w-40 lg:h-40 w-60 h-60  rounded-full shadow overflow-hidden">
            <img src={nairaLogo} className="w-full" />
          </div>
          <div className="lg:w-40 lg:h-40 w-60 h-60  rounded-full shadow overflow-hidden">
            <img src={qnetLogo} className="w-full" />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-4 items-center px-2 lg:px-32 py-16 mt-2 block bg-gray-50 mt-16">
          <div>
            <h3 className="text-2xl text-pink-600 font-semibold items-center">
              <img src={logo} className="w-16 inline-block" />
              Kataloqu.com
            </h3>
          </div>
          <div className="w-2/3 text-sm">
            Kataloqu adalah platform website katalog online yang memudahkan para
            penjual online untuk mendisplay produk dagangan mereka, serta
            mempromosikan secara online kepada pelanggan atau reseller. Anda
            dapat mengubungi kami melalui whatsapp di nomor{" "}
            <a
              className="text-pink-500"
              href="https://api.whatsapp.com/send?phone=6285659658433&text=Halo%20Kataloqu.com%20saya%20mau%20langganan">
              085659658433
            </a>{" "}
            atau melalui email kami{" "}
            <a className="text-pink-500" href="mailto:kataloqu.com@gmail.com">
              kataloqu.com@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
