import React, { useState, useEffect } from "react";
import Api from "../../services/Api";
// import { Directus } from "@directus/sdk";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaShoppingBag } from "react-icons/fa";
import ReactLoading from "react-loading";
import logo from "../../assets/images/logo.png";

function AdminquLogin() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const getUser = async (token) => {
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);

          // check store status is_activated
          if (response.data.store_id.is_activated == 1) {
            localStorage.setItem("auth_user", JSON.stringify(response.data));

            navigate("/adminqu/catalog");
            setIsLoading(false);
          } else {
            toast.error("Toko tidak aktif");
            localStorage.removeItem("auth_user");
            localStorage.removeItem("token");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const login = async () => {
      Api.post("/auth/login", {
        email: email,
        password: password,
      })
        .then((response) => {
          //console.log(response);
          if (response) {
            getUser(response.data.access_token);
            localStorage.setItem("token", response.data.access_token);
          }
        })
        .catch((error) => {
          //console.log(error);
          toast.error("Email atau password salah");
          setIsLoading(false);
        });
    };

    login();
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/adminqu/catalog");
    }
  }, []);

  return (
    <div className="flex h-screen bg-white">
      <div className="m-auto w-80">
        <div className="text-center flex flex-col justify-center gap-1 items-center mb-8">
          <img src={logo} className="w-20" alt="logo" />
          <h2 className="text-2xl font-semibold  text-left text-pink-600 rounded-full  ">
            Kataloqu
          </h2>
        </div>

        <h2 className="text-xl font-semibold mb-4">Login</h2>
        <form className="space-y-4" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email" className="block text-gray-700">
              Email
            </label>
            <input
              id="email"
              type="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-700">
              Password
            </label>
            <input
              id="password"
              type="password"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="h-24 flex flex-row justify-center">
            {isLoading ? (
              <ReactLoading
                type={"balls"}
                color={"gray"}
                height={10}
                width={48}
              />
            ) : (
              <button
                type="submit"
                className="w-full bg-pink-500 text-white py-2 px-4 h-10 rounded-md hover:bg-pink-600 focus:outline-none focus:bg-blue-600"
                onClick={handleLogin}>
                Login
              </button>
            )}
          </div>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
}

export default AdminquLogin;
