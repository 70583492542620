import React, { useState, useEffect } from "react";
import { FaWindowClose, FaChevronRight, FaChevronLeft } from "react-icons/fa";

import { Link } from "react-router-dom";
import Topmenu from "../../../components/adminqu/Topmenu";
import SideNav from "../../../components/adminqu/Sidenav";
import Api from "../../../services/Api";

import formatCurrency from "../../../helpers/formatNumber";
import PdfReportOrder from "./components/PdfReportOrder";

function AdminquReport() {
  const [isCustomerOpen, setIsCustomerOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState({
    first_name: "",
    id: "",
  });

  const token = localStorage.getItem("token");

  const thisMonth = String(new Date().getMonth() + 1).padStart(2, "0");
  const thisYear = new Date().getFullYear();
  const thisDay = String(new Date().getDate()).padStart(2, "0");

  const [fromDate, setFromDate] = useState(`${thisYear}-${thisMonth}-01`);
  const [toDate, setToDate] = useState(`${thisYear}-${thisMonth}-${thisDay}`);
  const [orders, setOrders] = useState([]);

  const [store, setStore] = useState({});

  const [isShowPL, setIsShowPL] = useState(true);

  const [totalShipping, setTotalShipping] = useState(0);
  const [totalAdditionalCost, setTotalAdditionalCost] = useState(0);
  const [totalSubtotal, setTotalSubtotal] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [total, setTotal] = useState(0);

  const getCustomers = async () => {
    Api.get(
      "/users?&filter[role][_eq]=41102248-b193-4b2a-ab2e-9182766b3113&fields=*&limit=-1&sort=first_name",
      token
    )
      .then((response) => {
        //console.log(response);
        if (response) {
          setCustomers(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getOrders = async () => {
    var queryBody =
      "fields=*.*&limit=-1&filter[_and][0][status][_eq]=completed";

    if (customer.id) {
      queryBody += "&filter[customer_id][_eq]=" + customer.id;
    }

    if (fromDate) {
      const fromDateData = new Date(fromDate).toISOString();
      queryBody += "&filter[date_created][_gte]=" + fromDateData;
    }

    if (toDate) {
      const toDateData = new Date(toDate).toISOString();
      const toDateEndOfDay = String(toDateData).split("T")[0] + "T23:59:59";
      queryBody += "&filter[_and][0][date_created][_lte]=" + toDateEndOfDay;
    }

    // sort by date_created desc
    queryBody += "&sort=-date_created";

    Api.get("/items/Orders?" + queryBody, token)
      .then((response) => {
        //console.log(response);
        if (response) {
          setOrders(response.data);

          setTotalShipping(
            response.data.reduce((acc, item) => acc + item.shipping_cost, 0)
          );

          setTotalAdditionalCost(
            response.data.reduce((acc, item) => acc + item.additional_cost, 0)
          );

          setTotalSubtotal(
            response.data.reduce((acc, item) => acc + item.subtotal, 0)
          );

          setTotalCost(
            response.data.reduce(
              (acc, item) =>
                acc + parseInt(item?.subtotal_cost ? item.subtotal_cost : 0),
              0
            )
          );

          setTotal(response.data.reduce((acc, item) => acc + item.total, 0));
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getUser = async (token) => {
    Api.get("/users/me?fields=*,store_id.*", token)
      .then((response) => {
        //console.log(response);
        setStore(response.data.store_id);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    getUser(token);
    getCustomers();
    getOrders();
  }, []);

  const generatePDF = () => {
    const data = {
      store: store,
      fromDate: fromDate,
      toDate: toDate,
      customer: customer,
      orders: orders,
    };
    PdfReportOrder(data);
  };

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <SideNav page="report" />
        </div>
        <div className="flex-1 justify-center items-center p-4 py-5">
          <div className="px-4 font-bold">Laporan</div>

          <div className="mt-4 px-4">
            <div className="flex flex-row gap-4">
              <div className="flex flex-row gap-2 items-center">
                <div className="flex-1">
                  Dari
                  <input
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    className="border-2 w-full border-gray-100 bg-gray-100 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:bg-white"
                  />
                </div>
                <div className="flex-1">
                  Sampai
                  <input
                    type="date"
                    value={toDate}
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                    className="border-2 w-full border-gray-100 bg-gray-100 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:bg-white"
                  />
                </div>
                <div className="flex-1">
                  Pelanggan
                  <input
                    type="text"
                    className="border-2 border-gray-200 w-full rounded-lg px-3 py-1.5 mt-1 font-normal"
                    value={customer.first_name}
                    readOnly={true}
                    onClick={() => setIsCustomerOpen(!isCustomerOpen)}
                  />
                </div>

                <div className="flex-1 flex gap-2 pt-7">
                  <button
                    className="bg-pink-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      getOrders();
                    }}>
                    Cari
                  </button>
                  <button
                    onClick={generatePDF}
                    className="bg-gray-100 hover:bg-gray-150 text-gray-800 font-bold py-2 px-4 rounded">
                    PDF
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center gap-2 text-xs px-8 my-4">
            Tampilkan ringkasan laba rugi
            <label
              for="toggleStatus"
              className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggleStatus"
                  className="sr-only"
                  onChange={(e) => setIsShowPL(e.target.checked)}
                  checked={isShowPL}
                />
                <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
              </div>
            </label>
          </div>

          {isShowPL && (
            <div className="px-4 w-full flex flex-row gap-2">
              <div className="p-4 bg-amber-50 rounded w-1/2">
                <div className="flex flex-row justify-between mb-2">
                  <span className="font-medium">Penjualan kotor</span>
                  <span className="font-medium text-sm text-gray-700 ">
                    {formatCurrency(total)}
                  </span>
                </div>
                <div className="text-sm flex flex-row justify-between">
                  <span>Total biaya pengiriman</span>
                  <span className="text-sm text-gray-700 font-normal">
                    {formatCurrency(totalShipping)}
                  </span>
                </div>
                <div className="text-sm flex flex-row justify-between">
                  <span>Total tambahan biaya</span>
                  <span className="text-sm text-gray-700 font-normal">
                    {formatCurrency(totalAdditionalCost)}
                  </span>
                </div>
              </div>
              <div className="p-4 bg-emerald-50 rounded w-1/2">
                <div className="flex flex-row justify-between mb-2">
                  <span className="font-medium">Penjualan bersih</span>
                  <span className=" font-medium text-sm text-gray-700">
                    {formatCurrency(
                      total - totalShipping - totalAdditionalCost
                    )}
                  </span>
                </div>
                <div className="text-sm flex flex-row justify-between">
                  <span>Total HPP</span>
                  <span className="text-sm text-gray-700 font-normal">
                    {formatCurrency(totalCost)}
                  </span>
                </div>
                <div className="text-sm flex flex-row justify-between font-bold">
                  <span>Laba/rugi</span>
                  <span className={"text-sm text-gray-700 font-bold"}>
                    {formatCurrency(
                      total - totalShipping - totalAdditionalCost - totalCost
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}

          <p className="text-md font-bold mt-8 px-4">Rincian Pesanan</p>
          <div className="pt-18 md:pt-8 lg:pt-4 px-4 rounded overflow-hidden">
            <table className="table-auto w-full text-xs rounded overflow-hidden">
              <thead>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 font-bold">No pesanan</td>
                  <td className="border px-4 py-2 font-bold">Tanggal</td>
                  <td className="border px-4 py-2 font-bold">Pelanggan</td>
                  <td className="border px-4 py-2 font-bold">Subtotal</td>
                  <td className="border px-4 py-2 font-bold">Ongkir</td>
                  <td className="border px-4 py-2 font-bold">Total</td>
                </tr>
              </thead>
              <tbody>
                {orders.map((item, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">
                      <Link to={"/adminqu/orders/" + item.id}>
                        #{item.order_number}{" "}
                      </Link>
                    </td>
                    <td className="border px-4 py-2">
                      {new Date(item.date_created).toLocaleDateString("id-ID", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </td>
                    <td className="border px-4 py-2">
                      {item.customer_id.first_name}
                    </td>
                    <td className="border px-4 py-2">
                      {formatCurrency(item.subtotal)}
                    </td>
                    <td className="border px-4 py-2">
                      {formatCurrency(item.shipping_cost)}
                    </td>
                    <td className="border px-4 py-2">
                      {formatCurrency(item.total)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="border px-4 py-2 font-bold" colSpan={3}>
                    Total
                  </td>
                  <td className="border px-4 py-2 font-bold">
                    {formatCurrency(
                      orders.reduce((acc, item) => acc + item.subtotal, 0)
                    )}
                  </td>
                  <td className="border px-4 py-2 font-bold">
                    {formatCurrency(
                      orders.reduce((acc, item) => acc + item.shipping_cost, 0)
                    )}
                  </td>
                  <td className="border px-4 py-2 font-bold">
                    {formatCurrency(
                      orders.reduce((acc, item) => acc + item.total, 0)
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        {isCustomerOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-30">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="bg-white w-3/4 md:w-1/3 rounded-lg py-8 z-50 mx-4">
              <div className="flex flex-row justify-end px-8">
                <button
                  className="-mt-6 mb-4 -mr-6 p-0 text-xl"
                  onClick={() => {
                    setIsCustomerOpen(false);
                  }}>
                  <FaWindowClose className="text-red-500 bg-white" />
                </button>
              </div>
              <div className="px-8 mb-4">
                <div className="my-1 mb-4 w-full md:w-full font-semibold flex flex-row justify-between">
                  <div> Pilih pelanggan</div>
                </div>
              </div>
              <div className="max-h-96 overflow-y-auto">
                {customers.map((item) => (
                  <div
                    className={
                      customer.id == item.id
                        ? "w-full px-8 py-2 text-sm px-4 bg-gray-100 cursor-pointer border-b border-gray-200 hover:bg-gray-100 flex flex-row justify-between"
                        : "w-full px-8 py-2 text-sm px-4 cursor-pointer border-b border-gray-200 hover:bg-gray-100 flex flex-row justify-between"
                    }
                    key={item.id}
                    onClick={() => {
                      setCustomer(item);
                      setIsCustomerOpen(false);
                    }}>
                    <div> {item.first_name}</div>
                    <div>
                      <FaChevronRight />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminquReport;
