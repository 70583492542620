import { jsPDF } from "jspdf";
import { applyPlugin } from "jspdf-autotable";
import formatCurrency from "../../../../helpers/formatNumber";

import React from "react";

applyPlugin(jsPDF);

export default function PdfReportOrder(data, mode = "save") {
  const generatePDF = () => {
    // pdf with A5 page size
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
      compress: true,
      precision: 16,
    });

    doc
      .setProperties({
        title: "Report Order",
        author: "Kataloqu",
        creator: "Kataloqu",
      })
      .html("", {
        callback: function (doc) {
          if (mode === "save") {
            doc.save("Report-Order.pdf");
          } else {
            doc.output("dataurlnewwindow", {
              filename: "Laporan-order.pdf",
            });
          }
        },
      });

    // setting the font size
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");

    doc.text(data?.store.name, 10, 13);
    doc.setFont("helvetica", "normal");

    doc.setFontSize(12);
    doc.text("Laporan Order", 10, 20);

    doc.setFontSize(9);
    doc.text("Dari", 10, 30);
    doc.text(": ", 40, 30);
    doc.text(new Date(data.fromDate).toDateString("id-ID"), 42, 30);

    doc.text("Sampai", 10, 35);
    doc.text(": ", 40, 35);
    doc.text(new Date(data.toDate).toDateString("id-ID"), 42, 35);

    doc.setFontSize(9);

    doc.text("Pelanggan", 110, 35);
    doc.text(": ", 140, 35);
    doc.text(data?.customer.first_name, 142, 35);

    // costs
    var dataCellItemCost = [];
    data?.orders.forEach((order, index) => {
      dataCellItemCost.push([
        index + 1,
        order.order_number,
        new Date(order.date_created).toDateString("id-ID", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        order.customer_id.first_name,
        formatCurrency(order.subtotal),
        formatCurrency(order.shipping_cost),
        formatCurrency(order.total),
      ]);
    });

    const sumSubtotal = data?.orders.reduce((acc, curr) => {
      return acc + curr.subtotal;
    }, 0);

    const sumShipping = data?.orders.reduce((acc, curr) => {
      return acc + curr.shipping_cost;
    }, 0);

    const sumTotal = data?.orders.reduce((acc, curr) => {
      return acc + curr.total;
    }, 0);

    // dataCellItemCost.push([
    //   "",
    //   "",
    //   "",
    //   "Total",
    //   formatCurrency(sumSubtotal),
    //   formatCurrency(sumShipping),
    //   formatCurrency(sumTotal),
    // ]);

    doc.autoTable({
      head: [
        [
          "No",
          "No Pesanan",
          "Tanggal",
          "Pelanggan",
          "Subtotal",
          "Ongkir",
          "Total",
        ],
      ],
      theme: "grid",
      styles: { cellPadding: 1, fontSize: 9 },
      headStyles: { fillColor: [100, 100, 100] },
      columnStyles: { 0: { cellWidth: 10 } },
      body: dataCellItemCost,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          formatCurrency(sumSubtotal),
          formatCurrency(sumShipping),
          formatCurrency(sumTotal),
        ],
      ],
      footStyles: { fillColor: [100, 100, 100] },
      startY: 40,
      margin: { left: 10 },
    });

    // doc.setFont("helvetica", "bold");
    // doc.setFontSize(10);
    // doc.text("Total", 140, doc.lastAutoTable.finalY + 10);

    // doc.setFont("helvetica", "normal");
    // doc.setFontSize(9);
    // doc.text(
    //   "" + formatCurrency(sumTotal) + "",
    //   155,
    //   doc.lastAutoTable.finalY + 10
    // );
  };

  generatePDF();
}
