import React, { useEffect, useState } from "react";

import { useParams, Link } from "react-router-dom";
import {
  FaChevronLeft,
  FaChevronRight,
  FaPlus,
  FaWindowClose,
  FaList,
  FaTrash,
} from "react-icons/fa";
import Api from "../../../services/Api";
import Config from "../../../config/Config";

import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import SideNav from "../../../components/adminqu/Sidenav";

import { FaChevronDown } from "react-icons/fa";

function AdminquCatalogAdd() {
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [cost, setCost] = useState("");
  const [price, setPrice] = useState("");

  const [isAcceptOnlineOrder, setIsAcceptOnlineOrder] = useState(true);
  const [isRangePrice, setIsRangePrice] = useState(false);
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");

  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [categoriesAll, setCategoriesAll] = useState([{}]);
  const [categories, setCategories] = useState([{}]);
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryAddName, setCategoryAddName] = useState("");

  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [brandsAll, setBrandsAll] = useState([{}]);
  const [brands, setBrands] = useState([{}]);
  const [brand, setBrand] = useState("");
  const [brandName, setBrandName] = useState("");
  const [brandAddName, setBrandAddName] = useState("");

  const [stock, setStock] = useState(0);
  const [weight, setWeight] = useState(500);

  const [isVariantOpen, setIsVariantOpen] = useState(false);
  const [variations, setVariations] = useState([]);

  const [customerGroups, setCustomerGroups] = useState([{}]);

  const [description, setDescription] = useState("");

  // now date yyyy-mm-dd
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  const [preorderEnd, setPreorderEnd] = useState(yyyy + "-" + mm + "-" + dd);
  const [preorderLabel, setPreorderLabel] = useState("PO Berakhir Hingga");

  const [isDiscount, setIsDiscount] = useState(false);
  const [discount, setDiscount] = useState(0);

  const [discountFrom, setDiscountFrom] = useState(0);
  const [discountTo, setDiscountTo] = useState(0);

  const [discountEnd, setDiscountEnd] = useState(yyyy + "-" + mm + "-" + dd);
  const [discountShowReseller, setDiscountShowReseller] = useState(true);

  const [isPreorder, setIsPreorder] = useState(false);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState("");

  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFilePreview, setSelectedFilePreview] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [isPublished, setIsPublished] = useState(true);

  const [tabActive, setTabActive] = useState("info");

  const [link, setLink] = useState({
    url: "",
    label: "",
  });
  const [links, setLinks] = useState([]);

  const [authUser, setAuthUser] = useState({
    store_id: {
      name: "",
    },
  });

  const [isDiscountOpen, setIsDiscountOpen] = useState(false);
  const [priceCustomerGroup, setPriceCustomerGroup] = useState([]);

  useEffect(() => {
    const getUser = async (token) => {
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);
          getCategory(token, response.data.store_id.id);
          getBrand(token, response.data.store_id.id);
          getCustomerGroup(token, response.data.store_id.id);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getCategory = async (token, storeId) => {
      Api.get(
        "/items/Categories?limit=-1&sort=name&fields=id,name&filter[store_id][_eq]=" +
          storeId,
        token
      )
        .then((response) => {
          console.log(response);
          setCategoriesAll(response.data);
          setCategories(response.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };
    const getBrand = async (token, storeId) => {
      Api.get(
        "/items/Brands?limit=-1&sort=name&fields=id,name&filter[store_id][_eq]=" +
          storeId,
        token
      )
        .then((response) => {
          console.log(response);
          setBrandsAll(response.data);
          setBrands(response.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getCustomerGroup = async (token, storeId) => {
      Api.get(
        "/items/Store_Customer_Group?limit=-1&sort=name&fields=id,name&filter[store_id][_eq]=" +
          storeId,
        token
      )
        .then((response) => {
          console.log(response);
          setCustomerGroups(response.data);

          const tmp = [];
          for (let item of response.data) {
            tmp.push({
              customer_group_id: item.id,
              discount: 0,
            });
          }

          setPriceCustomerGroup(tmp);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const token = localStorage.getItem("token");
    getUser(token);
  }, []);

  // filter category when categoryAddName change
  useEffect(() => {
    const tmp = categoriesAll.filter((item) => {
      return item.name?.toLowerCase().includes(categoryAddName.toLowerCase());
    });

    setCategories(tmp);
  }, [categoryAddName]);

  // filter brand when brandAddName change
  useEffect(() => {
    const tmp = brandsAll.filter((item) => {
      return item.name?.toLowerCase().includes(brandAddName.toLowerCase());
    });

    setBrands(tmp);
  }, [brandAddName]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files);
  };

  const addCategory = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    Api.post(
      "/items/Categories",
      {
        store_id: authUser.store_id.id,
        name: categoryAddName,
      },
      token
    )
      .then((response) => {
        //console.log(response);

        if (response.errors) {
          toast.error(
            "Gagal menyimpan kategori  (" + response.errors[0].message + " )"
          );
          setIsLoading(false);
          return;
        }

        if (response) {
          console.log("response", response);
          setCategory(response.data.id);
          setCategoryName(response.data.name);
          setIsCategoryOpen(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Gagal menambahkan kategori");
        setIsLoading(false);
      });
  };

  const addBrand = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    Api.post(
      "/items/Brands",
      {
        store_id: authUser.store_id.id,
        name: brandAddName,
      },
      token
    )
      .then((response) => {
        //console.log(response);

        if (response.errors) {
          toast.error(
            "Gagal menyimpan brand  (" + response.errors[0].message + " )"
          );
          setIsLoading(false);
          return;
        }

        if (response) {
          console.log("response", response);
          setBrand(response.data.id);
          setBrandName(response.data.name);
          setIsBrandOpen(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Gagal menambahkan brand");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    //console.log("images", images);
  }, [images]);

  useEffect(() => {
    const files = selectedFile;
    console.log("files", files);
    const tmp = images;

    const uploadFile = async (files) => {
      let i = 0;
      for (let item of files) {
        setIsUploading(true);
        function sleep(ms) {
          return new Promise((resolve) => setTimeout(resolve, ms));
        }

        const upload = async (file) => {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("token")
          );

          var formdata = new FormData();
          formdata.append("file", file, file.name);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
          };

          fetch(Config.api_url + "/files", requestOptions)
            .then((response) => response.text())
            .then((result) => {
              //console.log(result);
              const data = JSON.parse(result).data;
              addImage(data);
            })
            .catch((error) => {
              //console.log("error", error)
            });
        };

        const addImage = (data) => {
          const imgs = images;
          imgs.push(data.id);

          setImages([]);

          setImages(imgs);

          if (i == files.length - 1) {
            setIsUploading(false);
            if (image == "") {
              setImage(tmp[0]);
            }
          }

          i++;
        };

        await sleep(700);
        upload(item);
      }
    };

    if (files.length > 0) {
      uploadFile(files);
    }
  }, [selectedFile]);

  const submitAdd = async () => {
    const catalogAddProcess = async (token) => {
      const dataImages = [];

      let sequence = 1;
      for (let item of images) {
        dataImages.push({ image: item, sequence: sequence });
        sequence++;
      }

      const listVariations = [];
      for (let item of variations) {
        listVariations.push({
          name: item.name,
          stock: item.stock,
          cost: parseFloat(item.cost),
          price: parseFloat(item.price),
          price_discount: parseFloat(item.priceDiscount),
        });
      }

      Api.post(
        "/items/Catalog",
        {
          store_id: authUser.store_id.id,
          name: name,
          accept_online_order: isAcceptOnlineOrder,
          cost: parseFloat(cost),
          price: parseFloat(price),
          is_range_price: isRangePrice,
          price_from: parseFloat(priceFrom),
          price_to: parseFloat(priceTo),
          category_id: category,
          brand_id: brand,
          description: description,
          is_discount: isDiscount,
          discount: discount,
          discount_from: parseFloat(discountFrom),
          discount_to: parseFloat(discountTo),
          discount_end: discountEnd,
          show_discount_in_reseller: discountShowReseller,
          is_preorder: isPreorder,
          preorder_closed_date: preorderEnd,
          preorder_additional_text: preorderLabel,
          status: isPublished ? "published" : "archived",
          image: image,
          images: dataImages,
          links: links,
          stock: parseFloat(stock),
          weight: parseFloat(weight),
          variations: listVariations,
          date_updated: new Date(),
          price_customer_group: priceCustomerGroup,
        },
        token
      )
        .then((response) => {
          //console.log(response);

          if (response.errors) {
            toast.error(
              "Gagal menyimpan katalog  (" + response.errors[0].message + " )"
            );
            setIsLoading(false);
            return;
          }

          if (response) {
            toast.success("Berhasil menambahkan katalog");
            setName("");
            setCost("");
            setPrice("");
            setIsRangePrice(false);
            setPriceFrom("");
            setPriceTo("");
            setCategory("");
            setCategoryName("");
            setBrand("");
            setBrandName("");
            setDescription("");
            setImages([]);
            setIsDiscount(false);
            setDiscount(0);
            setDiscountFrom(0);
            setDiscountTo(0);
            setDiscountEnd("");
            setIsPreorder(false);
            setDiscountShowReseller(true);
            setPreorderEnd("");
            setPreorderLabel("");
            setImage("");
            setImages([]);
            setLink({
              url: "",
              label: "",
            });
            setLinks([]);
            setIsLoading(false);
            setVariations([]);
            setStock(0);
          }
        })
        .catch((error) => {
          //console.log(error);
          toast.error("Gagal menambahkan katalog");

          setIsLoading(false);
        });
    };

    setIsLoading(true);
    const token = localStorage.getItem("token");

    Api.post(
      "/flows/trigger/20454ec1-896b-40d5-8590-bba1ff04b2b0",
      {
        store_id: authUser.store_id.id,
      },
      token
    ).then((response) => {
      //console.log(response);
      if (response.is_eligible == true) {
        catalogAddProcess(token);
      } else {
        toast.error(
          "Paket anda tidak memungkinkan untuk menambahkan katalog, silahkan upgrade paket anda atau hapus katalog yang sudah ada"
        );
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <SideNav />
        </div>
        <div className="flex-1">
          <div>
            <div className="fixed w-full ml-0 mt-0 px-4 py-3 shadow bg-pink-500 text-white z-50">
              <div className="flex flex-row justify-between items-center">
                <div className="w-8">
                  <Link to="/adminqu/catalog">
                    <FaChevronLeft className="text-white" />
                  </Link>
                </div>
                <div className="flex-1">
                  <div className="text-sm md:text-lg font-bold text-left">
                    Tambah katalog{" "}
                  </div>
                </div>
                <div className="w-1/5 text-right"></div>
              </div>
            </div>

            <div className="flex flex-col  pt-16 md:pt-20 px-4 bg-gray-50 min-h-screen">
              <div className="my-2 justify-center flex">
                <div className="flex w-full lg:w-1/2 md:w-1/2 flex-row justify-center">
                  <div className="w-full md:w-1/2">
                    <button
                      onClick={() => setTabActive("info")}
                      className={
                        tabActive == "info"
                          ? "w-full bg-pink-100  text-gray-800 font-bold py-2 px-2 text-sm rounded"
                          : "w-full bg-gray-100  text-gray-800 font-bold py-2 px-2 text-sm rounded"
                      }>
                      Informasi produk
                    </button>
                  </div>
                  <div className="w-full md:w-1/2">
                    <button
                      onClick={() => setTabActive("link")}
                      className={
                        tabActive == "link"
                          ? "w-full bg-pink-100  text-gray-800 font-bold py-2 px-2 text-sm rounded"
                          : "w-full bg-gray-100  text-gray-800 font-bold py-2 px-2 text-sm rounded"
                      }>
                      Link
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-center">
                {tabActive == "info" && (
                  <div className="w-full md:w-1/2">
                    <div className="my-1 flex flex-col md:flex-row gap-2">
                      <div className="my-1 w-full md:w-full font-semibold">
                        <span className="text-sm">Nama produk</span>
                        <input
                          type="text"
                          className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="my-1 flex flex-col md:flex-row gap-2">
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">Harga modal</span>
                        <input
                          type="number"
                          className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                          value={cost}
                          onChange={(e) => setCost(e.target.value)}
                        />
                      </div>
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">Harga jual</span>
                        <input
                          type="number"
                          className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          disabled={isRangePrice}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between  w-full mb-4 mt-1">
                      <div className=" text-sm">Gunakan rentang harga?</div>
                      <label
                        for="toggleRangePrice"
                        className="flex items-center cursor-pointer">
                        <div className="relative">
                          <input
                            type="checkbox"
                            id="toggleRangePrice"
                            className="sr-only"
                            onChange={(e) => setIsRangePrice(e.target.checked)}
                          />
                          <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                          <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                        </div>
                      </label>
                    </div>
                    {isRangePrice && (
                      <div className="my-1 flex flex-col md:flex-row gap-2">
                        <div className="my-1 w-full md:w-1/2 font-semibold">
                          <span className="text-sm">
                            Mulai{" "}
                            <span className="text-xs">(harga terendah)</span>
                          </span>
                          <input
                            type="number"
                            placeholder="80000"
                            className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                            value={priceFrom}
                            onChange={(e) => setPriceFrom(e.target.value)}
                          />
                        </div>
                        <div className="my-1 w-full md:w-1/2 font-semibold">
                          <span className="text-sm">
                            Hingga{" "}
                            <span className="text-xs">(harga tertinggi)</span>
                          </span>
                          <input
                            type="number"
                            placeholder="100000"
                            className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                            value={priceTo}
                            onChange={(e) => setPriceTo(e.target.value)}
                          />
                        </div>
                      </div>
                    )}

                    <span className="text-sm font-semibold">
                      Pengaturan Harga diskon
                    </span>
                    <div className="flex items-center justify-between  w-full mb-4 mt-1">
                      <div className=" text-sm">Harga diskon?</div>
                      <label
                        for="toggleC"
                        className="flex items-center cursor-pointer">
                        <div className="relative">
                          <input
                            type="checkbox"
                            id="toggleC"
                            className="sr-only"
                            onChange={(e) => setIsDiscount(e.target.checked)}
                          />
                          <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                          <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                        </div>
                      </label>
                    </div>

                    <div className="my-1">
                      {isDiscount && (
                        <>
                          {isRangePrice ? (
                            <div className="my-1 flex flex-row flex-col md:flex-row gap-2">
                              <div className="my-1 w-full md:w-1/2 ">
                                <span className="text-sm">
                                  Mulai{" "}
                                  <span className="text-xs">
                                    (diskon terendah)
                                  </span>
                                </span>
                                <input
                                  type="number"
                                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                                  value={discountFrom}
                                  onChange={(e) =>
                                    setDiscountFrom(e.target.value)
                                  }
                                />
                              </div>
                              <div className="my-1 w-full md:w-1/2 ">
                                <span className="text-sm">
                                  Hingga{" "}
                                  <span className="text-xs">
                                    (diskon tertinggi)
                                  </span>
                                </span>
                                <input
                                  type="number"
                                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                                  value={discountTo}
                                  onChange={(e) =>
                                    setDiscountTo(e.target.value)
                                  }
                                />
                              </div>
                              <div className="my-1 w-full md:w-1/2">
                                <span className="text-sm">Berakhir diskon</span>
                                <input
                                  type="date"
                                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal bg-white"
                                  value={discountEnd}
                                  onChange={(e) =>
                                    setDiscountEnd(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="my-1 flex flex-col md:flex-row gap-2">
                              <div className="my-1 w-full md:w-1/2 ">
                                <span className="text-sm">Harga diskon</span>
                                <input
                                  type="number"
                                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                                  value={discount}
                                  onChange={(e) => setDiscount(e.target.value)}
                                />
                              </div>
                              <div className="my-1 w-full md:w-1/2">
                                <span className="text-sm">Berakhir diskon</span>
                                <input
                                  type="date"
                                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal bg-white"
                                  value={discountEnd}
                                  onChange={(e) =>
                                    setDiscountEnd(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className="flex items-center justify-between  w-full mb-4 mt-1">
                            <div className=" text-sm">
                              Tampilkan pada situs reseller
                            </div>
                            <label
                              for="toggleD"
                              className="flex items-center cursor-pointer">
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  id="toggleD"
                                  className="sr-only"
                                  onChange={(e) =>
                                    setDiscountShowReseller(e.target.checked)
                                  }
                                  checked={discountShowReseller}
                                  value={discountShowReseller}
                                />
                                <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                                <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                              </div>
                            </label>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="my-1 flex flex-col md:flex-row gap-2">
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">Kategori</span>
                        <div className="flex flex-row">
                          <input
                            type="text"
                            className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                            value={categoryName}
                            readOnly={true}
                            onClick={() => setIsCategoryOpen(!isCategoryOpen)}
                          />
                          <FaChevronDown className="text-gray-500 -ml-8 mt-5" />
                        </div>
                      </div>
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">Brand</span>
                        <div className="flex flex-row">
                          <input
                            type="text"
                            className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                            value={brandName}
                            readOnly={true}
                            onClick={() => setIsBrandOpen(!isBrandOpen)}
                          />

                          <FaChevronDown className="text-gray-500 -ml-8 mt-5" />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center justify-between  w-full mb-4 mt-1">
                      <button
                        onClick={() => {
                          const tmp = priceCustomerGroup.map((item3) => {
                            if (item3.price == 0) {
                              item3.price = parseInt(discount);
                            }
                            return item3;
                          });

                          setPriceCustomerGroup(tmp);
                          setIsDiscountOpen(!isDiscountOpen);
                        }}
                        className="border-2 bg-white w-full rounded-lg px-3 py-2 mt-1 font-normal flex flex-row gap-2 items-center">
                        <FaList /> Diskon khusus grup pelanggan
                      </button>
                    </div>

                    <div className="my-1 flex flex-col md:flex-row gap-2">
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">Stok</span>
                        <input
                          type={variations.length > 0 ? "text" : "number"}
                          className={
                            variations.length > 0
                              ? "border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal text-red-600"
                              : "border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal "
                          }
                          value={
                            variations.length > 0
                              ? "Silahkan atur pada variasi"
                              : stock
                          }
                          onChange={(e) => setStock(e.target.value)}
                          disabled={variations.length > 0}
                        />
                      </div>
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">Variasi Produk?</span>
                        <div className="flex flex-row">
                          <button
                            onClick={() => {
                              setIsVariantOpen(!isVariantOpen);
                            }}
                            className="border-2 bg-white-500 text-gray w-full rounded-lg px-3 py-2 mt-1 font-normal flex flex-row gap-2 items-center">
                            <FaList /> Set variasi produk
                            <span
                              className={
                                variations.length > 0
                                  ? "bg-pink-500 text-xs text-white rounded-full px-3 py-1"
                                  : "bg-gray-500 text-xs text-white rounded-full px-3 py-1"
                              }>
                              {variations.length}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="my-1 font-semibold">
                      <span className="text-sm">Deskripsi</span>
                      <div className="w-full flex-1 bg-white">
                        <ReactQuill
                          theme="snow"
                          value={description}
                          onChange={setDescription}
                          className="h-48 mb-4 pb-10 bg-white"
                        />
                      </div>
                    </div>
                    <div className="my-1 font-semibold">
                      <span className="text-sm">Gambar</span>
                      <label
                        for="file-upload"
                        className="w-full h-24 bg-gray-200">
                        <div className="flex flex-row justify-center items-center bg-gray-200 border border-dashed border-4 border-gray-300 py-8 text-white rounded h-full cursor-pointer">
                          <span className="text-sm text-gray-500">
                            Drag and drop file here
                          </span>
                        </div>
                      </label>
                      <input
                        type="file"
                        id="file-upload"
                        name="fileupload[]"
                        className="mt-1 rounded border hidden"
                        onChange={handleFileChange}
                        multiple
                      />
                    </div>

                    <div className="mt-4">
                      {isUploading && (
                        <div className="w-full">
                          <div className="flex flex-row justify-center items-center h-12 my-8">
                            <ReactLoading
                              type={"balls"}
                              color={"gray"}
                              height={10}
                              width={48}
                            />
                          </div>
                        </div>
                      )}

                      <div className="flex flex-row flex-wrap">
                        {images.map((item) => (
                          <div
                            className={
                              image == item
                                ? "w-1/4 lg:w-1/5 p-1 lg:p-2 border border-pink-500 cursor-pointer"
                                : "w-1/4 lg:w-1/5 p-1 lg:p-2 cursor-pointer"
                            }
                            key={item}
                            onClick={() => {
                              setSelectedFilePreview(item);
                              openModal();
                            }}>
                            <img
                              src={
                                Config.api_url +
                                "/assets/" +
                                item +
                                "?fit=cover&width=256&height=256"
                              }
                              className="w-full h-20 lg:h-32 object-cover overflow-hidden rounded-lg"
                            />
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="my-1 flex flex-col md:flex-row gap-2">
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">Berat barang (gram)</span>
                        <input
                          type="number"
                          className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                          value={weight}
                          onChange={(e) => setWeight(e.target.value)}
                        />
                      </div>
                      <div className="my-1 w-full md:w-1/2 font-semibold"></div>
                    </div>

                    <div className="flex items-center justify-between  w-full mb-4 mt-8">
                      <div className=" text-sm">Terima order via website?</div>
                      <label
                        for="toggleAcceptOnlineOrder"
                        className="flex items-center cursor-pointer">
                        <div className="relative">
                          <input
                            type="checkbox"
                            id="toggleAcceptOnlineOrder"
                            className="sr-only"
                            checked={isAcceptOnlineOrder}
                            onChange={(e) =>
                              setIsAcceptOnlineOrder(e.target.checked)
                            }
                          />
                          <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                          <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                        </div>
                      </label>
                    </div>

                    <div className="mt-4">
                      <span className="text-sm font-semibold">
                        Pengaturan pre-order
                      </span>
                      <div className="flex items-center justify-between  w-full mb-4 mt-1">
                        <div className=" text-sm">Katalog preorder?</div>
                        <label
                          for="toggleB"
                          className="flex items-center cursor-pointer">
                          <div className="relative">
                            <input
                              type="checkbox"
                              id="toggleB"
                              className="sr-only"
                              onChange={(e) => setIsPreorder(e.target.checked)}
                            />
                            <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                            <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                          </div>
                        </label>
                      </div>

                      {isPreorder && (
                        <div className="my-1 flex flex-row gap-2">
                          <div className="my-1 w-1/2 md:w-1/2">
                            <span className="text-sm">Berakhir preorder</span>
                            <input
                              type="date"
                              className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal bg-white"
                              value={preorderEnd}
                              onChange={(e) => setPreorderEnd(e.target.value)}
                            />
                          </div>
                          <div className="my-1 w-1/2 md:w-1/2 ">
                            <span className="text-sm">
                              Label informasi preorder
                            </span>
                            <input
                              type="text"
                              className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                              value={preorderLabel}
                              onChange={(e) => setPreorderLabel(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      <div className="flex items-center justify-between  w-full mb-4 mt-1">
                        <div className=" text-sm font-semibold">
                          Publikasikan?
                        </div>
                        <label
                          for="toggleStatus"
                          className="flex items-center cursor-pointer">
                          <div className="relative">
                            <input
                              type="checkbox"
                              id="toggleStatus"
                              className="sr-only"
                              onChange={(e) => setIsPublished(e.target.checked)}
                              checked={isPublished}
                            />
                            <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                            <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                          </div>
                        </label>
                      </div>

                      <div className="w-full flex justify-end">
                        <div className="my-4 w-full md:w-1/3 font-semibold">
                          <div className="h-24 flex flex-row justify-center">
                            {isLoading ? (
                              <ReactLoading
                                type={"balls"}
                                color={"gray"}
                                height={10}
                                width={48}
                              />
                            ) : (
                              <button
                                type="submit"
                                className="w-full bg-teal-500 text-white py-2 px-4 h-10 rounded-md hover:bg-teal-600 focus:outline-none focus:bg-blue-600"
                                onClick={submitAdd}>
                                Simpan
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {tabActive == "link" && (
                  <div className="w-full md:w-1/2">
                    <div className="my-1 flex flex-col md:flex-row gap-2">
                      <div className="my-1 w-full md:w-1/2 font-semibold">
                        <span className="text-sm">URL</span>
                        <input
                          type="text"
                          className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                          value={link.url}
                          onChange={(e) => {
                            setLink({
                              url: e.target.value,
                              label: link.label,
                            });
                          }}
                        />
                      </div>
                      <div className="flex gap-2 flex-row">
                        <div className="my-1 w-1/2 md:w-1/2 font-semibold">
                          <span className="text-sm">Nama label</span>
                          <input
                            type="text"
                            className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                            value={link.label}
                            onChange={(e) => {
                              setLink({
                                url: link.url,
                                label: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="my-1 w-1/2 md:w-1/2 font-semibold">
                          <span className="text-sm">
                            <br />
                          </span>
                          <button
                            onClick={() => {
                              const tmp = links;
                              tmp.push(link);
                              setLinks(tmp);
                              setLink({
                                url: "",
                                label: "",
                              });
                            }}
                            className="border-2 bg-pink-500 text-white w-full rounded-lg px-3 py-2 mt-1 font-normal">
                            + Tambah link
                          </button>
                        </div>
                      </div>
                    </div>
                    {links.length > 0 && (
                      <div className="w-full">
                        <div className="flex flex-row flex-wrap">
                          {links.map((item, index) => (
                            <div
                              className="w-full md:w-1/2 py-1 lg:px-1 "
                              key={item.url}>
                              <div className="flex flex-row justify-between items-center bg-gray-200 border border-dashed border-2 border-gray-300 py-2 px-4 text-pink-500 rounded h-full">
                                <div className="text-sm">
                                  <span className="text-gray-600 font-bold">
                                    {item.label}
                                  </span>
                                  <br />
                                  <span className="text-xs">{item.url}</span>
                                </div>

                                <div className="text-sm w-4">
                                  <FaWindowClose
                                    className="text-red-500 bg-white cursor-pointer"
                                    onClick={() => {
                                      const tmp = links;
                                      tmp.splice(index, 1);
                                      console.log("tmp", tmp);

                                      setLinks([]);
                                      setTimeout(() => {
                                        setLinks(tmp);
                                      }, 50);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="w-full flex justify-end">
                      <div className="my-4 w-full md:w-1/3 font-semibold">
                        <div className="h-24 flex flex-row justify-center">
                          {isLoading ? (
                            <ReactLoading
                              type={"balls"}
                              color={"gray"}
                              height={10}
                              width={48}
                            />
                          ) : (
                            <button
                              type="submit"
                              className="w-full bg-teal-500 text-white py-2 px-4 h-10 rounded-md hover:bg-teal-600 focus:outline-none focus:bg-blue-600"
                              onClick={submitAdd}>
                              Simpan
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {isOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white rounded-lg p-8 z-50 mx-4">
                  <div className="flex flex-row justify-end">
                    <button
                      className="-mt-6 mb-4 -mr-6 p-0 text-xl"
                      onClick={closeModal}>
                      <FaWindowClose className="text-red-500 bg-white" />
                    </button>
                  </div>
                  <div className="max-h-96 overflow-y-auto">
                    <img
                      src={Config.api_url + "/assets/" + selectedFilePreview}
                      alt="Product 1"
                      className="w-full h-auto"
                    />
                  </div>

                  <div className="flex gap-2 flex-row justify-end ">
                    <button
                      onClick={() => {
                        setImage(selectedFilePreview);
                        closeModal();
                      }}
                      className="mt-4 bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-1 px-2 text-sm rounded">
                      Jadikan gambar utama
                    </button>
                    <button
                      onClick={() => {
                        const imgs = images;
                        const index = imgs.indexOf(selectedFilePreview);
                        if (index > -1) {
                          imgs.splice(index, 1);
                        }

                        setImages([]);
                        setTimeout(() => {
                          setImages(imgs);
                          //console.log("images state", images);
                          setIsUploading(false);
                          closeModal();
                        }, 500);
                      }}
                      className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 text-sm rounded">
                      Hapus gambar ini
                    </button>
                  </div>
                </div>
              </div>
            )}

            {isCategoryOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white w-3/4 md:w-1/3 rounded-lg py-8 z-50 mx-4">
                  <div className="flex flex-row justify-end px-8">
                    <button
                      className="-mt-6 mb-4 -mr-6 p-0 text-xl"
                      onClick={() => {
                        setIsCategoryOpen(false);
                      }}>
                      <FaWindowClose className="text-red-500 bg-white" />
                    </button>
                  </div>
                  <div className="px-8 mb-4">
                    <div className="my-1 mb-4 w-full md:w-full font-semibold flex flex-row justify-between">
                      <div> Pilih kategori atau buat kategori baru</div>
                    </div>
                    <div className="flex flex-row justify-between items-center gap-2">
                      <input
                        type="text"
                        className="border-2 border-gray-200 w-full rounded-lg px-3 py-1 mt-1 font-normal"
                        value={categoryAddName}
                        onChange={(e) => setCategoryAddName(e.target.value)}
                      />
                      <button
                        className="bg-pink-500 text-white font-bold py-1 px-4 h-9 rounded-md hover:bg-pink-600 focus:outline-none focus:bg-pink-600"
                        onClick={() => {
                          addCategory();
                        }}>
                        Buat
                      </button>
                    </div>
                  </div>
                  <div className="max-h-96 overflow-y-auto">
                    {categories.map((item) => (
                      <div
                        className={
                          category == item.id
                            ? "w-full px-8 py-2 text-sm px-4 bg-gray-100 cursor-pointer border-b border-gray-200 hover:bg-gray-100 flex flex-row justify-between"
                            : "w-full px-8 py-2 text-sm px-4 cursor-pointer border-b border-gray-200 hover:bg-gray-100 flex flex-row justify-between"
                        }
                        key={item.id}
                        onClick={() => {
                          setCategory(item.id);
                          setCategoryName(item.name);
                          setIsCategoryOpen(false);
                        }}>
                        <div> {item.name}</div>
                        <div>
                          <FaChevronRight />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {isBrandOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white w-3/4 md:w-1/3 rounded-lg py-8 z-50 mx-4">
                  <div className="flex flex-row justify-end px-8">
                    <button
                      className="-mt-6 mb-4 -mr-6 p-0 text-xl"
                      onClick={() => {
                        setIsBrandOpen(false);
                      }}>
                      <FaWindowClose className="text-red-500 bg-white" />
                    </button>
                  </div>
                  <div className="px-8 mb-4">
                    <div className="my-1 mb-4 w-full md:w-full font-semibold flex flex-row justify-between">
                      <div> Pilih brand atau buat brand baru</div>
                    </div>
                    <div className="flex flex-row justify-between items-center gap-2">
                      <input
                        type="text"
                        className="border-2 border-gray-200 w-full rounded-lg px-3 py-1 mt-1 font-normal"
                        value={brandAddName}
                        onChange={(e) => setBrandAddName(e.target.value)}
                      />
                      <button
                        className="bg-pink-500 text-white font-bold py-1 px-4 h-9 rounded-md hover:bg-pink-600 focus:outline-none focus:bg-pink-600"
                        onClick={() => {
                          addBrand();
                        }}>
                        Buat
                      </button>
                    </div>
                  </div>
                  <div className="max-h-96 overflow-y-auto">
                    {brands.map((item) => (
                      <div
                        className={
                          brand == item.id
                            ? "w-full px-8 py-2 text-sm px-4 bg-gray-100 cursor-pointer border-b border-gray-200 hover:bg-gray-100 flex flex-row justify-between"
                            : "w-full px-8 py-2 text-sm px-4 cursor-pointer border-b border-gray-200 hover:bg-gray-100 flex flex-row justify-between"
                        }
                        key={item.id}
                        onClick={() => {
                          setBrand(item.id);
                          setBrandName(item.name);
                          setIsBrandOpen(false);
                        }}>
                        <div> {item.name}</div>
                        <div>
                          <FaChevronRight />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {isDiscountOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white w-3/4 md:w-1/3 rounded-lg py-2 z-50 mx-4">
                  <div className="px-8 font-bold">Diskon Grup (%)</div>
                  <div className="flex flex-row justify-end px-8">
                    <button
                      className="-mt-6 mb-4 -mr-6 p-0 text-xl"
                      onClick={() => {
                        setIsDiscountOpen(false);
                      }}>
                      <FaWindowClose className="text-red-500 bg-white" />
                    </button>
                  </div>

                  <div className="max-h-96 overflow-y-auto">
                    {priceCustomerGroup.map((item) => (
                      <div
                        className="w-full px-8 py-2 text-sm px-4 cursor-pointer border-b border-gray-200 flex flex-row justify-between"
                        key={item.customer_group_id}>
                        <div className="w-1/2">
                          {customerGroups.map((item2) => (
                            <div>
                              {item.customer_group_id == item2.id && (
                                <div>{item2.name}</div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="w-1/2">
                          <input
                            type="number"
                            className="border-2 w-full border-gray-200 w-16 rounded-lg px-3 py-2 mt-1 font-normal"
                            value={item.discount}
                            onChange={(e) => {
                              const tmp = priceCustomerGroup.map((item3) => {
                                if (
                                  item3.customer_group_id ==
                                  item.customer_group_id
                                ) {
                                  item3.discount = parseInt(e.target.value);
                                }
                                return item3;
                              });

                              console.log("tmp", tmp);

                              // setPriceCustomerGroup([]);
                              setPriceCustomerGroup(tmp);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-row justify-end px-8">
                    <button
                      onClick={() => {
                        setIsDiscountOpen(false);
                      }}
                      className="mt-4 text-white bg-teal-500 hover:bg-gray-200 text-gray-800 font-bold py-2 px-3 text-sm rounded">
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            )}

            {isVariantOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="fixed inset-0 bg-black opacity-50"></div>
                <div className="bg-white w-5/6 md:w-1/3 rounded-lg py-2 z-50 mx-4 pb-8">
                  <div className="px-8 pt-2 font-bold">Variasi</div>
                  <div className="flex flex-row justify-end px-8">
                    <button
                      className="-mt-6 mb-4 -mr-6 p-0 text-xl"
                      onClick={() => {
                        setIsVariantOpen(false);
                      }}>
                      <FaWindowClose className="text-red-500 bg-white" />
                    </button>
                  </div>

                  <div className="max-h-96 overflow-y-auto p-2">
                    {/* <div className="w-full px-8 py-2 text-sm px-4 cursor-pointer border-b border-gray-200 flex flex-row justify-between">
                      <div className="w-3/4">
                        <span className="text-sm">Nama</span>
                      </div>
                      <div className="w-1/4">
                        <span className="text-sm">Stock</span>
                      </div>
                    </div> */}
                    {variations.map((item, index) => (
                      <>
                        <div className="w-full p-2 bg-gray-100 mb-4 rounded">
                          <div
                            className="w-full px-2 py-2 text-sm px-4 cursor-pointer  flex flex-row gap-1 justify-between items-center"
                            key={index}>
                            <div className="w-full">
                              <input
                                type="text"
                                placeholder="Nama"
                                className="border-2 w-full border-gray-200 w-16 rounded-lg px-3 py-2 mt-1 font-normal"
                                value={item.name}
                                onChange={(e) => {
                                  // edit name with current index
                                  const tmp = variations.map(
                                    (item3, index2) => {
                                      if (index2 == index) {
                                        item3.name = e.target.value;
                                      }
                                      return item3;
                                    }
                                  );

                                  // setPriceCustomerGroup([]);
                                  setVariations(tmp);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="w-full px-2 pb-2 text-sm px-4 cursor-pointer flex flex-row gap-1 justify-between items-center"
                            key={index}>
                            <div className="w-1/4 flex justify-center items-center text-xs">
                              Jumlah stok
                            </div>
                            <div className="w-1/4">
                              <input
                                type="number"
                                placeholder="Stock"
                                className="border-2 w-full border-gray-200 w-16 rounded-lg px-3 py-2 mt-1 font-normal"
                                value={item.stock}
                                onChange={(e) => {
                                  // edit price with current index
                                  const tmp = variations.map(
                                    (item3, index2) => {
                                      if (index2 == index) {
                                        item3.stock = parseInt(e.target.value);
                                      }
                                      return item3;
                                    }
                                  );

                                  // setPriceCustomerGroup([]);
                                  setVariations(tmp);
                                }}
                              />
                            </div>
                            <div className="w-1/2">
                              <input
                                type="number"
                                placeholder="Harga modal"
                                className="border-2 w-full border-gray-200 w-16 rounded-lg px-3 py-2 mt-1 font-normal"
                                value={item.cost}
                                onChange={(e) => {
                                  // edit name with current index
                                  const tmp = variations.map(
                                    (item3, index2) => {
                                      if (index2 == index) {
                                        item3.cost = e.target.value;
                                      }
                                      return item3;
                                    }
                                  );

                                  // setPriceCustomerGroup([]);
                                  setVariations(tmp);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="w-full px-2 pb-2 text-sm px-4 cursor-pointer flex flex-row gap-1 justify-between items-center"
                            key={index}>
                            <div className="w-1/2">
                              <input
                                type="number"
                                placeholder="Harga jual"
                                className="border-2 w-full border-gray-200 w-16 rounded-lg px-3 py-2 mt-1 font-normal"
                                value={item.price}
                                onChange={(e) => {
                                  // edit name with current index
                                  const tmp = variations.map(
                                    (item3, index2) => {
                                      if (index2 == index) {
                                        item3.price = e.target.value;
                                      }
                                      return item3;
                                    }
                                  );

                                  // setPriceCustomerGroup([]);
                                  setVariations(tmp);
                                }}
                              />
                            </div>
                            <div className="w-1/2">
                              <input
                                type="number"
                                placeholder="Harga diskon"
                                className="border-2 w-full border-gray-200 w-16 rounded-lg px-3 py-2 mt-1 font-normal"
                                value={item.priceDiscount}
                                onChange={(e) => {
                                  // edit price with current index
                                  const tmp = variations.map(
                                    (item3, index2) => {
                                      if (index2 == index) {
                                        item3.priceDiscount = parseInt(
                                          e.target.value
                                        );
                                      }
                                      return item3;
                                    }
                                  );

                                  // setPriceCustomerGroup([]);
                                  setVariations(tmp);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="text-center items-center justify-center flex flex-row  cursor-pointer"
                            onClick={() => {
                              const tmp = variations;
                              tmp.splice(index, 1);
                              console.log("tmp", tmp);

                              setVariations([]);
                              setTimeout(() => {
                                setVariations(tmp);
                              }, 50);
                            }}>
                            <FaTrash className="text-red-500 bg-white" />
                            <div>Hapus</div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  {variations.length == 0 ? (
                    <div className="flex flex-col justify-center px-8">
                      <div>Anda belum menambahkan variasi produk</div>
                      <button
                        onClick={() => {
                          setVariations([
                            ...variations,
                            {
                              name: "",
                              cost: "",
                              price: "",
                              priceDiscount: "",
                              stock: 1,
                            },
                          ]);
                        }}
                        className="mt-4 text-white bg-gray-500 hover:bg-gray-500 text-gray-800 font-bold py-2 px-3 text-sm rounded">
                        Tambah variasi baru +
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-row justify-between px-8">
                      <button
                        onClick={() => {
                          setVariations([
                            ...variations,
                            {
                              name: "",
                              price: "",
                              cost: "",
                              priceDiscount: "",
                              stock: 1,
                            },
                          ]);
                        }}
                        className="mt-4 text-white bg-gray-500 hover:bg-gray-500 text-gray-800 font-bold py-2 px-3 text-sm rounded">
                        Tambah +
                      </button>
                      <button
                        onClick={() => {
                          setIsVariantOpen(false);
                        }}
                        className="mt-4 text-white bg-teal-500 hover:bg-teal-600 text-gray-800 font-bold py-2 px-3 text-sm rounded">
                        Simpan
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}

            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminquCatalogAdd;
