const Config = {
  api_url:
    process.env.NODE_ENV === "development"
      ? "https://secret-cp.prod.kataloqu.com"
      : "https://secret-cp.prod.kataloqu.com",
  fcmKey:
    "AAAAwIlP4Uo:APA91bG_X_iVT9i_daDhv-NhD6_EuJ8PhVPeHciOcgTue7FTw_VU5h4x8V0slHWXrWfba2z4VrN8wBPLldtc5qxK_O3No_CC92vqI-X0iKouCHDAQx4U5Rus9_La5AKs5etjCAueizBU",

  allowedExtensions: [
    { extension: "jpg", mime: "image/jpeg", type: "image" },
    { extension: "jpeg", mime: "image/jpeg", type: "image" },
    { extension: "png", mime: "image/png", type: "image" },
    { extension: "gif", mime: "image/gif", type: "image" },
    { extension: "pdf", mime: "application/pdf", type: "file" },
    { extension: "doc", mime: "application/msword", type: "file" },
    {
      extension: "docx",
      mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      type: "file",
    },
    { extension: "xls", mime: "application/vnd.ms-excel", type: "file" },
    {
      extension: "xlsx",
      mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      type: "file",
    },
    { extension: "ppt", mime: "application/vnd.ms-powerpoint", type: "file" },
    {
      extension: "pptx",
      mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      type: "file",
    },
    { extension: "zip", mime: "application/zip", type: "file" },
    { extension: "rar", mime: "application/x-rar-compressed", type: "file" },
    // video
    { extension: "mp4", mime: "video/mp4", type: "video" },
    { extension: "avi", mime: "video/avi", type: "video" },
    { extension: "mov", mime: "video/quicktime", type: "video" },
    { extension: "wmv", mime: "video/x-ms-wmv", type: "video" },
    { extension: "flv", mime: "video/x-flv", type: "video" },
    { extension: "m3u8", mime: "application/x-mpegURL", type: "video" },
    { extension: "mp3", mime: "audio/mpeg", type: "audio" },
    { extension: "wav", mime: "audio/x-wav", type: "audio" },
    { extension: "ogg", mime: "audio/ogg", type: "audio" },
    { extension: "aac", mime: "audio/aac", type: "audio" },
    { extension: "m4a", mime: "audio/mp4", type: "audio" },
    { extension: "m4v", mime: "video/mp4", type: "video" },
    { extension: "3gp", mime: "video/3gpp", type: "video" },
    { extension: "3g2", mime: "video/3gpp2", type: "video" },
    { extension: "webm", mime: "video/webm", type: "video" },
    { extension: "mkv", mime: "video/x-matroska", type: "video" },
    { extension: "wmv", mime: "video/x-ms-wmv", type: "video" },
  ],
};

export default Config;
