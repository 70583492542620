import React, { useState, useEffect } from "react";

import { FaChevronDown } from "react-icons/fa";

import { Link } from "react-router-dom";

function DropdownBrand(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [brands, setBrands] = useState(props.brands);

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md  px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 "
          id="options-menu"
          aria-haspopup="listbox"
          // onPointerOver={() => {
          //   setIsOpen(true);
          // }}
          // onPointerOut={() => {
          //   setIsOpen(false);
          // }}
          onClick={toggleDropdown}>
          {props.name}
          <FaChevronDown className="-mr-1 ml-2 mt-1" aria-hidden="true" />
        </button>
      </div>

      {isOpen && (
        <div
          // onPointerOver={() => {
          //   setIsOpen(true);
          // }}
          // onPointerOut={() => {
          //   setIsOpen(false);
          // }}

          onClick={toggleDropdown}
          className="origin-top-right absolute left-0 mt-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu">
          <div className=" max-h-96 overflow-scroll py-1" role="none">
            {props.brands.map((item) => (
              <Link
                to={`/${props.resellerSlug}/catalog/brand/${item.name}/${item.id}`}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem">
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownBrand;
