import React, { useState, useEffect } from "react";
import Header from "../../components/Header";

import Api from "../../services/Api";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import formatCurrency from "../../helpers/formatNumber";

function Orders() {
  const [orders, setOrders] = useState([]);

  const defineStatus = {
    pending: "Menunggu Konfirmasi",
    process: "Dalam Proses",
    delivery: "Dalam Pengiriman",
    completed: "Selesai",
    cancel: "Dibatalkan",
  };

  useEffect(() => {
    document.title = "Pesanan - by Kataloqu";

    const token = localStorage.getItem("customer_token");
    if (token == null) {
      window.location.href = "/login";
    }

    const subdomain = window.location.hostname.split(".")[0];

    const getOrders = async () => {
      Api.get("/items/Orders?fields=*.*&sort=-date_created", token)
        .then((response) => {
          console.log(response);
          if (response.data.length == 0) {
            // 404 page
            window.location.href = "/expired";
          }

          if (response) {
            setOrders(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // Fetch orders from API
    };

    getOrders();
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleString("id-ID", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="flex-1 flex flex-col">
      <Header />

      <div className="px-4 sm:pt-24 pt-52 pb-4">
        <div className="container mx-auto md:w-2/3 xl:w-1/2 px-4 pt-4">
          <div className="font-bold text-lg text-gray-700 mb-4">Pesanan</div>

          {orders.length == 0 ? (
            <div className="flex justify-center items-center h-64">
              <ReactLoading type={"spin"} color={"#000"} />
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              {orders.map((order, index) => (
                <Link to={"/orders/" + order.id} key={index}>
                  <div key={index} className="bg-white p-4 shadow rounded-lg">
                    <div className="flex flex-row justify-between items-center">
                      <div className="text-sm md:text-md font-bold">
                        {order.order_number}
                      </div>
                      <div className="text-xs md:text-sm text-gray-500 text-right">
                        {formatDate(order.date_created)}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mt-2">
                      <div className="text-sm text-gray-700 ">
                        {defineStatus[order.status]}
                      </div>
                      <div className="text-sm font-medium text-gray-800">
                        {formatCurrency(order.total)}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Orders;
