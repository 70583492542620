import React, { useState, useEffect } from "react";
import Header from "../../components/Header";

import Api from "../../services/Api";

import { Link } from "react-router-dom";
import formatCurrency from "../../helpers/formatNumber";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

var httpHost = window.location.hostname;
var domain = "https://kataloqu.com";

if (httpHost == "localhost" || httpHost == "192.168.1.7") {
  httpHost = "mycollection.kataloqu.com";
}

function OrderDetail() {
  const subdomain = httpHost.split(".")[0];
  const [order, setOrder] = useState({
    order_number: "",
    date_created: "",
    status: "",
    total: 0,
    shipper_name: "",
    shipping_address: "",
    shipping_name: "",
    shipping_phone: "",
    note: "",
    items: [],
  });

  const defineStatus = {
    pending: "Menunggu Konfirmasi",
    process: "Dalam Proses",
    delivery: "Dalam Pengiriman",
    completed: "Selesai",
    canceled: "Dibatalkan",
  };

  const [store, setStore] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("customer_token");
    if (token == null) {
      window.location.href = "/login";
    }

    const orderId = window.location.pathname.split("/")[2];
    console.log(orderId);

    const getOrder = async () => {
      Api.get("/items/Orders/" + orderId + "?fields=*.*.*", token)
        .then((response) => {
          console.log(response);

          if (response) {
            setOrder(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getStore = async () => {
      Api.get(
        "/items/Store?filter[subdomain][_eq]=" +
          subdomain +
          "&filter[is_activated][_eq]=1&fields=*"
      )
        .then((response) => {
          console.log(response);
          if (response.data.length == 0) {
            // 404 page
            window.location.href = "/expired";
          }

          if (response) {
            setStore(response.data[0]);
            document.title = response.data[0].name + " - by Kataloqu";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getStore();
    getOrder();
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleString("id-ID", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div>
      <Header />
      <div className="px-4 sm:pt-24 pt-52 pb-4">
        <div className="container mx-auto md:w-2/3 xl:w-1/2 px-4 pt-4">
          <div className="flex justify-between mb-4">
            <div className="text-center flex-1">
              <p className="text-lg font-bold">Pesanan</p>
              <p className="text-sm md:text-lg">{order.order_number}</p>
            </div>
          </div>

          <div className="container mx-auto px-4 border text-xs md:text-sm pt-4 rounded shadow pb-10 ">
            <div className="mt-2">
              <h2 className={"font-bold text-" + store.color + "-700"}>
                Info Pesanan
              </h2>
              <div className="mt-2 md:flex md:flex-row md:justify-between">
                <p className="font-bold">Tanggal Pesanan</p>
                <p>{formatDate(order.date_created)}</p>
              </div>
              <div className="mt-2 md:flex md:flex-row md:justify-between">
                <p className="font-bold">Status Pesanan</p>
                <p
                  className={
                    order.status == "pending"
                      ? "text-yellow-500"
                      : order.status == "process"
                      ? "text-blue-500"
                      : order.status == "shipping"
                      ? "text-green-500"
                      : order.status == "cancel"
                      ? "text-red-500"
                      : ""
                  }>
                  {defineStatus[order.status]}
                </p>
              </div>
              <div className="mt-2 md:flex md:flex-row md:justify-between">
                <p className="font-bold">Total Pesanan</p>
                <p>{formatCurrency(order.total)}</p>
              </div>
              <div className="mt-2 md:flex md:flex-row md:justify-between">
                <p className="font-bold">Alamat Pengiriman</p>
                <p>{order.shipping_address}</p>
              </div>
              <div className="mt-2 md:flex md:flex-row md:justify-between">
                <p className="font-bold">Penerima</p>
                <p>{order.shipping_name}</p>
              </div>
              <div className="mt-2 md:flex md:flex-row md:justify-between">
                <p className="font-bold">Nomor Telepon</p>
                <p>{order.shipping_phone}</p>
              </div>
              <div className="mt-2  md:flex md:flex-row md:justify-between">
                <p className="font-bold">Pengirim</p>
                <p>{order.shipper_name}</p>
              </div>
              <div className="mt-2  md:flex md:flex-row md:justify-between">
                <p className="font-bold">Catatan</p>
                <p>{order.note}</p>
              </div>
              <div className="mt-10">
                <h2 className={"font-bold text-" + store.color + "-700"}>
                  Detail Pesanan
                </h2>
                <div className="mt-2">
                  {order.items.length > 0 &&
                    order.items.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-end border-b-2 py-2">
                        <div>
                          <p className="font-bold">{item.catalog_id.name}</p>
                          {item?.catalog_variant_id && (
                            <p className="text-xs">
                              {item.catalog_variant_id.name}
                            </p>
                          )}
                          <p>
                            {item.qty} x {formatCurrency(item.final_price)}
                          </p>
                        </div>
                        <div>
                          <p>{formatCurrency(item.subtotal)}</p>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="mt-2 md:flex md:flex-row md:justify-between">
                  <p className="font-medium">Subtotal</p>
                  <p className="">{formatCurrency(order.subtotal)}</p>
                </div>
                <div className="mt-2 md:flex md:flex-row md:justify-between">
                  <p className="font-medium">Tambahan diskon</p>
                  <p className="">
                    -{formatCurrency(order.additional_discount)}
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <h2 className={"font-bold  text-pink-500"}>
                  Detail Pengiriman
                </h2>
                <div className="mt-2 md:flex md:flex-row md:justify-between">
                  <p className="font-medium">Layanan pengiriman</p>
                  <p>
                    {order.shipping_provider} -{" "}
                    {order.shipping_provider_service}
                  </p>
                </div>
                <div className=" md:flex md:flex-row md:justify-between">
                  <p className="font-medium">Biaya pengiriman</p>
                  <p>{formatCurrency(order.shipping_cost)}</p>
                </div>
              </div>
              <div className="mt-5">
                <h2 className={"font-bold  text-pink-500"}>Total Pembayaran</h2>

                <div className="mt-2 md:flex md:flex-row md:justify-between">
                  <p className="font-medium">Tambahan biaya</p>
                  <p>{formatCurrency(order.additional_cost)}</p>
                </div>
                <div className="md:flex md:flex-row md:justify-between">
                  <p className="font-bold">Total</p>
                  <p className="font-bold">{formatCurrency(order.total)}</p>
                </div>
              </div>

              <div className="mt-5 p-4 border rounded">
                <h2 className={"font-bold  text-pink-500"}>Informasi</h2>

                <div>{ReactHtmlParser(store.order_note)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetail;
