import React, { useState, useEffect } from "react";

import Api from "../../../services/Api";

import Topmenu from "../../../components/adminqu/Topmenu";
import { ToastContainer, toast } from "react-toastify";
import Config from "../../../config/Config";

import ReactLoading from "react-loading";

import SideNav from "../../../components/adminqu/Sidenav";

import { Link } from "react-router-dom";

import { FaChevronCircleRight, FaTimes } from "react-icons/fa";

function AdminquSettingShipping() {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [authUser, setAuthUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    store_id: {
      name: "",
      subdomain: "",
      package: "",
    },
  });

  const [selectedProvinceName, setSelectedProvinceName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedSubdistrictName, setSelectedSubdistrictName] = useState("");
  const [isModalOrigin, setIsModalOrigin] = useState(false);
  const [isModalOriginOpen, setIsModalOriginOpen] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState("Provinsi");
  const [originLabel, setOriginLabel] = useState("");
  const [selectedOriginList, setSelectedOriginList] = useState([]);
  const [selectedOriginListOriginal, setSelectedOriginListOriginal] = useState(
    []
  );

  const [searchOrigin, setSearchOrigin] = useState("");

  const [province, setProvince] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [subdistrict, setSubdistrict] = useState([]);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState("");

  const getProvince = async () => {
    setSelectedOriginListOriginal([]);
    setSelectedOriginList([]);
    Api.get("/flows/trigger/9dbccb98-1e98-4ec0-89d8-85c630da3711")
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setProvince(response.data);
          setSelectedOriginListOriginal(response.data);
          setSelectedOriginList(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getCity = async (id) => {
    setSelectedOriginListOriginal([]);
    setSelectedOriginList([]);

    Api.get(
      "/flows/trigger/5ed594b6-0cb0-43a1-9297-665119821253?province=" + id
    )
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setCity(response.data);
          setSelectedOriginListOriginal(response.data);
          setSelectedOriginList(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getSubdistrict = async (id) => {
    setSelectedOriginListOriginal([]);
    setSelectedOriginList([]);

    Api.get("/flows/trigger/472d762c-d5dd-4083-a110-814fd6e5a6c5?city=" + id)

      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setSubdistrict(response.data);
          setSelectedOriginListOriginal(response.data);
          setSelectedOriginList(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const [shippingProviderList, setShippingProviderList] = useState({
    ongkir_jne: {
      name: "ongkir_jne",
      label: "JNE",
      active: false,
    },
    ongkir_pos: {
      name: "ongkir_pos",
      label: "POS Indonesia",
      active: false,
    },
    ongkir_tiki: {
      name: "ongkir_tiki",
      label: "TIKI",
      active: false,
    },
    ongkir_pos: {
      name: "ongkir_pos",
      label: "POS Indonesia",
      active: false,
    },
    ongkir_jnt: {
      name: "ongkir_jnt",
      label: "J&T",
      active: false,
    },
    ongkir_sicepat: {
      name: "ongkir_sicepat",
      label: "SiCepat",
      active: false,
    },
    ongkir_wahana: {
      name: "ongkir_wahana",
      label: "Wahana",
      active: false,
    },
    ongkir_lion: {
      name: "ongkir_lion",
      label: "Lion Parcel",
      active: false,
    },
    ongkir_anteraja: {
      name: "ongkir_anteraja",
      label: "AnterAja",
      active: false,
    },
    ongkir_sentral: {
      name: "ongkir_sentral",
      label: "Sentral Cargo",
      active: false,
    },
  });

  const [store, setStore] = useState({
    color: "pink",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    const getUser = async (token) => {
      setIsLoading(true);
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);
          setStore(response.data.store_id);

          // update shipping provider
          Object.keys(shippingProviderList).map((key) => {
            setShippingProviderList((list) => ({
              ...list,
              [key]: {
                ...list[key],
                active: response.data.store_id[key],
              },
            }));
          });

          // set origin
          setSelectedProvince(response.data.store_id.origin_province);
          setSelectedCity(response.data.store_id.origin_city);
          setSelectedSubdistrict(response.data.store_id.origin_subdistrict);

          // set origin label
          if (response.data.store_id.origin_subdistrict) {
            setOriginLabel(
              response.data.store_id.origin_subdistrict.split("|")[1] +
                ", " +
                response.data.store_id.origin_city.split("|")[1] +
                ", " +
                response.data.store_id.origin_province.split("|")[1]
            );
          }

          // if (response.data.store_id.color == "") {
          //   setStore((store) => ({ ...store, color: "pink" }));
          // }

          setIsLoading(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    getUser(token);
  }, []);

  useEffect(() => {
    getProvince();
  }, []);

  useEffect(() => {
    // search destination
    if (searchOrigin.length > 0) {
      var filtered = [];

      if (selectedOrigin === "Provinsi") {
        filtered = selectedOriginListOriginal.filter((item) =>
          item.province.toLowerCase().includes(searchOrigin.toLowerCase())
        );
      } else if (selectedOrigin === "Kota") {
        filtered = selectedOriginListOriginal.filter((item) =>
          item.city_name.toLowerCase().includes(searchOrigin.toLowerCase())
        );
      } else if (selectedOrigin === "Kecamatan") {
        filtered = selectedOriginListOriginal.filter((item) =>
          item.subdistrict_name
            .toLowerCase()
            .includes(searchOrigin.toLowerCase())
        );
      }

      setSelectedOriginList(filtered);
    } else {
      setSelectedOriginList(selectedOriginListOriginal);
    }
  }, [searchOrigin]);

  const submitSave = async () => {
    setIsLoading(true);

    Api.patch(
      "/items/Store/" + store.id,
      {
        ongkir_jne: shippingProviderList.ongkir_jne.active,
        ongkir_pos: shippingProviderList.ongkir_pos.active,
        ongkir_tiki: shippingProviderList.ongkir_tiki.active,
        ongkir_jnt: shippingProviderList.ongkir_jnt.active,
        ongkir_sicepat: shippingProviderList.ongkir_sicepat.active,
        ongkir_wahana: shippingProviderList.ongkir_wahana.active,
        ongkir_lion: shippingProviderList.ongkir_lion.active,
        ongkir_anteraja: shippingProviderList.ongkir_anteraja.active,
        ongkir_sentral: shippingProviderList.ongkir_sentral.active,
        origin_province: selectedProvince + "|" + selectedProvinceName,
        origin_city: selectedCity + "|" + selectedCityName,
        origin_subdistrict: selectedSubdistrict + "|" + selectedSubdistrictName,
      },
      token
    ).then((response) => {
      if (response.errors) {
        toast.error(
          "Gagal menyimpan pengaturan  (" + response.errors[0].message + " )"
        );
        setIsLoading(false);
        return;
      }

      if (response) {
        toast.success("Pengaturan berhasil disimpan");
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="lg:w-56 w-[3.35rem] ">
          <SideNav page="setting" />
        </div>
        <div className="flex-1">
          <div className="flex-1 w-full  p-4 py-5">
            <div className="px-4 pl-16 sm:pl-4 font-bold">Pengaturan</div>
            <div className="pt-4 lg:pt-4">
              <div className="my-8 mb-4 md:mb-12 flex flex-row justify-center gap-2">
                <Link
                  to={"/adminqu/setting"}
                  className="bg-gray-200 p-2 px-4 rounded ">
                  <span className="text-gray-900 text-sm">Informasi</span>
                </Link>
                <Link
                  to="/adminqu/setting/order"
                  className="bg-gray-200 p-2 px-4 rounded">
                  <span className="text-gray-900 text-sm">Order</span>
                </Link>
                <Link
                  to="/adminqu/setting/shipping"
                  className="bg-pink-500 p-2 px-4 rounded">
                  <span className="text-white text-sm">Ekspedisi</span>
                </Link>
              </div>
              <div className="w-full flex flex-row justify-center px-4">
                <div className="w-full lg:w-1/2 bg-gray-50 rounded p-8 mt-8 md:mt-0">
                  <div className="my-1 flex flex-col md:flex-row gap-2"></div>
                  <div className="my-1 flex flex-col md:flex-row gap-2">
                    <div className="my-1 w-full md:w-full font-semibold">
                      <div className="flex flex-col gap-2 mb-4">
                        <div className="text-sm ">Atur asal pengiriman</div>
                        <div className="flex flex-row items-center cursor-pointer">
                          <input
                            className=" cursor-pointer border w-full border-gray-300 rounded-lg px-4 py-2"
                            readOnly
                            value={originLabel}
                            onClick={() => {
                              setSelectedOrigin("Provinsi");
                              getProvince();
                              setIsModalOriginOpen(true);
                            }}
                          />
                          <FaChevronCircleRight className="text-gray-400 -ml-8" />
                        </div>
                      </div>

                      <div className="text-sm mb-4">
                        Atur Provider Ekspedisi
                      </div>

                      <div className="flex flex-col gap-2">
                        {Object.keys(shippingProviderList).map((key) => (
                          <div className="flex flex-row items-center gap-2">
                            <label>
                              <input
                                type="checkbox"
                                className="mr-2"
                                checked={shippingProviderList[key].active}
                                onChange={(e) => {
                                  setShippingProviderList((list) => ({
                                    ...list,
                                    [key]: {
                                      ...list[key],
                                      active: e.target.checked,
                                    },
                                  }));
                                }}
                              />
                              <span className="text-sm font-semibold text-gray-700">
                                {shippingProviderList[key].label}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="text-right">
                    {isLoading ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        className="mt-4 w-full md:w-auto bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded-lg"
                        onClick={() => {
                          submitSave();
                        }}>
                        Simpan pengaturan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                (isModalOriginOpen ? "fixed" : "hidden") +
                " inset-0 w-full h-full z-50 overflow-hidden flex justify-center items-center bg-black bg-opacity-50"
              }>
              <div className="bg-white rounded-lg w-11/12 md:w-1/2 p-4">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row justify-between items-center">
                    <h1 className="text-lg font-semibold">
                      Pilih tujuan {selectedOrigin}
                    </h1>
                    <button
                      onClick={() => setIsModalOriginOpen(false)}
                      className="text-gray-400 hover:text-gray-700">
                      <FaTimes />
                    </button>
                  </div>

                  <div className="w-full">
                    <input
                      type="text"
                      className="border w-full border-gray-300 rounded-lg px-4 py-2"
                      placeholder="Cari asal"
                      value={searchOrigin}
                      onChange={(e) => setSearchOrigin(e.target.value)}
                    />
                  </div>

                  <div className="flex flex-col max-h-96 overflow-scroll">
                    {selectedOriginList.length > 0 ? (
                      selectedOriginList.map((item) => (
                        <div
                          className="cursor-pointer hover:bg-gray-100 rounded p-1"
                          onClick={() => {
                            if (selectedOrigin === "Provinsi") {
                              setSelectedProvince(item.province_id);
                              setSelectedProvinceName(item.province);
                              setSelectedOrigin("Kota");
                              getCity(item.province_id);
                            } else if (selectedOrigin === "Kota") {
                              setSelectedCity(item.city_id);
                              setSelectedCityName(item.city_name);
                              setSelectedOrigin("Kecamatan");
                              getSubdistrict(item.city_id);
                            } else if (selectedOrigin === "Kecamatan") {
                              setSelectedSubdistrict(item.subdistrict_id);
                              setSelectedSubdistrictName(item.subdistrict_name);
                              setIsModalOriginOpen(false);

                              setOriginLabel(
                                item.subdistrict_name +
                                  ", " +
                                  selectedCityName +
                                  ", " +
                                  selectedProvinceName
                              );
                            }
                          }}>
                          {selectedOrigin === "Provinsi" && (
                            <>
                              <div>{item.province}</div>
                            </>
                          )}
                          {selectedOrigin === "Kota" && (
                            <>
                              <div>
                                {item.type} {item.city_name}
                              </div>
                            </>
                          )}
                          {selectedOrigin === "Kecamatan" && (
                            <>
                              <div>{item.subdistrict_name}</div>
                            </>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="flex flex-row text-center justify-center min-h-32">
                        <ReactLoading
                          type="spin"
                          color={"#333"}
                          height={30}
                          width={30}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminquSettingShipping;
