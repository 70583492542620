import React, { useState, useEffect } from "react";

import Api from "../../../services/Api";

import { FaSearch, FaTrash, FaPlus, FaMinus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Config from "../../../config/Config";

import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import Topmenu from "../../../components/adminqu/Topmenu";
import InfiniteScroll from "react-infinite-scroll-component";
import SideNav from "../../../components/adminqu/Sidenav";
import {
  FaChevronLeft,
  FaWindowClose,
  FaChevronRight,
  FaChevronCircleRight,
  FaTimes,
  FaPencilAlt,
} from "react-icons/fa";

import formatCurrency from "../../../helpers/formatNumber";

import { v4 as uuid } from "uuid";

function AdminquOrderAdd() {
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [store, setStore] = useState({});

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [group, setGroup] = useState("");

  const [groups, setGroups] = useState([]);

  const [isCustomerOpen, setIsCustomerOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customersOriginal, setCustomersOriginal] = useState([]);
  const [customer, setCustomer] = useState({});
  const [searchCustomer, setSearchCustomer] = useState("");

  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  const [searchCatalog, setSearchCatalog] = useState("");
  const [catalogs, setCatalogs] = useState([]);

  const [selectedAddCatalog, setSelectedAddCatalog] = useState({});
  const [selectedAddCatalogVariant, setSelectedAddCatalogVariant] =
    useState(null);
  const [selectedAddPrice, setSelectedAddPrice] = useState(0);
  const [selectedAddFinalPrice, setSelectedAddFinalPrice] = useState(0);
  const [selectedAddQty, setSelectedAddQty] = useState(1);
  const [isQtyOpen, setIsQtyOpen] = useState(false);

  const [items, setItems] = useState([]);

  const [shipperName, setShipperName] = useState("");

  const [shippingName, setShippingName] = useState("");
  const [shippingPhone, setShippingPhone] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingProvince, setShippingProvince] = useState("");
  const [shippingSubdistrict, setShippingSubdistrict] = useState("");
  const [shippingPostalCode, setShippingPostalCode] = useState("");
  const [shippingProvider, setShippingProvider] = useState("");
  const [shippingProviderService, setShippingProviderService] = useState("");
  const [isManualShipping, setIsManualShipping] = useState(false);

  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedProvinceName, setSelectedProvinceName] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedSubdistrict, setSelectedSubdistrict] = useState("");
  const [selectedSubdistrictName, setSelectedSubdistrictName] = useState("");

  const [isModalDestinationOpen, setIsModalDestinationOpen] = useState(false);
  const [searchDestination, setSearchDestination] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("Provinsi");
  const [selectedDestinationList, setSelectedDestinationList] = useState([]);
  const [destinationLabel, setDestinationLabel] = useState("");

  const [servicesList, setServicesList] = useState("");
  const [service, setService] = useState([]);

  const [selectedDestinationListOriginal, setSelectedDestinationListOriginal] =
    useState([]);

  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [subdistrict, setSubdistrict] = useState([]);

  const [isModalServiceOpen, setIsModalServiceOpen] = useState(false);
  const [isModalEditPrice, setIsModalEditPrice] = useState(false);
  const [editPriceItem, setEditPriceItem] = useState({});
  const [selectedService, setSelectedService] = useState("");
  const [selectedServiceList, setSelectedServiceList] = useState([]);
  const [selectedServiceListOriginal, setSelectedServiceListOriginal] =
    useState([]);

  const [note, setNote] = useState("");

  const [additionalDiscount, setAdditionalDiscount] = useState(0);
  const [additionalCost, setAdditionalCost] = useState(0);

  const [totalQty, setTotalQty] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [total, setTotal] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [authUser, setAuthUser] = useState({
    store_id: {
      name: "",
    },
  });

  useEffect(() => {
    const getUser = async (token) => {
      Api.get("/users/me?fields=*,store_id.*", token)
        .then((response) => {
          //console.log(response);
          localStorage.setItem("auth_user", JSON.stringify(response.data));
          setAuthUser(response.data);
          getStore(response.data.store_id.id);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const token = localStorage.getItem("token");
    getUser(token);
  }, []);

  useEffect(() => {
    const getGroups = async (token) => {
      Api.get("/items/Store_Customer_Group?limit=-1", token)
        .then((response) => {
          //console.log(response);
          setGroups(response.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const token = localStorage.getItem("token");
    getGroups(token);
  }, []);

  const submitAdd = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    Api.post(
      "/flows/trigger/4dabbf0f-8ce9-4eef-934d-77b1eeaf2185",
      {
        store_id: authUser.store_id.id,
        name: name,
        email: email,
        password: password,
        password_confirmation: password,
        store_customer_group_id: group,
      },
      token
    )
      .then((response) => {
        //console.log(response);

        if (response.errors) {
          toast.error(
            "Gagal menyimpan grup pesanan  (" +
              response.errors[0].message +
              " )"
          );
          setIsLoading(false);
          return;
        }

        if (response) {
          toast.success("Berhasil menambahkan pesanan");
          window.location.href = "/adminqu/orders";
          setIsLoading(false);
        }
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Gagal menambahkan pesanan");

        setIsLoading(false);
      });
  };

  const getCustomers = async () => {
    const token = localStorage.getItem("token");
    Api.get(
      "/users?filter[role][_eq]=41102248-b193-4b2a-ab2e-9182766b3113&fields=*.*&limit=-1",
      token
    )
      .then((response) => {
        //console.log(response);
        if (response) {
          setCustomersOriginal(response.data);
          setCustomers(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getProvince = async () => {
    setSelectedDestinationListOriginal([]);
    setSelectedDestinationList([]);
    Api.get("/flows/trigger/9dbccb98-1e98-4ec0-89d8-85c630da3711")
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setProvince(response.data);
          setSelectedDestinationListOriginal(response.data);
          setSelectedDestinationList(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getCity = async (id) => {
    setSelectedDestinationListOriginal([]);
    setSelectedDestinationList([]);

    Api.get(
      "/flows/trigger/5ed594b6-0cb0-43a1-9297-665119821253?province=" + id
    )
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setCity(response.data);
          setSelectedDestinationListOriginal(response.data);
          setSelectedDestinationList(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getSubdistrict = async (id) => {
    setSelectedDestinationListOriginal([]);
    setSelectedDestinationList([]);

    Api.get("/flows/trigger/472d762c-d5dd-4083-a110-814fd6e5a6c5?city=" + id)

      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setSubdistrict(response.data);
          setSelectedDestinationListOriginal(response.data);
          setSelectedDestinationList(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getRate = async () => {
    Api.post("/flows/trigger/3825f233-d93a-43d6-9a81-fdb06820ab23", {
      origin: store.origin_subdistrict,
      destination: selectedSubdistrict,
      weight: totalWeight,
      courier: servicesList,
    })
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setService(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getStore = async (storeId) => {
    Api.get("/items/Store?filter[id][_eq]=" + storeId + "&fields=*")
      .then((response) => {
        //console.log(response);
        if (response.data.length == 0) {
          // 404 page
          window.location.href = "/expired";
        }

        if (response) {
          setStore(response.data[0]);

          var serviceList = "";
          response.data[0].ongkir_jne && (serviceList += "jne:");
          response.data[0].ongkir_jnt && (serviceList += "jnt:");
          response.data[0].ongkir_tiki && (serviceList += "tiki:");
          response.data[0].ongkir_pos && (serviceList += "pos:");
          response.data[0].ongkir_sicepat && (serviceList += "sicepat:");
          response.data[0].ongkir_wahana && (serviceList += "wahana:");
          response.data[0].ongkir_anteraja && (serviceList += "anteraja:");
          response.data[0].ongkir_sentral && (serviceList += "sentral:");
          response.data[0].ongkir_lion && (serviceList += "lion:");

          // remove last character
          serviceList = serviceList.slice(0, -1);
          setServicesList(serviceList);

          document.title = response.data[0].name + " - by Kataloqu";
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const searchCustomers = async () => {
    const token = localStorage.getItem("token");
    Api.get(
      "/users?filter[role][_eq]=41102248-b193-4b2a-ab2e-9182766b3113&fields=*.*&limit=-1&filter[first_name][_contains]=" +
        searchCustomer,
      token
    ).then((response) => {
      setCustomers(response.data);
    });
  };

  const searchCatalogs = async () => {
    const token = localStorage.getItem("token");
    Api.get(
      "/items/Catalog?filter[store_id][_eq]=" +
        authUser.store_id.id +
        "&fields=*,variations.*,price_customer_group.*&limit=-1&filter[name][_contains]=" +
        searchCatalog,
      token
    ).then((response) => {
      setCatalogs(response?.data);
    });
  };

  useEffect(() => {
    if (searchCustomer == "") {
      getCustomers();
    } else {
      searchCustomers();
    }
  }, [searchCustomer]);

  useEffect(() => {
    if (searchCatalog != "") {
      if (searchCatalog.length > 2) {
        setTimeout(() => {
          searchCatalogs();
        }, 1000);
      }
    }
  }, [searchCatalog]);

  useEffect(() => {
    const totalWeightTemp = items.reduce((total, item) => {
      return total + item.catalog.weight * item.qty;
    }, 0);

    setTotalWeight(totalWeightTemp);

    const totalQtyTemp = items.reduce((total, item) => {
      return total + item.qty;
    }, 0);

    setTotalQty(totalQtyTemp);

    const totalAmountTemp = items.reduce((total, item) => {
      return total + item.final_price * item.qty;
    }, 0);

    setTotalAmount(totalAmountTemp);

    const totalTemp =
      totalAmountTemp -
      additionalDiscount +
      parseInt(shippingFee) +
      parseInt(additionalCost);

    setTotal(totalTemp);
  }, [items, additionalDiscount, shippingFee, additionalCost]);

  const checkout = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    var postitems = [];

    items.map((item) => {
      postitems.push({
        catalog_id: item.catalog.id,
        catalog_variant_id: item?.variant?.id ? item.variant.id : null,
        cost: item?.variant?.id ? item.variant.cost : item.cost,
        qty: item.qty,
        price: item.price,
        final_price: item.final_price,
        subtotal: item.subtotal,
      });
    });

    const token = localStorage.getItem("token");
    const data = {
      store_id: authUser.store_id.id,
      customer_id: customer.id,
      shipper_name: shipperName,
      shipping_name: shippingName,
      shipping_phone: shippingPhone,
      shipping_address: shippingAddress,
      shipping_postal_code: shippingPostalCode,
      shipping_city: selectedCityName,
      shipping_province: selectedProvinceName,
      shipping_subdistrict: selectedSubdistrictName,
      total_weight: totalWeight,
      additional_discount: additionalDiscount,
      additional_cost: additionalCost,
      subtotal: totalAmount,
      shipping_cost: shippingFee,
      total: total,
      note: note,
      subtotal_cost: postitems.reduce((total, item) => {
        return total + parseInt(item.qty) * parseInt(item.cost);
      }, 0),
      items: postitems,
      shipping_provider: isManualShipping ? "Manual" : shippingProvider,
      shipping_provider_service: isManualShipping
        ? ""
        : shippingProviderService,
    };
    //https://secret-cp.kataloqu.com/flows/trigger/db884111-1d37-44cb-958c-5804fc97426b
    Api.post("/flows/trigger/db884111-1d37-44cb-958c-5804fc97426b", data, token)
      .then((response) => {
        console.log(response);
        // if (response) {
        //   window.location.href = "/order/" + response.data.id;
        // }
        if (response?.status == "success") {
          window.location.href = "/adminqu/orders/" + response.id;
        } else {
          if (response?.errors == "Insufficient Quota") {
            toast.error("Gagal checkout, silahkan hubungi admin");
          }
        }

        setIsSubmitting(false);
      })
      .catch((error) => {
        //console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <div className="fixed w-full ml-0 mt-0 px-4 py-3 shadow bg-pink-500 text-white z-50">
        <div className="flex flex-row justify-between items-center">
          <div className="w-1/5">
            <Link to="/adminqu/customer">
              <FaChevronLeft className="text-white" />
            </Link>
          </div>
          <div className="flex-1">
            <div className="text-sm md:text-lg font-bold text-center">
              Tambah pesanan{" "}
            </div>
          </div>
          <div className="w-1/5 text-right"></div>
        </div>
      </div>

      <div className="flex flex-col  pt-16 md:pt-20 px-4 bg-gray-50 min-h-screen">
        <div className="flex flex-col lg:flex-row justify-center gap-8">
          <div className="w-full lg:w-1/3">
            <div className="my-1 flex flex-col gap-2">
              <div className="my-1 w-full md:w-full font-semibold">
                <span className="text-sm">Pelanggan</span>
                <input
                  type="text"
                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                  value={customer.first_name}
                  readOnly
                  onClick={() => setIsCustomerOpen(true)}
                />
              </div>

              <div className="my-1 w-full md:w-full font-semibold">
                <span className="text-sm">Pengirim (opsional)</span>
                <input
                  type="text"
                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                  value={shipperName}
                  placeholder="Nama pengirim / toko / 08123456789"
                  onChange={(e) => setShipperName(e.target.value)}
                />
              </div>

              <div className="my-1 w-full md:w-full font-semibold bg-white border">
                <div className="min-h-[80px]">
                  {items.length > 0 ? (
                    items.map((item, index) => (
                      <div className="border-b-2 border-gray-200  p-4">
                        <div className="flex justify-between">
                          <div className="text-sm">
                            <div>{item.catalog.name}</div>
                            <div className="text-xs font-normal">
                              {item?.variant?.name}
                            </div>
                            <div className="text-xs text-gray-600 font-normal mt-1">
                              {item.final_price < item.price ? (
                                <span className="text-red-600 line-through mr-1">
                                  {formatCurrency(item.price)}
                                </span>
                              ) : (
                                ""
                              )}

                              {formatCurrency(item.final_price)}
                            </div>
                          </div>
                          <div className="text-xs">
                            <div className="flex flex-row gap-4 justify-center items-center">
                              <button
                                className="bg-gray-200 p-2 rounded"
                                onClick={() => {
                                  if (item.qty == 1) {
                                    return;
                                  }

                                  // update qty - 1 and update to items
                                  const newQty = item.qty - 1;
                                  const newItems = items.map((i) => {
                                    if (i.id == item.id) {
                                      i.qty = newQty;
                                    }
                                    return i;
                                  });

                                  setItems(newItems);
                                }}>
                                <FaMinus />
                              </button>
                              <span>{item.qty}</span>
                              <button
                                className="bg-gray-200 p-2 rounded"
                                onClick={() => {
                                  // update qty + 1 and update to items
                                  const newQty = item.qty + 1;

                                  const newItems = items.map((i) => {
                                    if (i.id == item.id) {
                                      i.qty = newQty;
                                    }
                                    return i;
                                  });

                                  setItems(newItems);
                                }}>
                                <FaPlus />
                              </button>
                              <button
                                className="bg-red-500 p-2 rounded"
                                onClick={() => {
                                  const newItems = items.filter(
                                    (i) => i.catalog.id != item.catalog.id
                                  );
                                  setItems(newItems);
                                }}>
                                <FaTrash color="white" />
                              </button>
                            </div>
                            <div className="text-sm mt-1 text-right">
                              {formatCurrency(item.final_price * item.qty)}
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            className="text-pink-500 p-1 text-sm px-4 border rounded flex justify-center items-center gap-2"
                            onClick={() => {
                              setIsModalEditPrice(true);
                              setEditPriceItem(item);
                            }}>
                            <FaPencilAlt size={12} />
                            <span>Edit harga</span>
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col justify-center items-center h-24 text-normal text-gray-400 text-sm">
                      <div>Belum ada item</div>
                      <div className="font-normal">
                        Silahkan tambahkan item catalog ke dalam pesanan
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-row justify-center p-4 bg-gray-100">
                  <button
                    className="text-teal-600 flex gap-2 justify-center items-center"
                    onClick={() => {
                      setIsCatalogOpen(true);
                      setIsQtyOpen(false);
                    }}>
                    Tambahkan item produk <FaPlus />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            <div className="my-1 flex flex-col  gap-2">
              <div className="my-1 w-full md:w-full font-semibold">
                <span className="text-sm">Nama penerima</span>
                <input
                  type="text"
                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                  value={shippingName}
                  onChange={(e) => setShippingName(e.target.value)}
                />
              </div>
              <div className="my-1 w-full md:w-full font-semibold">
                <span className="text-sm">Telepon penerima</span>

                <input
                  type="text"
                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                  value={shippingPhone}
                  onChange={(e) => setShippingPhone(e.target.value)}
                />
              </div>
              <div className="my-1 w-full md:w-full font-semibold">
                <span className="text-sm">Alamat penerima</span>
                <textarea
                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal"
                  value={shippingAddress}
                  onChange={(e) => setShippingAddress(e.target.value)}
                />
              </div>
              <div className="my-1 w-full md:w-full font-semibold">
                <span className="text-sm">Tujuan pengiriman</span>
                <div className="flex flex-row items-center cursor-pointer">
                  <input
                    className=" cursor-pointer border w-full border-gray-300 rounded-lg px-4 py-2"
                    readOnly
                    value={destinationLabel}
                    onClick={() => {
                      setSelectedDestination("Provinsi");
                      getProvince();
                      setIsModalDestinationOpen(true);
                    }}
                  />
                  <FaChevronCircleRight className="text-gray-400 -ml-8" />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center">
                  <label className="text-sm font-semibold">
                    Layanan pengiriman
                  </label>
                  <div className="flex flex-row items-center gap-2 text-xs">
                    Ongkir manual?
                    <label
                      for="toggleStatus"
                      className="flex items-center cursor-pointer">
                      <div className="relative">
                        <input
                          type="checkbox"
                          id="toggleStatus"
                          className="sr-only"
                          onChange={(e) =>
                            setIsManualShipping(e.target.checked)
                          }
                          checked={isManualShipping}
                        />
                        <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="flex flex-row items-center">
                  <input
                    disabled={isManualShipping}
                    className="border w-full border-gray-300 rounded-lg px-4 py-2"
                    readOnly
                    value={selectedService}
                    onClick={() => {
                      getRate();
                      setIsModalServiceOpen(true);
                    }}
                  />
                  <FaChevronCircleRight className="text-gray-400 -ml-8" />
                </div>
              </div>
              {isManualShipping && (
                <div>
                  <div className="my-1 w-full md:w-full font-semibold">
                    <span className="text-sm">Biaya ongkir pengiriman</span>

                    <input
                      type="number"
                      className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal text-right"
                      value={shippingFee}
                      onChange={(e) => setShippingFee(e.target.value)}
                    />
                  </div>
                </div>
              )}

              <div>
                <div className="flex flex-row justify-between items-center">
                  <div>Total berat</div>
                  <div>{totalWeight} gram</div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold">
                  Catatan untuk penjual
                </label>
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="border border-gray-300 rounded-lg px-4 py-2"
                  rows="4"></textarea>
              </div>

              <div className="my-1 w-full md:w-full font-semibold flex flex-row justify-between items-center">
                <div className="text-sm w-1/2 pl-4">Tambahan Diskon</div>

                <div>
                  <input
                    type="number"
                    className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal text-right"
                    value={additionalDiscount}
                    onChange={(e) => setAdditionalDiscount(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 p-4 bg-gray-100 rounded text-sm">
                <div className="flex flex-row justify-between items-center">
                  <div>Jumlah barang</div>
                  <div>{totalQty}</div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div>Total belanja</div>
                  <div>{formatCurrency(totalAmount)}</div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div>Biaya pengiriman</div>
                  <div>{formatCurrency(shippingFee)}</div>
                </div>
              </div>

              <div className="my-1 w-full md:w-full font-semibold flex flex-row justify-between items-center">
                <div className="text-sm w-1/2 pl-4">Tambahan Biaya</div>

                <div>
                  <input
                    type="number"
                    className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal text-right"
                    value={additionalCost}
                    onChange={(e) => setAdditionalCost(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 p-4 bg-gray-100 rounded text-sm">
                <div className="flex flex-row justify-between items-center">
                  <div className="font-semibold text-lg">Total pembayaran</div>
                  <div className="font-semibold text-lg">
                    {formatCurrency(total)}
                  </div>
                </div>
              </div>

              <div className="w-full flex justify-end">
                <div className="my-4 w-full md:w-1/2 font-semibold">
                  <div className="h-24 flex flex-row justify-center">
                    {isLoading ? (
                      <ReactLoading
                        type={"balls"}
                        color={"gray"}
                        height={10}
                        width={48}
                      />
                    ) : (
                      <button
                        type="submit"
                        className="w-full bg-teal-500 text-white py-2 px-4 h-10 rounded-md hover:bg-teal-600 focus:outline-none focus:bg-blue-600"
                        onClick={checkout}>
                        Buat Order
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isCustomerOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-30">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white w-3/4 md:w-1/3 rounded-lg py-8 z-50 mx-4">
            <div className="flex flex-row justify-end px-8">
              <button
                className="-mt-6 mb-4 -mr-6 p-0 text-xl"
                onClick={() => {
                  setIsCustomerOpen(false);
                }}>
                <FaWindowClose className="text-red-500 bg-white" />
              </button>
            </div>
            <div className="px-8 mb-4">
              <div className="my-1 mb-4 w-full md:w-full font-semibold flex flex-row justify-between">
                <div>Cari pelanggan</div>
              </div>
              <div className="flex flex-row justify-between items-center gap-2">
                <input
                  type="text"
                  className="border-2 border-gray-200 w-full rounded-lg px-3 py-1 mt-1 font-normal"
                  value={searchCustomer}
                  onChange={(e) => setSearchCustomer(e.target.value)}
                />
              </div>
            </div>
            <div className="max-h-96 overflow-y-auto">
              {customers.map((item) => (
                <div
                  className={
                    customer.id == item.id
                      ? "w-full px-8 py-2 text-sm px-4 bg-gray-100 cursor-pointer border-b border-gray-200 hover:bg-gray-100 flex flex-row justify-between"
                      : "w-full px-8 py-2 text-sm px-4 cursor-pointer border-b border-gray-200 hover:bg-gray-100 flex flex-row justify-between"
                  }
                  key={item.id}
                  onClick={() => {
                    setCustomer(item);
                    setIsCustomerOpen(false);
                  }}>
                  <div> {item.first_name}</div>
                  <div>
                    <FaChevronRight />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Modal destination */}
      <div
        className={
          (isModalDestinationOpen ? "fixed" : "hidden") +
          " inset-0 w-full h-full z-50 overflow-hidden flex justify-center items-center bg-black bg-opacity-50"
        }>
        <div className="bg-white rounded-lg w-11/12 md:w-1/2 p-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-lg font-semibold">
                Pilih tujuan {selectedDestination}
              </h1>
              <button
                onClick={() => setIsModalDestinationOpen(false)}
                className="text-gray-400 hover:text-gray-700">
                <FaTimes />
              </button>
            </div>

            <div className="w-full">
              <input
                type="text"
                className="border w-full border-gray-300 rounded-lg px-4 py-2"
                placeholder="Cari provinsi"
                value={searchDestination}
                onChange={(e) => setSearchDestination(e.target.value)}
              />
            </div>

            <div className="flex flex-col max-h-96 overflow-scroll">
              {selectedDestinationList.length > 0 ? (
                selectedDestinationList.map((item) => (
                  <div
                    className="cursor-pointer hover:bg-gray-100 rounded p-1"
                    onClick={() => {
                      if (selectedDestination === "Provinsi") {
                        setSelectedProvince(item.province_id);
                        setSelectedProvinceName(item.province);
                        setSelectedDestination("Kota");
                        getCity(item.province_id);
                      } else if (selectedDestination === "Kota") {
                        setSelectedCity(item.city_id);
                        setSelectedCityName(item.city_name);
                        setSelectedDestination("Kecamatan");
                        getSubdistrict(item.city_id);
                      } else if (selectedDestination === "Kecamatan") {
                        setSelectedSubdistrict(item.subdistrict_id);
                        setSelectedSubdistrictName(item.subdistrict_name);
                        setIsModalDestinationOpen(false);

                        setDestinationLabel(
                          item.subdistrict_name +
                            ", " +
                            selectedCityName +
                            ", " +
                            selectedProvinceName
                        );
                      }
                    }}>
                    {selectedDestination === "Provinsi" && (
                      <>
                        <div>{item.province}</div>
                      </>
                    )}
                    {selectedDestination === "Kota" && (
                      <>
                        <div>
                          {item.type} {item.city_name}
                        </div>
                      </>
                    )}
                    {selectedDestination === "Kecamatan" && (
                      <>
                        <div>{item.subdistrict_name}</div>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <div className="flex flex-row text-center justify-center min-h-32">
                  <ReactLoading
                    type="spin"
                    color={"#333"}
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          (isModalServiceOpen ? "fixed" : "hidden") +
          " inset-0 w-full h-full z-50 overflow-hidden flex justify-center items-center bg-black bg-opacity-50"
        }>
        <div className="bg-white rounded-lg w-11/12 md:w-1/2 p-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-lg font-semibold">Pilih layanan</h1>
              <button
                onClick={() => setIsModalServiceOpen(false)}
                className="text-gray-400 hover:text-gray-700">
                <FaTimes />
              </button>
            </div>

            <div className="flex flex-col max-h-96 overflow-scroll">
              {service.length > 0 ? (
                service.map((item) => (
                  <div className="rounded p-1">
                    <div className="font-bold">{item.name}</div>
                    {item.costs.map((cost) => (
                      <div
                        className="flex flex-row justify-between py-2 cursor-pointer"
                        onClick={() => {
                          setSelectedService(item.name + " - " + cost.service);
                          setShippingFee(cost.cost[0].value);
                          setShippingProvider(item.name);
                          setShippingProviderService(cost.service);
                          setTotal(totalAmount + cost.cost[0].value);
                          setIsModalServiceOpen(false);
                        }}>
                        <div className="w-1/2">{cost.service}</div>
                        <div className="w-1/2 flex flex-row items-center justify-between">
                          <div>
                            {cost.cost[0].etd} hari ({cost.cost[0].value})
                          </div>
                          <div>
                            <FaChevronCircleRight className="text-gray-800 -ml-8" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div className="flex flex-row text-center justify-center min-h-32">
                  <ReactLoading
                    type="spin"
                    color={"#333"}
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isCatalogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-30">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white w-3/4 md:w-1/3 rounded-lg py-8 z-50 mx-4">
            <div className="flex flex-row justify-end px-8">
              <button
                className="-mt-6 mb-4 -mr-6 p-0 text-xl"
                onClick={() => {
                  setIsCatalogOpen(false);
                }}>
                <FaWindowClose className="text-red-500 bg-white" />
              </button>
            </div>
            {!isQtyOpen && (
              <>
                <div className="px-8 mb-4">
                  <div className="my-1 mb-4 w-full md:w-full font-semibold flex flex-row justify-between">
                    <div>Cari katalog</div>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-2">
                    <input
                      type="text"
                      className="border-2 border-gray-200 w-full rounded-lg px-3 py-1 mt-1 font-normal"
                      value={searchCatalog}
                      onChange={(e) => setSearchCatalog(e.target.value)}
                    />
                  </div>
                </div>
                <div className="max-h-96 overflow-y-auto">
                  {catalogs.length > 0 &&
                    catalogs.map((item) => (
                      <div
                        className="w-full px-8 py-2 text-sm px-4 cursor-pointer border-b border-gray-200 hover:bg-gray-100 flex flex-row justify-between"
                        key={item.id}
                        onClick={() => {
                          setSelectedAddCatalog(item);
                          setIsQtyOpen(true);

                          if (item.variations?.length > 0) {
                            setSelectedAddCatalogVariant(item.variations[0]);
                            setSelectedAddPrice(item.variations[0].price);
                            if (item.variations[0].price_discount > 0) {
                              setSelectedAddFinalPrice(
                                item.variations[0].price_discount
                              );
                            } else {
                              setSelectedAddFinalPrice(
                                item.variations[0].price
                              );
                            }
                          } else {
                            setSelectedAddPrice(item.price);
                            if (item.discount > 0) {
                              setSelectedAddFinalPrice(item.discount);
                            } else {
                              setSelectedAddFinalPrice(item.price);
                            }
                          }
                        }}>
                        <div> {item.name}</div>
                        <div>
                          <FaChevronRight />
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
            {isQtyOpen && (
              <>
                <div className="px-8 mb-4">
                  <div className="my-1 mb-4 w-full md:w-full font-semibold">
                    {selectedAddCatalog.name}
                  </div>
                  {selectedAddCatalog.variations?.length > 0 && (
                    <div className="flex flex-row flex-wrap my-4 gap-2">
                      {selectedAddCatalog.variations?.length > 0 &&
                        selectedAddCatalog.variations.map((item) => (
                          <button
                            className={
                              selectedAddCatalogVariant.id == item.id
                                ? "bg-gray-200 border-2 border-gray-200 rounded-lg px-3 py-1 mt-1 font-normal"
                                : "border-2 border-gray-200 rounded-lg px-3 py-1 mt-1 font-normal"
                            }
                            key={item.id}
                            onClick={() => {
                              setSelectedAddCatalogVariant(item);
                              if (item.price) {
                                setSelectedAddPrice(item.price);
                              }
                              if (item.price_discount) {
                                if (item.price_discount < item.price) {
                                  setSelectedAddFinalPrice(item.price_discount);
                                }
                              } else {
                                setSelectedAddFinalPrice(item.price);
                              }
                            }}>
                            <div> {item.name}</div>
                          </button>
                        ))}
                    </div>
                  )}
                  <div className="my-1 mb-4 w-full md:w-full flex flex-row justify-between">
                    <div>Jumlah</div>
                    <div className="flex flex-row gap-4 justify-center items-center">
                      <button
                        className="bg-gray-200 p-2 rounded"
                        onClick={() => {
                          if (selectedAddQty > 1) {
                            setSelectedAddQty(selectedAddQty - 1);
                          }
                        }}>
                        <FaMinus />
                      </button>
                      <span>{selectedAddQty}</span>
                      <button
                        className="bg-gray-200 p-2 rounded"
                        onClick={() => {
                          if (selectedAddCatalogVariant != null) {
                            if (
                              selectedAddQty < selectedAddCatalogVariant.stock
                            )
                              setSelectedAddQty(selectedAddQty + 1);
                          } else {
                            if (selectedAddQty < selectedAddCatalog.stock)
                              setSelectedAddQty(selectedAddQty + 1);
                          }
                        }}>
                        <FaPlus />
                      </button>
                    </div>
                  </div>
                  <div className="my-1 mb-4 w-full md:w-full flex flex-row justify-between">
                    <div></div>
                    <div className="flex flex-row gap-4 justify-center items-center text-sm">
                      Stok:{" "}
                      {selectedAddCatalogVariant
                        ? selectedAddCatalogVariant.stock
                        : selectedAddCatalog.stock}
                    </div>
                  </div>
                  <div className="my-1 mb-4 w-full md:w-full flex flex-row justify-between">
                    <div>Harga</div>
                    <div>
                      <div className="flex flex-row gap-2 items-center">
                        {selectedAddFinalPrice < selectedAddPrice ? (
                          <>
                            <span className="text-xs text-red-600 font-normal line-through">
                              {formatCurrency(selectedAddPrice)}
                            </span>
                            <span>{formatCurrency(selectedAddFinalPrice)}</span>
                          </>
                        ) : (
                          <span>{formatCurrency(selectedAddPrice)}</span>
                        )}
                      </div>
                      {selectedAddCatalog?.price_customer_group.map((price) => {
                        if (
                          price.customer_group_id ==
                          customer?.store_customer_group_id?.id
                        ) {
                          return (
                            <div className="text-xs">
                              <span>
                                Diskon {customer?.store_customer_group_id?.name}
                              </span>
                              <span className="text-xs text-red-600 font-normal ml-1">
                                {price.discount}%
                              </span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>

                  {/* {selectedAddCatalogVariant != null
                    ? selectedAddCatalogVariant.stock < 1 && (
                        <div className="my-1 mb-4 w-full md:w-full flex flex-row justify-between">
                          <div></div>
                          <div className="flex flex-row gap-4 justify-center items-center text-sm text-red-600">
                            Stok habis
                          </div>
                        </div>
                      )
                    : selectedAddCatalog.stock < 1 && (
                        <div className="my-1 mb-4 w-full md:w-full flex flex-row justify-between">
                          <div></div>
                          <div className="flex flex-row gap-4 justify-center items-center text-sm text-red-600">
                            Stok habis
                          </div>
                        </div>
                      )} */}

                  {selectedAddCatalogVariant != null ? (
                    selectedAddCatalogVariant.stock < 1 ? (
                      <div className="my-1 mb-4 w-full md:w-full flex flex-row justify-between">
                        <div></div>
                        <div className="flex flex-row gap-4 justify-center items-center text-sm text-red-600">
                          Stok habis
                        </div>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="w-full bg-teal-500 text-white py-2 px-4 h-10 rounded-md hover:bg-teal-600 focus:outline-none focus:bg-blue-600 "
                          onClick={() => {
                            setIsCatalogOpen(false);
                            setIsQtyOpen(false);

                            // validate final price
                            var finalPrice = selectedAddFinalPrice;
                            selectedAddCatalog?.price_customer_group.map(
                              (price) => {
                                if (
                                  price.customer_group_id ==
                                  customer?.store_customer_group_id?.id
                                ) {
                                  finalPrice =
                                    finalPrice -
                                    (parseInt(finalPrice) *
                                      parseInt(price.discount)) /
                                      100;
                                }
                              }
                            );

                            setItems([
                              ...items,
                              {
                                id: uuid(),
                                catalog: selectedAddCatalog,
                                variant: selectedAddCatalogVariant,
                                price: selectedAddPrice,
                                final_price: finalPrice,
                                qty: selectedAddQty,
                                weight: selectedAddCatalog.weight,
                                subtotal:
                                  selectedAddFinalPrice * selectedAddQty,
                              },
                            ]);

                            // reset selected
                            setSelectedAddCatalog({});
                            setSelectedAddCatalogVariant(null);
                            setSelectedAddPrice(0);
                            setSelectedAddFinalPrice(0);
                            setSelectedAddQty(1);
                          }}>
                          Tambahkan
                        </button>
                      </div>
                    )
                  ) : selectedAddCatalog.stock < 1 ? (
                    <div className="my-1 mb-4 w-full md:w-full flex flex-row justify-between">
                      <div></div>
                      <div className="flex flex-row gap-4 justify-center items-center text-sm text-red-600">
                        Stok habis
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="w-full bg-teal-500 text-white py-2 px-4 h-10 rounded-md hover:bg-teal-600 focus:outline-none focus:bg-blue-600 "
                        onClick={() => {
                          setIsCatalogOpen(false);
                          setIsQtyOpen(false);

                          // validate final price
                          var finalPrice = selectedAddFinalPrice;
                          selectedAddCatalog?.price_customer_group.map(
                            (price) => {
                              if (
                                price.customer_group_id ==
                                customer?.store_customer_group_id?.id
                              ) {
                                finalPrice =
                                  finalPrice -
                                  (parseInt(finalPrice) *
                                    parseInt(price.discount)) /
                                    100;
                              }
                            }
                          );

                          setItems([
                            ...items,
                            {
                              catalog: selectedAddCatalog,
                              variant: selectedAddCatalogVariant,
                              price: selectedAddPrice,
                              final_price: finalPrice,
                              qty: selectedAddQty,
                              weight: selectedAddCatalog.weight,
                              subtotal: selectedAddFinalPrice * selectedAddQty,
                            },
                          ]);

                          // reset selected
                          setSelectedAddCatalog({});
                          setSelectedAddCatalogVariant(null);
                          setSelectedAddPrice(0);
                          setSelectedAddFinalPrice(0);
                          setSelectedAddQty(1);
                        }}>
                        Tambahkan
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {/* Modal destination */}
      <div
        className={
          (isModalEditPrice ? "fixed" : "hidden") +
          " inset-0 w-full h-full z-50 overflow-hidden flex justify-center items-center bg-black bg-opacity-50"
        }>
        <div className="bg-white rounded-lg w-11/12 md:w-1/2 p-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-lg font-semibold">
                Edit harga {editPriceItem?.catalog?.name}
              </h1>
              <button
                onClick={() => setIsModalEditPrice(false)}
                className="text-gray-400 hover:text-gray-700">
                <FaTimes />
              </button>
            </div>

            <div className="w-full">
              <label>Harga normal</label>
              <input
                type="number"
                className="border w-full border-gray-300 rounded-lg px-4 py-2"
                placeholder=""
                value={editPriceItem.price}
                onChange={(e) => {
                  const newItems = items.map((i) => {
                    if (i.id == editPriceItem.id) {
                      i.price = e.target.value;
                    }
                    return i;
                  });
                  setItems(newItems);
                }}
              />
            </div>
            <div className="w-full">
              <label>Harga final</label>
              <input
                type="number"
                className="border w-full border-gray-300 rounded-lg px-4 py-2"
                placeholder=""
                value={editPriceItem.final_price}
                onChange={(e) => {
                  const newItems = items.map((i) => {
                    if (i.id == editPriceItem.id) {
                      i.final_price = e.target.value;
                    }
                    return i;
                  });
                  setItems(newItems);
                }}
              />
            </div>
            <div className="flex justify-end items-end">
              <button
                className="py-2 px-4 bg-pink-500 text-white rounded"
                onClick={() => setIsModalEditPrice(false)}>
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default AdminquOrderAdd;
