import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./screens/home/Home";
import Detail from "./screens/detail/Detail";
import Catalog from "./screens/catalog/Catalog";
import CatalogSale from "./screens/catalog/CatalogSale";
import CatalogType from "./screens/catalog/CatalogType";

import AdminquLogin from "./screens/adminqu/AdminquLogin";
import AdminquCatalog from "./screens/adminqu/catalog/AdminquCatalog";
import AdminquCatalogAdd from "./screens/adminqu/catalog/AdminquCatalogAdd";
import AdminquCatalogEdit from "./screens/adminqu/catalog/AdminquCatalogEdit";

import AdminquCategory from "./screens/adminqu/category/AdminquCategory";
import AdminquCategoryAdd from "./screens/adminqu/category/AdminquCategoryAdd";
import AdminquCategoryEdit from "./screens/adminqu/category/AdminquCategoryEdit";

import AdminquBrand from "./screens/adminqu/brand/AdminquBrand";
import AdminquBrandAdd from "./screens/adminqu/brand/AdminquBrandAdd";
import AdminquBrandEdit from "./screens/adminqu/brand/AdminquBrandEdit";

import AdminquOrders from "./screens/adminqu/orders/AdminquOrders";
import AdminquOrderAdd from "./screens/adminqu/orders/AdminquOrderAdd";
import AdminquOrderDetail from "./screens/adminqu/orders/AdminquOrderDetail";

import AdminquCustomer from "./screens/adminqu/customer/AdminquCustomer";
import AdminquCustomerAdd from "./screens/adminqu/customer/AdminquCustomerAdd";
import AdminquCustomerEdit from "./screens/adminqu/customer/AdminquCustomerEdit";

import AdminquCustomerGroup from "./screens/adminqu/customer-group/AdminquCustomerGroup";
import AdminquCustomerGroupAdd from "./screens/adminqu/customer-group/AdminquCustomerGroupAdd";
import AdminquCustomerGroupEdit from "./screens/adminqu/customer-group/AdminquCustomerGroupEdit";

import AdminquLinkReseller from "./screens/adminqu/reseller/AdminquLinkReseller";
import AdminquAccount from "./screens/adminqu/account/AdminquAccount";
import AdminquSetting from "./screens/adminqu/setting/AdminquSetting";
import AdminquSettingOrder from "./screens/adminqu/setting/AdminquSettingOrder";
import AdminquSettingShipping from "./screens/adminqu/setting/AdminquSettingShipping";

import AdminquReport from "./screens/adminqu/report/AdminquReport";

import AdminBlank from "./screens/adminqu/AdminBlank";

import Landing from "./screens/landing/Landing";
import Reseller from "./screens/reseller/Reseller";
import ResellerDetail from "./screens/reseller/ResellerDetail";
import ResellerCatalog from "./screens/reseller/ResellerCatalog";
import ResellerCatalogSale from "./screens/reseller/ResellerCatalogSale";
import ResellerCatalogType from "./screens/reseller/ResellerCatalogType";

import Login from "./screens/auth/Login";
import Register from "./screens/auth/Register";

import Account from "./screens/account/Account";
import Orders from "./screens/orders/Orders";
import OrderDetail from "./screens/orders/OrderDetail";

import Cart from "./screens/cart/Cart";
import Checkout from "./screens/checkout/Checkout";

import Page404 from "./screens/error/Page404";
import PageExpired from "./screens/error/PageExpired";

const httpHost = window.location.hostname;
const currentUrl = window.location.href;

const env = process.env.NODE_ENV || "development";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {httpHost === "kataloqu.com" ? (
          <Route path="/" element={<Landing />} />
        ) : (
          <Route path="/" element={<Home />} />
        )}

        {env === "development" && <Route path="/home" element={<Landing />} />}

        {/* {httpHost === "kataloqu.com" && (
          <>
            <Route path="/:slug/:reseller" element={<Reseller />} />
            <Route
              path="/:slug/:reseller/detail/:id"
              element={<ResellerDetail />}
            />
          </>
        )} */}

        <Route path="/detail/:id" element={<Detail />} />
        <Route path="/catalog/sale" element={<CatalogSale />} />
        <Route path="/catalog/:type" element={<CatalogType />} />
        <Route path="/catalog/:type/:name/:id" element={<Catalog />} />

        <Route path="/adminqu" element={<AdminquLogin />} />
        <Route path="/adminqu/login" element={<AdminquLogin />} />
        <Route path="/adminqu/catalog" element={<AdminquCatalog />} />
        <Route path="/adminqu/category" element={<AdminquCategory />} />
        <Route path="/adminqu/category/add" element={<AdminquCategoryAdd />} />
        <Route path="/adminqu/category/:id" element={<AdminquCategoryEdit />} />

        <Route path="/adminqu/brand" element={<AdminquBrand />} />
        <Route path="/adminqu/brand/add" element={<AdminquBrandAdd />} />
        <Route path="/adminqu/brand/:id" element={<AdminquBrandEdit />} />

        <Route path="/adminqu/catalog/add" element={<AdminquCatalogAdd />} />
        <Route path="/adminqu/catalog/:id" element={<AdminquCatalogEdit />} />

        <Route path="/adminqu/orders" element={<AdminquOrders />} />
        <Route path="/adminqu/orders/add" element={<AdminquOrderAdd />} />
        <Route path="/adminqu/orders/:id" element={<AdminquOrderDetail />} />

        <Route path="/adminqu/customer" element={<AdminquCustomer />} />

        <Route path="/adminqu/customer/add" element={<AdminquCustomerAdd />} />
        <Route path="/adminqu/customer/:id" element={<AdminquCustomerEdit />} />

        <Route
          path="/adminqu/customer-group"
          element={<AdminquCustomerGroup />}
        />

        <Route
          path="/adminqu/customer-group/add"
          element={<AdminquCustomerGroupAdd />}
        />
        <Route
          path="/adminqu/customer-group/:id"
          element={<AdminquCustomerGroupEdit />}
        />

        <Route
          path="/adminqu/link-reseller"
          element={<AdminquLinkReseller />}
        />
        <Route path="/adminqu/account" element={<AdminquAccount />} />
        <Route path="/adminqu/setting" element={<AdminquSetting />} />
        <Route
          path="/adminqu/setting/order"
          element={<AdminquSettingOrder />}
        />
        <Route
          path="/adminqu/setting/shipping"
          element={<AdminquSettingShipping />}
        />

        <Route path="/adminqu/report" element={<AdminquReport />} />

        <Route path="/adminqu/blank" element={<AdminBlank />} />

        <>
          <Route path="/:slug/:reseller" element={<Reseller />} />
          <Route
            path="/:slug/:reseller/detail/:id"
            element={<ResellerDetail />}
          />
          <Route
            path="/:slug/:reseller/catalog/sale"
            element={<ResellerCatalogSale />}
          />
          <Route
            path="/:slug/:reseller/catalog/:type"
            element={<ResellerCatalogType />}
          />
          <Route
            path="/:slug/:reseller/catalog/:type/:name/:id"
            element={<ResellerCatalog />}
          />
        </>

        <>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/account" element={<Account />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:id" element={<OrderDetail />} />

          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
        </>
        <Route path="/expired" element={<PageExpired />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
