import React, { useState, useEffect } from "react";

import Header from "../../components/Header";

import Api from "../../services/Api";
import Config from "../../config/Config";

import { Link } from "react-router-dom";
import formatCurrency from "../../helpers/formatNumber";

var httpHost = window.location.hostname;
var domain = "https://kataloqu.com";

if (httpHost == "localhost" || httpHost == "192.168.1.7") {
  httpHost = "mycollection.kataloqu.com";
}

function Account() {
  const [user, setUser] = useState({});

  const [store, setStore] = useState({});

  const [pendingOrder, setPendingOrder] = useState(0);
  const [processOrder, setProcessOrder] = useState(0);
  const [shippingOrder, setShippingOrder] = useState(0);
  const [cancelOrder, setCancelOrder] = useState(0);

  useEffect(() => {
    const subdomain = httpHost.split(".")[0];
    document.title = "Akun Saya - by Kataloqu";

    const token = localStorage.getItem("customer_token");

    if (token == null) {
      window.location.href = "/login";
    }

    const getStore = async () => {
      Api.get(
        "/items/Store?filter[subdomain][_eq]=" +
          subdomain +
          "&filter[is_activated][_eq]=1&fields=*"
      )
        .then((response) => {
          //console.log(response);
          if (response.data.length == 0) {
            // 404 page
            window.location.href = "/expired";
          }

          if (response) {
            setStore(response.data[0]);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const summaryOrder = async () => {
      Api.get(
        "/flows/trigger/4ce2d364-da58-47bf-9859-2daaea1f9bb3",
        token
      ).then((res) => {
        //console.log("res", res);
        // console.log("summaryOrder", res);
        setPendingOrder(res?.pending);
        setProcessOrder(res?.process);
        setShippingOrder(res?.delivery);
        setCancelOrder(res?.canceled);
      });
    };

    getStore();
    summaryOrder();

    Api.get("/users/me", token)
      .then((res) => {
        //console.log("res", res);

        if (res?.data) {
          console.log("data", res.data);
          setUser(res.data);
        } else {
          window.location.href = "/login";
          localStorage.removeItem("customer_token");
        }
      })
      .catch((error) => {
        window.location.href = "/login";
        localStorage.removeItem("customer_token");
      });
  }, []);

  const logout = () => {
    const token = localStorage.getItem("customer_token");

    Api.post("/users/logout", null, token).then((res) => {
      //console.log("res", res);

      localStorage.removeItem("customer_token");
      window.location.href = "/login";
    });
  };

  return (
    <div className="flex-1 flex flex-col">
      <Header />

      <div className="px-4 sm:pt-24 pt-52 pb-4">
        <div className="container mx-auto md:w-2/3 xl:w-1/2 px-4 pt-4">
          <div className="font-bold  text-gray-700 mb-4">Informasi Akun</div>

          <div className="flex-col text-xs md:text-sm flex p-4 bg-gray-100 rounded-lg">
            <div className="flex flex-row w-full">
              <div className="w-1/4">
                <div>Nama</div>
              </div>
              <div className="w-3/4">
                <div>: {user?.first_name}</div>
              </div>
            </div>
            <div className="flex flex-row w-full">
              <div className="w-1/4">
                <div>Email</div>
              </div>
              <div className="w-3/4">
                <div>: {user?.email_account}</div>
              </div>
            </div>
          </div>
          <div>
            <button
              className={
                "mt-4 bg-" +
                store.color +
                "-500 text-xs hover:bg-" +
                store.color +
                "-700 text-white font-bold py-2 px-4 rounded-lg"
              }>
              Ubah profil
            </button>
          </div>

          <div className="mt-8 font-bold text-lg text-gray-700 mb-4">
            Ringkasan pesanan
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4  gap-4">
            <div className="p-4 bg-gray-100 rounded-lg">
              <div className="font-bold text-xs md:text-md text-gray-700 mb-2">
                Menunggu <br />
                konfirmasi
              </div>
              <div className="text-2xl">{pendingOrder}</div>
            </div>
            <div className="p-4 bg-gray-100 rounded-lg">
              <div className="font-bold  text-xs md:text-md text-gray-700 mb-2">
                Sedang <br />
                diproses
              </div>
              <div className="text-2xl">{processOrder}</div>
            </div>
            <div className="p-4 bg-gray-100 rounded-lg">
              <div className="font-bold  text-xs md:text-md text-gray-700 mb-2">
                Sedang <br />
                dikirim
              </div>
              <div className="text-2xl">{shippingOrder}</div>
            </div>
            <div className="p-4 bg-gray-100 rounded-lg">
              <div className="font-bold  text-xs md:text-md text-gray-700 mb-2">
                Telah <br />
                dibatalkan
              </div>
              <div className="text-2xl">{cancelOrder}</div>
            </div>
          </div>

          <div className="mt-4">
            <Link
              to="/orders"
              className={
                "mt-4 bg-" +
                store.color +
                "-500 text-xs hover:bg-" +
                store.color +
                "-700 text-white font-bold py-2 px-4 rounded-lg"
              }>
              Lihat Semua Pesanan
            </Link>
          </div>
          <div className="mt-8">
            <p> Keluar dari akun </p>
            <button
              onClick={logout}
              className={
                "mt-4 bg-red-500 text-xs hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg"
              }>
              Keluar akun
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
