import React, { useState, useEffect } from "react";

import { useParams, Link } from "react-router-dom";
import { FaCopy, FaWindowClose, FaChevronRight } from "react-icons/fa";
import Api from "../../services/Api";
import Config from "../../config/Config";
import { ToastContainer, toast } from "react-toastify";

import ReactLoading from "react-loading";

import DropdownCatalog from "../../components/reseller/DropdownCatalog";
import DropdownCategory from "../../components/reseller/DropdownCategory";
import DropdownBrand from "../../components/reseller/DropdownBrand";
import formatCurrency from "../../helpers/formatNumber";

const currentUrl = window.location.href;

function ResellerCatalogType() {
  const params = useParams();
  const type = params.type;

  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [store, setStore] = useState({
    name: "",
  });

  const segment2 = currentUrl.split("/")[3];
  // const segment3 = currentUrl.split("/")[4];

  const segment3beforecleaning = currentUrl.split("/")[4];

  // remove query url from segment3
  const segment3 = segment3beforecleaning.split("?")[0];

  const [reseller, setReseller] = useState({});

  const [catalogOriginal, setCatalogOriginal] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [search, setSearch] = useState("");

  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const transformToSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w-]+/g, "") // Remove non-word characters
      .replace(/--+/g, "-") // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ""); // Trim hyphens from the start and end
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Link berhasil disalin");
      })
      .catch((error) => {
        //console.error("Error copying to clipboard:", error)
      });
  };

  useEffect(() => {
    const getInitialCatalog = async (storeId) => {
      if (type == "ready") {
        var urlQuery =
          "/items/Catalog?filter[store_id][_eq]=" +
          storeId +
          "&filter[status][_eq]=published&filter[is_preorder][_neq]=1";
      } else if (type == "preorder") {
        var urlQuery =
          "/items/Catalog?filter[store_id][_eq]=" +
          storeId +
          "&filter[status][_eq]=published&filter[is_preorder][_eq]=1";
      }

      Api.get(urlQuery)
        .then((response) => {
          //console.log(response);
          if (response) {
            setCatalogOriginal(response.data);
            setCatalog(response.data);
            setIsInitialLoading(false);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getReseller = async () => {
      Api.get(
        "/items/Link?filter[slug][_eq]=" +
          segment2 +
          "/" +
          segment3 +
          "&filter[status][_eq]=active&fields=*"
      )
        .then((response) => {
          //console.log(response);
          if (response.data.length == 0) {
            window.location.href = "/404";
          }

          if (response) {
            setReseller(response.data[0]);
            document.title = response.data[0].name + " - by Kataloqu";
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getStore = async () => {
      Api.get(
        "/items/Store?filter[slug][_eq]=" +
          segment2 +
          "&filter[is_activated][_eq]=1&fields=*"
      )
        .then((response) => {
          //console.log(response);
          if (response.data.length == 0) {
            window.location.href = "/expired";
          }

          if (response) {
            setStore(response.data[0]);
            getReseller();
            getBrands(response.data[0].id);
            getCategory(response.data[0].id);
            getInitialCatalog(response.data[0].id);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getBrands = async (store_id) => {
      const response = await Api.get(
        "/items/Brands?filter[store_id][_eq]=" + store_id + "&sort=name"
      );
      setBrands(response.data);
    };
    const getCategory = async (store_id) => {
      const response = await Api.get(
        "/items/Categories?filter[store_id][_eq]=" + store_id + "&sort=name"
      );
      setCategory(response.data);
    };

    setIsInitialLoading(true);
    getStore();
  }, [type]);

  useEffect(() => {
    const searchCatalog = catalogOriginal.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });

    setCatalog(searchCatalog);
  }, [search]);

  const dateFormat = (date) => {
    if (date != null && date != undefined) {
      const parts = date.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        return `${day}/${month}/${year}`;
      }
      return date;
    } else {
      return "-";
    }
  };

  return (
    <div>
      <div className="fixed w-full ml-0 mt-0 px-4 py-4 shadow bg-white">
        <div className="flex flex-col w-full sm:flex-row gap-4 items-center hidden md:inline-flex">
          <Link
            to={"/" + reseller.slug}
            className="flex-initial  w-full sm:w-1/4 text-lg font-bold text-center">
            {reseller.name}
          </Link>
          <div className="w-full flex flex-row sm:w-1/3 ml-auto gap-4">
            <div className="">
              <DropdownCatalog resellerSlug={reseller.slug} />
            </div>
            <div className="">
              <DropdownCategory
                resellerSlug={reseller.slug}
                name="Kategori"
                category={category}
              />
            </div>
            <div className="">
              <DropdownBrand
                resellerSlug={reseller.slug}
                name="Brand"
                brands={brands}
              />
            </div>
          </div>
          <div className="w-full sm:w-1/4 ml-auto">
            <input
              type="text"
              placeholder="Cari katalog"
              className="border-2 w-full border-gray-100 bg-gray-100 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:bg-white"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="flex-initial w-full sm:w-1/4 text-center"></div>
        </div>

        <div className="flex flex-col gap-4 sm:hidden">
          <Link
            to={"/" + reseller.slug}
            className="flex flex-row justify-between">
            <div className="w-full text-lg font-bold">{reseller.name}</div>
          </Link>
          <div className="w-full flex flex-row gap-1">
            <div className="">
              <DropdownCatalog resellerSlug={reseller.slug} />
            </div>
            <div className="">
              <DropdownCategory
                resellerSlug={reseller.slug}
                name="Kategori"
                category={category}
              />
            </div>
            <div className="">
              <DropdownBrand
                resellerSlug={reseller.slug}
                name="Brand"
                brands={brands}
              />
            </div>
          </div>
          <div className="w-full ml-auto">
            <input
              type="text"
              placeholder="Search"
              className="border-2 w-full border-gray-100 bg-gray-100 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none focus:bg-white"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>

      {isInitialLoading ? (
        <div className="px-4 sm:pt-24 pt-52 pb-4 flex justify-center">
          <ReactLoading type={"balls"} color={"gray"} height={10} width={48} />
        </div>
      ) : (
        <div className="px-4 sm:pt-24 pt-52 pb-4">
          <div className="container mx-auto ">
            <div className="font-bold mb-8 flex flex-row gap-2 items-center">
              Katalog <FaChevronRight />{" "}
              {type == "ready" ? "Ready Stock" : "Pre Order"}{" "}
            </div>

            <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
              {catalog.map((item) => (
                <Link to={`/${reseller.slug}/detail/${item.id}`}>
                  <div
                    className={
                      "bg-" +
                      store.color +
                      "-50 rounded overflow-hidden flex flex-col"
                    }>
                    <div>
                      <img
                        src={
                          Config.api_url +
                          "/assets/" +
                          item.image +
                          "?fit=cover&width=400&height=400"
                        }
                        alt="Product 1"
                        className="w-full h-auto"
                      />
                    </div>
                    <div className="p-4 mb-2 h-32">
                      <h2 className="text-sm sm:text-md font-semibold mt-0">
                        {item.name}
                      </h2>

                      {item.is_discount && item.show_discount_in_reseller ? (
                        <>
                          {item.is_range_price ? (
                            <p className={"text-red-500 text-sm font-normal"}>
                              <span className=" line-through pr-1">
                                {formatCurrency(item.price_from)} -{" "}
                                {formatCurrency(item.price_to)}
                              </span>
                              <br />
                              <span
                                className={
                                  "text-" +
                                  store.color +
                                  "-700 text-sm font-semibold"
                                }>
                                {formatCurrency(item.discount_from)} -{" "}
                                {formatCurrency(item.discount_to)}
                              </span>
                            </p>
                          ) : (
                            <p className={"text-red-500 text-sm font-normal"}>
                              <span className=" line-through pr-1">
                                {formatCurrency(item.price)}
                              </span>
                              <span
                                className={
                                  "text-" +
                                  store.color +
                                  "-700 text-sm font-semibold"
                                }>
                                {formatCurrency(item.discount)}
                              </span>
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {item.is_range_price ? (
                            <p
                              className={
                                "text-" +
                                store.color +
                                "-700 text-sm font-semibold"
                              }>
                              {formatCurrency(item.price_from)} -{" "}
                              {formatCurrency(item.price_to)}
                            </p>
                          ) : (
                            <p
                              className={
                                "text-" +
                                store.color +
                                "-700 text-sm font-semibold"
                              }>
                              {formatCurrency(item.price)}
                            </p>
                          )}
                        </>
                      )}

                      {item.is_preorder == 1 && (
                        <>
                          <p className="text-gray-500 text-xs mt-2 ">
                            {item.preorder_additional_text}
                          </p>
                          <p
                            className={
                              "text-" + store.color + "-500 text-xs mt-2 "
                            }>
                            <span
                              className={
                                "bg-" +
                                store.color +
                                "-500 text-white px-2 py-1 rounded "
                              }>
                              {dateFormat(item.preorder_closed_date)}
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default ResellerCatalogType;
