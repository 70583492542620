import React, { useState, useEffect } from "react";
import Header from "../../components/Header";

import Api from "../../services/Api";

import { FaChevronCircleRight, FaTimes } from "react-icons/fa";

import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import formatCurrency from "../../helpers/formatNumber";

var httpHost = window.location.hostname;
var domain = "https://kataloqu.com";

if (httpHost == "localhost" || httpHost == "192.168.1.7") {
  httpHost = "mycollection.kataloqu.com";
}

function Checkout() {
  const [store, setStore] = useState({});
  const [cart, setCart] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);

  const [shippingProvider, setShippingProvider] = useState("");
  const [shippingProviderService, setShippingProviderService] = useState("");

  const [isModalDestinationOpen, setIsModalDestinationOpen] = useState(false);
  const [isModalServiceOpen, setIsModalServiceOpen] = useState(false);

  const [shipperName, setShipperName] = useState("");
  const [shippingName, setShippingName] = useState("");
  const [shippingPhone, setShippingPhone] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingZip, setShippingZip] = useState("");

  const [isManualShipping, setIsManualShipping] = useState(false);

  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [subdistrict, setSubdistrict] = useState([]);

  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedSubdistrict, setSelectedSubdistrict] = useState("");

  const [selectedProvinceName, setSelectedProvinceName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedSubdistrictName, setSelectedSubdistrictName] = useState("");

  const [selectedDestination, setSelectedDestination] = useState("Provinsi");
  const [selectedDestinationListOriginal, setSelectedDestinationListOriginal] =
    useState([]);
  const [selectedDestinationList, setSelectedDestinationList] = useState([]);
  const [searchDestination, setSearchDestination] = useState("");
  const [destinationLabel, setDestinationLabel] = useState("");

  const [servicesList, setServicesList] = useState("");
  const [service, setService] = useState([]);
  const [selectedService, setSelectedService] = useState("");

  const [total, setTotal] = useState(0);
  const [subtotalCost, setSubtotalCost] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [note, setNote] = useState("");

  const getProvince = async () => {
    setSelectedDestinationListOriginal([]);
    setSelectedDestinationList([]);
    Api.get("/flows/trigger/9dbccb98-1e98-4ec0-89d8-85c630da3711")
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setProvince(response.data);
          setSelectedDestinationListOriginal(response.data);
          setSelectedDestinationList(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getCity = async (id) => {
    setSelectedDestinationListOriginal([]);
    setSelectedDestinationList([]);

    Api.get(
      "/flows/trigger/5ed594b6-0cb0-43a1-9297-665119821253?province=" + id
    )
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setCity(response.data);
          setSelectedDestinationListOriginal(response.data);
          setSelectedDestinationList(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getSubdistrict = async (id) => {
    setSelectedDestinationListOriginal([]);
    setSelectedDestinationList([]);

    Api.get("/flows/trigger/472d762c-d5dd-4083-a110-814fd6e5a6c5?city=" + id)

      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setSubdistrict(response.data);
          setSelectedDestinationListOriginal(response.data);
          setSelectedDestinationList(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    // recalculating total when shipping fee changed
    setTotal(parseInt(totalAmount) + parseInt(shippingFee));
  }, [shippingFee]);

  const getRate = async () => {
    Api.post("/flows/trigger/3825f233-d93a-43d6-9a81-fdb06820ab23", {
      origin: store.origin_subdistrict,
      destination: selectedSubdistrict,
      weight: totalWeight,
      courier: servicesList,
    })
      .then((response) => {
        console.log(response);
        if (response) {
          console.log(response.data);
          setService(response.data);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getCart = async () => {
    const token = localStorage.getItem("customer_token");
    Api.get("/items/Cart?fields=*.*.*", token)
      .then((response) => {
        console.log(response);
        if (response) {
          setCart(response.data);
          let total = 0;
          let amount = 0;
          let weight = 0;
          let cost = 0;
          response.data.map((item) => {
            total += item.qty;
            amount += item.subtotal;
            weight += item.catalog_id.weight * item.qty;
            cost +=
              (item.catalog_variant_id
                ? item.catalog_variant_id.cost
                : item.catalog_id.cost) * item.qty;
          });
          setTotalQty(total);
          setSubtotalCost(cost);
          setTotalAmount(amount);
          setTotal(amount);
          setTotalWeight(weight);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getStore = async () => {
    const subdomain = httpHost.split(".")[0];
    Api.get(
      "/items/Store?filter[subdomain][_eq]=" +
        subdomain +
        "&filter[is_activated][_eq]=1&fields=*"
    )
      .then((response) => {
        //console.log(response);
        if (response.data.length == 0) {
          // 404 page
          window.location.href = "/expired";
        }

        if (response) {
          setStore(response.data[0]);

          var serviceList = "";
          response.data[0].ongkir_jne && (serviceList += "jne:");
          response.data[0].ongkir_jnt && (serviceList += "jnt:");
          response.data[0].ongkir_tiki && (serviceList += "tiki:");
          response.data[0].ongkir_pos && (serviceList += "pos:");
          response.data[0].ongkir_sicepat && (serviceList += "sicepat:");
          response.data[0].ongkir_wahana && (serviceList += "wahana:");
          response.data[0].ongkir_anteraja && (serviceList += "anteraja:");
          response.data[0].ongkir_sentral && (serviceList += "sentral:");
          response.data[0].ongkir_lion && (serviceList += "lion:");

          // remove last character
          serviceList = serviceList.slice(0, -1);
          setServicesList(serviceList);

          document.title = response.data[0].name + " - by Kataloqu";
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    getStore();
    getCart();
    getProvince();
  }, []);

  useEffect(() => {
    // search destination
    if (searchDestination.length > 0) {
      const filtered = selectedDestinationListOriginal.filter((item) =>
        item.province.toLowerCase().includes(searchDestination.toLowerCase())
      );
      setSelectedDestinationList(filtered);
    } else {
      setSelectedDestinationList(selectedDestinationListOriginal);
    }
  }, [searchDestination]);

  const checkout = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    var items = [];

    cart.map((item) => {
      items.push({
        catalog_id: item.catalog_id.id,
        catalog_variant_id: item?.catalog_variant_id?.id || null,
        qty: item.qty,
        cost: item.cost,
        price: item.price,
        final_price: item.final_price,
        subtotal: item.subtotal,
      });
    });

    const token = localStorage.getItem("customer_token");
    const data = {
      store_id: store.id,
      shipper_name: shipperName,
      shipping_name: shippingName,
      shipping_phone: shippingPhone,
      shipping_address: shippingAddress,
      shipping_postal_code: shippingZip,
      shipping_city: selectedCityName,
      shipping_province: selectedProvinceName,
      shipping_subdistrict: selectedSubdistrictName,
      total_weight: totalWeight,
      subtotal: totalAmount,
      subtotal_cost: subtotalCost,
      shipping_cost: shippingFee,
      shipping_provider: isManualShipping ? "Manual" : shippingProvider,
      shipping_provider_service: isManualShipping
        ? ""
        : shippingProviderService,
      total: total,
      items: items,
      note: note,
    };

    Api.post("/flows/trigger/a8558466-32bf-466e-8908-2938acdb1939", data, token)
      .then((response) => {
        console.log(response);
        // if (response) {
        //   window.location.href = "/order/" + response.data.id;
        // }
        if (response?.status == "success") {
          window.location.href = "/orders/" + response.id;
        } else {
          if (response?.errors == "Insufficient Quota") {
            toast.error("Gagal checkout, silahkan hubungi admin");
          }
        }

        setIsSubmitting(false);
      })
      .catch((error) => {
        //console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <div className="flex-1 flex justify-center">
      <Header />
      <div className="px-4 sm:pt-28 pt-56 pb-8 w-full md:w-1/2">
        {/* Cart list view */}
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-lg font-semibold">Checkout</h1>
          </div>
          <div>
            {/* Checkout form  */}
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold">Nama Penerima</label>
                <input
                  type="text"
                  value={shippingName}
                  onChange={(e) => setShippingName(e.target.value)}
                  className="border border-gray-300 rounded-lg px-4 py-2"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold">Nomor Telepon</label>
                <input
                  type="text"
                  value={shippingPhone}
                  onChange={(e) => setShippingPhone(e.target.value)}
                  className="border border-gray-300 rounded-lg px-4 py-2"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold">Alamat</label>
                <textarea
                  value={shippingAddress}
                  onChange={(e) => setShippingAddress(e.target.value)}
                  className="border border-gray-300 rounded-lg px-4 py-2"
                  rows="4"></textarea>
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold">Kodepos</label>
                <input
                  type="text"
                  value={shippingZip}
                  onChange={(e) => setShippingZip(e.target.value)}
                  className="border border-gray-300 rounded-lg px-4 py-2"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold">
                  Tujuan pengiriman
                </label>
                <div className="flex flex-row items-center cursor-pointer">
                  <input
                    className=" cursor-pointer border w-full border-gray-300 rounded-lg px-4 py-2"
                    readOnly
                    value={destinationLabel}
                    onClick={() => {
                      setSelectedDestination("Provinsi");
                      getProvince();
                      setIsModalDestinationOpen(true);
                    }}
                  />
                  <FaChevronCircleRight className="text-gray-400 -ml-8" />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center">
                  <label className="text-sm font-semibold">
                    Layanan pengiriman
                  </label>
                  <div className="flex flex-row items-center gap-2 text-xs">
                    Ongkir manual?
                    <label
                      for="toggleStatus"
                      className="flex items-center cursor-pointer">
                      <div className="relative">
                        <input
                          type="checkbox"
                          id="toggleStatus"
                          className="sr-only"
                          onChange={(e) =>
                            setIsManualShipping(e.target.checked)
                          }
                          checked={isManualShipping}
                        />
                        <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <input
                    disabled={isManualShipping}
                    className="border w-full border-gray-300 rounded-lg px-4 py-2"
                    readOnly
                    value={selectedService}
                    onClick={() => {
                      getRate();
                      setIsModalServiceOpen(true);
                    }}
                  />
                  <FaChevronCircleRight className="text-gray-400 -ml-8" />
                </div>
              </div>
              {isManualShipping && (
                <div>
                  <div className="my-1 w-full md:w-full font-semibold">
                    <span className="text-sm">Biaya ongkir pengiriman</span>

                    <input
                      type="number"
                      className="border-2 border-gray-200 w-full rounded-lg px-3 py-2 mt-1 font-normal text-right"
                      value={shippingFee}
                      onChange={(e) => setShippingFee(e.target.value)}
                    />
                  </div>
                </div>
              )}
              <div>
                <div className="flex flex-row justify-between items-center">
                  <div>Total berat</div>
                  <div>{totalWeight} gram</div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold">
                  Pengirim (opsional)
                </label>
                <input
                  type="text"
                  value={shipperName}
                  onChange={(e) => setShipperName(e.target.value)}
                  className="border border-gray-300 rounded-lg px-4 py-2"
                  placeholder="Nama pengirim/toko/08123456789"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold">
                  Catatan untuk penjual
                </label>
                <textarea
                  className="border border-gray-300 rounded-lg px-4 py-2"
                  rows="4"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}></textarea>
              </div>

              <div className="flex flex-col gap-2 p-4 bg-gray-100 rounded">
                <div className="flex flex-row justify-between items-center">
                  <div>Jumlah barang</div>
                  <div>{totalQty}</div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div>Total belanja</div>
                  <div>{formatCurrency(totalAmount)}</div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div>Biaya pengiriman</div>
                  <div>{formatCurrency(shippingFee)}</div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div className="font-semibold text-lg">Total pembayaran</div>
                  <div className="font-semibold text-lg">
                    {formatCurrency(total)}
                  </div>
                </div>
              </div>

              <div className="justify-end flex">
                {isSubmitting ? (
                  <div className="flex flex-row text-center justify-center">
                    <ReactLoading
                      type="spin"
                      color={"#333"}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  <button
                    onClick={checkout}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 w-full md:w-1/3 rounded-lg">
                    Checkout
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal destination */}
      <div
        className={
          (isModalDestinationOpen ? "fixed" : "hidden") +
          " inset-0 w-full h-full z-50 overflow-hidden flex justify-center items-center bg-black bg-opacity-50"
        }>
        <div className="bg-white rounded-lg w-11/12 md:w-1/2 p-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-lg font-semibold">
                Pilih tujuan {selectedDestination}
              </h1>
              <button
                onClick={() => setIsModalDestinationOpen(false)}
                className="text-gray-400 hover:text-gray-700">
                <FaTimes />
              </button>
            </div>

            <div className="w-full">
              <input
                type="text"
                className="border w-full border-gray-300 rounded-lg px-4 py-2"
                placeholder="Cari provinsi"
                value={searchDestination}
                onChange={(e) => setSearchDestination(e.target.value)}
              />
            </div>

            <div className="flex flex-col max-h-96 overflow-scroll">
              {selectedDestinationList.length > 0 ? (
                selectedDestinationList.map((item) => (
                  <div
                    className="cursor-pointer hover:bg-gray-100 rounded p-1"
                    onClick={() => {
                      if (selectedDestination === "Provinsi") {
                        setSelectedProvince(item.province_id);
                        setSelectedProvinceName(item.province);
                        setSelectedDestination("Kota");
                        getCity(item.province_id);
                      } else if (selectedDestination === "Kota") {
                        setSelectedCity(item.city_id);
                        setSelectedCityName(item.city_name);
                        setSelectedDestination("Kecamatan");
                        getSubdistrict(item.city_id);
                      } else if (selectedDestination === "Kecamatan") {
                        setSelectedSubdistrict(item.subdistrict_id);
                        setSelectedSubdistrictName(item.subdistrict_name);
                        setIsModalDestinationOpen(false);

                        setDestinationLabel(
                          item.subdistrict_name +
                            ", " +
                            selectedCityName +
                            ", " +
                            selectedProvinceName
                        );
                      }
                    }}>
                    {selectedDestination === "Provinsi" && (
                      <>
                        <div>{item.province}</div>
                      </>
                    )}
                    {selectedDestination === "Kota" && (
                      <>
                        <div>
                          {item.type} {item.city_name}
                        </div>
                      </>
                    )}
                    {selectedDestination === "Kecamatan" && (
                      <>
                        <div>{item.subdistrict_name}</div>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <div className="flex flex-row text-center justify-center min-h-32">
                  <ReactLoading
                    type="spin"
                    color={"#333"}
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal service */}
      <div
        className={
          (isModalServiceOpen ? "fixed" : "hidden") +
          " inset-0 w-full h-full z-50 overflow-hidden flex justify-center items-center bg-black bg-opacity-50"
        }>
        <div className="bg-white rounded-lg w-11/12 md:w-1/2 p-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-lg font-semibold">Pilih layanan</h1>
              <button
                onClick={() => setIsModalServiceOpen(false)}
                className="text-gray-400 hover:text-gray-700">
                <FaTimes />
              </button>
            </div>

            <div className="flex flex-col max-h-96 overflow-scroll">
              {service.length > 0 ? (
                service.map((item) => (
                  <div className="rounded p-1">
                    <div className="font-bold">{item.name}</div>
                    {item.costs.map((cost) => (
                      <div
                        className="flex flex-row justify-between py-2 cursor-pointer"
                        onClick={() => {
                          setSelectedService(item.name + " - " + cost.service);
                          setShippingFee(cost.cost[0].value);
                          setTotal(totalAmount + cost.cost[0].value);
                          setIsModalServiceOpen(false);
                        }}>
                        <div className="w-1/2">{cost.service}</div>
                        <div className="w-1/2 flex flex-row items-center justify-between">
                          <div>
                            {cost.cost[0].etd} hari ({cost.cost[0].value})
                          </div>
                          <div>
                            <FaChevronCircleRight className="text-gray-800 -ml-8" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div className="flex flex-row text-center justify-center min-h-32">
                  <ReactLoading
                    type="spin"
                    color={"#333"}
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Checkout;
